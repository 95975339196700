<template>
  <div class='pg-select-wrap' v-clickoutside="close">
    <div class="pg-select"
      :style="[{ borderRadius: round ? '5rem' : borderRadius + 'rem' }, { width: isBlock ? '100%' : 'auto' }]"
      :class="[{ focus: visible }, { transparent }]" ref="dropParent" @click="visible = !visible">
      <i class="select-icon" v-if="prefix">
        <Icon :type="prefix" />
      </i>
      <slot v-if="isSlotSelection" name="selection" :data="selectedItem"></slot>
      <div v-else :class="[prefix ? 'pg-select-value prefix-select' : 'pg-select-value']"
        :style="[{ height: (height || '0.5') + 'rem' }]">
        <input v-if="filterable" v-model='keyword' type="text">
        <span v-show="!filterable || keyword == ''">{{ selectedItem.label }}</span>
      </div>
      <i class="arrow" :class="{ active: visible }">
        <Icon type="arrow-down-line" />
      </i>
    </div>
    <pop placement="bottom-start">
      <ul class="pg-select-drop-list" v-if="filterOptions.length">
        <li class="drop-item" v-for="(item, index) in  filterOptions " :key="index"
          :class="[{ 'item-selected': value == item.value }, { bg }]" :style="{ minWidth: dropListWidth + 'px' }"
          @click="selected(item)">
          <span v-if="!isSlot">{{ item.label }}</span>
          <slot v-else :item="item"></slot>
        </li>
      </ul>
      <div class="pg-select-drop-list" v-else>
        <div class="drop-item">{{ $t('No_Records') }}</div>
      </div>
    </pop>
  </div>
</template>


<script>
import Emitter from 'element-ui/src/mixins/emitter';
import pop from './drop/pop.vue';
export default {
  name: 'PgSelect',
  components: { pop },
  mixins: [Emitter],
  props: {
    filterable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String, Number],
    },
    prefix: {
      type: String,
    },
    isBlock: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    bg: {
      type: Boolean,
      default: true
    },
    height: {
      type: [String, Number],
    },
    isSlot: {
      type: Boolean,
      default: false
    },
    isSlotSelection: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: true
    },
    borderRadius: {
      type: Number,
      default: 0.1
    },
  },
  data() {
    return {
      visible: false,
      dropListWidth: 0,
      keyword: '',
    }
  },
  updated(){
    this.$nextTick(() => {
      this.dropListWidth = this.$refs.dropParent.offsetWidth
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.dropListWidth = this.$refs.dropParent.offsetWidth
    })
  },
  watch: {
    visible(val) {
      this.broadcast('dropItem', 'visible', [
        val,
      ]);
    },
  },
  computed: {
    filterOptions() {
      return this.options.filter(o => o.label.toLowerCase().includes(this.keyword))
    },
    selectedItem() {
      const filterArr = this.filterOptions?.filter(o => o.value == this.value)
      return filterArr.length > 0 ? filterArr[0] : { label: this.value }
    }
  },
  methods: {
    close() {
      if (this.visible) {
        this.visible = false
      }
    },
    selected(item) {
      this.$emit('input', item.value)
      this.keyword = ''
      this.visible = false
      this.$emit('change', item.value)
    },
  },
}
</script>

<style lang='scss' scoped>
.pg-select-drop-list {
  max-height: 5.1rem;
  padding: 0.14rem 0;
  overflow-y: auto;
  border-radius: 0.14rem;
  box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, .1);
  background-color: var(--theme-main-bg-color);
  border: 0.01rem solid var(--theme-color-line);
  transform: translateY(5px);

  .drop-item {
    font-size: .24rem;
    user-select: none;
    line-height: .8rem;
    min-height: 0.8rem;
    box-sizing: border-box;
    padding: 0 0.2rem;
    background-color: var(--theme-main-bg-color);
    color: var(--theme-text-color-lighten);
    cursor: pointer;
    &:hover{
      background-color: var(--theme-bg-color)
    };

    &.item-selected {
      color: var(--theme-primary-color);
    }

    &.item-selected.bg {
      //background-color: var(--theme-bg-color);
    }
  }

  div.drop-item {
    cursor: default;
  }
}


.pg-select-wrap {
  font-size: 0.2rem;

  .pg-select {
    user-select: none;
    display: inline-flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    background: var(--theme-main-bg-color);
    min-width: 1.6rem;
    position: relative;
    border: 0.01rem solid var(--theme-color-line);

    &.transparent {

      background: transparent;
    }

    .select-icon {
      position: absolute;
      left: 0;
      top: 0;
      color: var(--theme-text-color);
      font-size: .3rem;
      width: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
    }

    &.focus {
      border-color: var(--theme-ant-primary-color-13);
    }

    .pg-select-value {
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 0.2rem;
      margin-right: 0.4rem;
      color: var(--theme-text-color-lighten);
      position: relative;

      &.prefix-select {
        margin-left: 0;
        padding-left: 0.6rem;
      }

      input {
        color: var(--theme-text-color-lighten);
        background: transparent;
        position: absolute;
        top: 0;
        left: -4px;
        right: 0;
        bottom: 0;
        border: none;
        outline: none;

        &:focus+span {
          opacity: 0.4;
        }
      }
    }

    .arrow {
      position: absolute;
      color: var(--theme-text-color-lighten);
      font-size: .17rem;
      transition: all .3s;
      right: 0.2rem;

      &.active {
        transform: rotate(-180deg);
      }
    }
  }
}

.pc-page {
  .pg-select-wrap {
    .pg-select {
      height: 0.4rem;
    }
  }

  .pg-select-drop-list {
    .drop-item {
      font-size: .2rem;
      line-height: .55rem;
      height: 0.55rem;
      min-height: 0.55rem;
    }
  }
}
</style>