import { onScrollEvent } from "@/util";
export default {
  bind(el, binding) {
    el.addEventListener('scroll',onScrollEvent(el,binding.value,500))
  },
  unbind(el) {
    
    el.removeEventListener('scroll')
  }
};
