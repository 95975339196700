<template>
  <div class='pg-password'>
    <div class="ps-header">
      <span class="ps-title">{{ title }}</span>
      <i class="icon-eye" @click="showPassword = !showPassword">
        <Icon :type="showPassword ? 'eye' : 'eye-close'"></Icon>
      </i>
    </div>
    <div class="input-wrap">
      <input class="pwd-input" v-model="password" :disabled="disabled" ref="input" :maxlength="num" type="tel"
        oninput="value=value.replace(/[^\d]/g,'')" @input="chnage">
      <ul class="pwd-wrap" @click="handleFoucs">
        <li class="pwd-item" v-for="item of num" :key="item"
          :class="[{ active: password.length === item - 1 }, { fill: password[item - 1] !== undefined }]">
          <span v-if="showPassword">{{ password[item - 1] }}</span>
          <i v-else></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num: {
      type: Number,
      default() {
        return 6
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: String,
      default() {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    value(val) {
      this.password = val
    }
  },
  data() {
    return {
      password: '',
      showPassword: false,
    }
  },
  methods: {
    handleFoucs() {
      this.$refs.input.focus()
    },
    chnage() {
      this.$emit('input', this.password)
      if (this.password.length === this.num) {
        this.$refs.input.blur()
        this.$emit('compelete')
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.pg-password {
  .ps-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.15rem;

    .ps-title {
      font-size: .22rem;
      color: var(--theme-text-color-darken);
    }

    .icon-eye {
      font-size: .36rem;
      color: var(--theme-color-line);
    }
  }

  .input-wrap {
    position: relative;

    .pwd-wrap {
      border-radius: 0.14rem;
      height: 1rem;
      display: flex;
      border: 1px solid var(--theme-color-line);
      background: var(--theme-main-bg-color);

      .pwd-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        position: relative;
        font-size: 0.4rem;
        color: var(--theme-text-color-darken);

        &.fill {
          i {
            display: flex;
            width: 0.26rem;
            height: 0.26rem;
            background: var(--theme-text-color-darken);
            border-radius: 50%;
            opacity: 1;
          }
        }

        &:not(:last-child) {
          border-right: 0.01rem solid var(--theme-color-line)
        }

        &::after {
          content: '';
          position: absolute;
          left: -1px;
          right: -1px;
          bottom: -1px;
          top: -1px;
          border: 1px solid transparent;
        }

        &:first-child::after {
          border-radius: 0.14rem 0 0 0.14rem;
        }


        &:last-child::after {
          border-radius: 0 0.14rem 0.14rem 0;
        }


        i {
          height: 0.32rem;
          width: 0.025rem;
          background: var(--theme-text-color-darken);
          border-radius: 50%;
          opacity: 0;
          display: inline-block;
        }
      }


    }

    .pwd-input {
      opacity: 1;
      position: absolute;
      top: 0;
      z-index: -1;
      opacity: 0;

      &:focus+.pwd-wrap .pwd-item {
        &.active {

          &::after {
            border-color: var(--theme-primary-color);
          }

          i {
            animation: cursorAnimation 1s infinite;
          }
        }

        &.fill:last-child::after {
          border-color: var(--theme-primary-color);
        }
      }
    }

    .pwd-input-item-fill {
      color: var(--theme-text-color) !important
    }
  }

}
</style>