export default {
    Home: "Beranda",
    offers: "Penawaran",
    Profile: "Profil",
    Profile_me_info: "Profil",
    Withdrawal: "Penarikan",
    Customer_service: "Layanan Pelanggan",
    login: "Masuk",
    register: "Daftar",
    deposit: "Setoran",
    withdraw: "Tarik",
    Withdraw_Records: "Riwayat Penarikan",
    Audit_Report: "Laporan Audit",
    Manage_Account: "Kelola Akun",
    Balance: "Saldo",
    Withdrawal_Password: "Kata Sandi Penarikan",
    Not_Started: "Belum Dimulai",
    Completed: "Selesai",
    Ongoing: "Berlangsung",
    interest: "Bunga",
    dont_show: "Jangan tampilkan lagi hari ini",
    Casino: "Kasino",
    Reward: "Hadiah",
    Agent: "Agen",
    Event: "Acara",
    APP_download: "Unduh Aplikasi",
    Mission: "Misi",
    Games: "Permainan",
    Support: "Dukungan",
    Online_Service: "Layanan Online",
    Help_Center: "Pusat Bantuan",
    Message_Center: "Pusat Pesan",
    forgot_password: "Lupa Kata Sandi",
    Remmeber_Me: "Ingat Saya",
    Register_An_Account: "Daftar Akun",
    Username: "Nama Pengguna",
    Name: "Nama",
    user_tip: "4-16 karakter, mendukung huruf Inggris/angka",
    password: "Kata Sandi",
    password_tip: "6-16 karakter",
    password_agin_tip: "Kata sandi tidak cocok. Silakan masukkan ulang.",
    Re_enter_password: "Masukkan Ulang Kata Sandi",
    over_tip: "Saya berusia di atas 18 tahun, telah membaca, dan setuju dengan",
    User_Agreement: "Perjanjian Pengguna",
    I_have_read: "Saya telah membaca",
    user_agreement_1: `1. Untuk menghindari sengketa taruhan, anggota harus membaca aturan perusahaan sebelum masuk ke Aplikasi. Setelah pemain "Saya Setuju" dan memasuki perusahaan untuk bertaruh, dianggap bahwa Anda setuju dengan Perjanjian Pengguna perusahaan. `,
    user_agreement_2: `2. Tanggung jawab anggota untuk memastikan kerahasiaan akun dan informasi login. Semua taruhan online yang dilakukan dengan nomor akun dan kata sandi anggota akan dianggap sah. Mohon ubah kata sandi Anda secara berkala. Perusahaan tidak bertanggung jawab atas kompensasi taruhan yang dilakukan pada akun dan kata sandi yang dicuri.`,
    user_agreement_3: `Perusahaan berhak mengubah perjanjian ini atau aturan permainan atau aturan kerahasiaan dari waktu ke waktu. Ketentuan yang diubah akan berlaku pada tanggal yang ditentukan setelah perubahan terjadi, dan hak untuk mengambil keputusan akhir dalam semua sengketa disimpan oleh perusahaan.`,
    user_agreement_4: `4. Pengguna harus berusia sesuai dengan hukum negara tempat tinggal untuk menggunakan kasino atau aplikasi online. Taruhan online yang tidak berhasil dikirim akan dianggap batal.`,
    user_agreement_5: `5. Ketika pemain terputus secara otomatis atau dipaksa dari permainan sebelum hasil permainan diumumkan, hal ini tidak akan mempengaruhi hasil permainan.`,
    Messages: "Pesan",
    Account: "Akun",
    Main_Wallet: "Dompet Utama",
    To_achieve_next_level: "Untuk mencapai level berikutnya",
    Betting_still_lacks: "Taruhan masih kurang",
    Upgrade_Needs_Wager: "Peningkatan Membutuhkan Taruhan",
    Account_Details: "Detail Akun",
    Bet_Records: "Riwayat Taruhan",
    Report: "Laporan",
    Reports: "Laporan",
    Withdrawal_Management: "Manajemen Penarikan",
    Withdrawal_Method: "Metode Penarikan",
    million_monthly: "juta bulanan",
    Security_Center: "Pusat Keamanan",
    Language: "Bahasa",
    Feedback_to_get_rewards: "Berikan Umpan Balik untuk Mendapatkan Hadiah",
    Quit: "Keluar",
    Login_Now: "Masuk Sekarang",
    First_deposit_upon_registration: "Setoran Pertama Saat Mendaftar",
    Bonus: "Bonus",
    Collected: "Diterima",
    Task_time: "Waktu Tugas",
    bonus_1: "Tugas Jangka Panjang (setiap akun baru hanya bisa berpartisipasi sekali)",
    bonus_2: "II.Kondisi Tugas:",
    bonus_2_content: "Lengkapi pengaturan akun dan tautan keamanan",
    bonus_3: "III.Isi Tugas:",
    bonus_3_content: `1.Setiap akun baru yang terdaftar dapat menyelesaikan tugas-tugas di atas, dan setelah selesai, akan menerima jumlah bonus tertentu. Semakin tinggi kesulitan, semakin besar hadiahnya.<br>2.Karena setiap akun sepenuhnya anonim, jika dicuri, yang menyebabkan hilangnya dana, tidak dapat dipulihkan. Oleh karena itu, kami mewajibkan tautan verifikasi dua langkah, terutama ketika menambahkan alamat penarikan, untuk membuktikan bahwa ini adalah operasi pribadi dan memastikan keamanan Anda.<br>3.Kondisi yang dipenuhi dapat diklaim langsung, dan dapat diklaim langsung di platform mana pun; mereka akan kedaluwarsa jika tidak diklaim (kegagalan untuk diklaim dianggap sebagai penyerahan sukarela).<br>4.Karena bonus untuk tugas ini relatif tinggi, bonus memerlukan 2 kali audit (yaitu, audit, taruhan, atau taruhan yang valid) untuk penarikan, tanpa batasan platform permainan.<br>5.Tugas ini hanya untuk operasi manual normal pemilik akun; menyewakan, menggunakan curang, bot, perjudian lintas akun, saling menyikat, arbitrase, API, protokol, memanfaatkan celah, kontrol kelompok, atau sarana teknis lainnya dilarang. Pelanggaran akan mengakibatkan pembatalan atau pengurangan hadiah, pembekuan akun, atau bahkan penempatan dalam daftar hitam.<br>6.Untuk menghindari perbedaan pemahaman teks, platform akan menyimpan interpretasi akhir dari acara ini.`,
    Accumulated_bets_made: "Taruhan yang telah diakumulasikan",
    Go: "Pergi",
    Deposit_Records: "Riwayat Setoran",
    Online_Deposit: "Setoran Online",
    Deposit_Amount: "Jumlah Setoran",
    Bonus_event_explanation: "Penjelasan Acara Bonus",
    Deposit_Now: "Setor Sekarang",
    The_first_deposit_to_receive: "Setoran pertama untuk menerima",
    Total_Deposit: "Total Setoran",
    Deposit_Details: "Detail Setoran",
    Today: "Hari Ini",
    Yesterdaty: "Kemarin",
    Days: "Hari",
    Day: "Hari",
    DAY: "HARI",
    All: "Semua",
    Total_Withdrawal: "Total Penarikan",
    Total_Withdraw: "Total Penarikan",
    Accumulated_Claimed_Benefits: "Manfaat yang Diklaim",
    Transaction_Type: "Jenis Transaksi",
    Action: "Tindakan",
    Deposit_Method: "Metode Setoran",
    Deposit_channels: "Saluran Setoran",
    Time_Created: "Waktu Dibuat",
    Order_No: "Nomor Pesanan",
    Newbie_Bonus: "Bonus Pemula",
    Daily_Mission: "Misi Harian",
    Claim_All: "Klaim Semua",
    History: "Riwayat",
    Rebate: "Cashback",
    Claim: "Klaim",
    Claim_1: "Klaim",
    Claimed: "Diklaim",
    Next: "Selanjutnya",
    No_Records: "Tidak Ada Riwayat",
    Level: "Level",
    Valid_Bets: "Taruhan Valid",
    First_Deposit: "Setoran Pertama",
    Online_login_and_counts: 'Offline',
    Online_login_and_counts_pc: "Login Online dan Hitungan",
    Commission: "Komisi",
    Agent_Network: "Jaringan Agen",
    Referral_Link: "Tautan Referral",
    Performance: "Performa",
    Subordinate_s_Wagers: "Taruhan Bawahan",
    All_data: "Semua Data",
    Subordinate_stats: "Statistik Bawahan",
    Agent_Bonus_Rate: "Tingkat Bonus Agen",
    Collectable: "Dapat Dikumpulkan",
    Agent_Tier: "Tingkat Agen",
    Promotion_conditions: "Kondisi Promosi (Performa Kumulatif)",
    Total_Pending_Audit: "Total Menunggu Audit",
    Audit_Status: "Status Audit",
    Funds_Change: "Perubahan Dana",
    Number_of_audits: "Jumlah Audit",
    Total_Audit_Required: "Total Audit yang Diperlukan",
    Pending_audit: "Menunggu Audit",
    Audited: "Telah Diaudit",
    Limited_to_the_platform: "Terbatas pada Platform",
    Game_Limited: "Terbatas pada Permainan",
    Total_Bet_Lucky_Spin: "Total Taruhan Lucky Spin",
    My_Link: "Tautan Saya",
    Quick_Share: "Bagikan Cepat",
    valid_s: "Bawahan Valid",
    people: " orang",
    Details: "Detail",
    box_tip: "Apa itu jumlah referral yang valid? (Memenuhi kondisi berikut secara bersamaan)",
    the_s: "Total setoran bawahan",
    the_turnover: "Total omset bawahan",
    Or_the_above: "Atau di atas",
    Create: "Buat",
    Mine: "Saya",
    Feedback_Content: "Isi Umpan Balik",
    Suggestions_for_revision: "Saran untuk Revisi",
    Attachment: "Lampiran",
    Easier_to_be_adopted: "Lebih Mudah Diterima",

    Reupload: "Unggah Ulang",
    upload_tip: "Mendukung format gambar dan video, ukuran tidak boleh melebihi 50MB",
    Reward_Rules: "Aturan Hadiah",
    Reward_Rules_content: "Kami telah menyiapkan bonus besar untuk mengumpulkan umpan balik khusus untuk mengoptimalkan sistem dan fitur kami agar lebih baik bagi Anda! Setelah diterima, hadiah akan diberikan berdasarkan pentingnya (tidak termasuk yang tidak diterima).",
    Submit_feedback: "Kirim Umpan Balik",
    No_Messages: "Tidak Ada Pesan",
    online_tip: "Layanan pelanggan profesional selalu online untuk membantu Anda dengan pertanyaan apa pun.",
    plat_sup: "Dukungan",
    News: "Berita",
    Notification: "Notifikasi",
    Marquee: "Marquee",
    daily_i_1: "Telah masuk secara berturut-turut selama",
    daily_i_2: "hari",
    Required_deposit: "Setoran yang dibutuhkan",
    Required_bets: "Taruhan yang dibutuhkan",
    daily_rule_title_1: "I.Kebutuhan Acara:",
    daily_rule_content_1: "Setoran + Taruhan (reset setelah 7 hari berturut-turut)",
    daily_rule_title_2: "II.Isi Acara:",
    daily_rule_content_2: `1.Setoran harian dan taruhan yang memenuhi syarat acara diperlukan untuk absensi sukses untuk
  klaim<br>
  bonus tetap atau bonus misteri yang sesuai, hingga 19.<br>
  2.Acara ini adalah acara absensi berturut-turut (dimulai kembali dari hari 1 jika terputus);<br>
  3.Hadiah hanya dapat diklaim secara manual di iOS, Android, H5, PC<br>
  4.Bonus yang diberikan dalam acara ini (tidak termasuk modal) memerlukan 1 kali rollover (yaitu, verifikasi,
  taruhan, atau taruhan yang valid) untuk penarikan, taruhan tidak terbatas pada platform permainan;<br>
  5.Acara ini hanya untuk operasi manual normal pemilik akun, penyewaan, penggunaan perangkat lunak curang,
  bot,<br>
  perjudian antar akun yang berbeda, manipulasi salingan, arbitrase, API, protokol, memanfaatkan
  celah, kontrol kelompok, atau sarana teknis lainnya dilarang, jika tidak hadiah dapat dibatalkan
  atau<br>
  dikurangi, atau akun dapat dibekukan atau bahkan dimasukkan ke dalam daftar hitam;<br>
  6.Untuk menghindari perbedaan pemahaman teks, platform akan menyimpan interpretasi akhir
  dari<br>
  acara ini.`,
    Back: "Kembali",
    Hot: "Populer",
    Recent: "Baru-baru ini",
    Distributed: "Distribusi",
    Deposited: "Disetorkan",
    Transfer_in: "Transfer Masuk",
    Transfer_out: "Transfer Keluar",
    Settlement_period: "Periode Penyelesaian",
    hours: "jam",
    Total_claimed: "Total Klaim",
    Interest_ceiling: "Plafon Bunga",
    No_restrictions: "Tidak Ada Batasan",
    Total_Earnings: "Total Pendapatan",
    Record_details: "Detail Rekam",
    Interest_rules: "Aturan Bunga",
    Interest_tips: "Suku Bunga Tahunan 15%",
    Time: "Waktu",
    Type: "Jenis",
    Amount: 'Jumlah',
    Rebate_Records: 'Riwayat Cashback',
    Rebate_rate: "Tingkat Cashback",
    Receive_amount: "Jumlah Diterima",
    Rebateable: "Dapat Cashback",
    to_level: "Untuk mencapai level berikutnya",
    bsl: "Taruhan masih kurang",
    VIP_Level_List: "Daftar Level VIP",
    Promotion_Bonus: "Bonus Promosi",
    Weekly_Bonus: 'Bonus Mingguan',
    Monthly_Bonus: 'Bonus Bulanan',
    Tips: "Tip",
    tips_content: `Untuk naik ke level berikutnya, berdasarkan taruhan valid kumulatif yang ada, taruhan tambahan diperlukan. Misalnya, untuk naik ke VIP 1, Anda memerlukan 1000 taruhan valid kumulatif, dan untuk naik ke VIP 2, Anda memerlukan 2000 taruhan valid kumulatif. Oleh karena itu, untuk mencapai VIP 2, pemain memerlukan total 3000 taruhan valid kumulatif (1000 + 2000), dan seterusnya.`,
    vip_rule_title: "Penjelasan Aturan VIP",
    vip_rule: `1.Standar Promosi: Memenuhi persyaratan promosi VIP (yaitu, setoran atau taruhan valid memenuhi kriteria) untuk naik ke level VIP yang sesuai dan menerima bonus promosi yang sesuai. Jika naik beberapa level secara berturut-turut, Anda dapat menerima semua bonus promosi level. Bonus dapat diklaim secara real-time;\r\n
  2.Bonus Harian: Dengan memenuhi persyaratan setoran harian dan taruhan valid pada level saat ini, Anda dapat menerima bonus harian yang sesuai. Jika naik beberapa level secara berturut-turut, hanya bonus harian level saat ini yang dapat diperoleh. Bonus dapat diklaim secara real-time;\r\n
  3.Bonus Mingguan: Dengan memenuhi persyaratan setoran dan taruhan valid pada level saat ini setiap minggu, Anda dapat menerima bonus mingguan yang sesuai. Jika naik beberapa level secara berturut-turut, hanya bonus mingguan level saat ini yang dapat diperoleh. Bonus dapat diklaim secara real-time;\r\n
  4.Bonus Bulanan: Dengan memenuhi persyaratan setoran dan taruhan valid pada level saat ini setiap bulan, Anda dapat menerima bonus bulanan yang sesuai. Jika naik beberapa level secara berturut-turut, hanya bonus bulanan level saat ini yang dapat diperoleh. Bonus dapat diklaim secara real-time;\r\n
  5.Waktu Kadaluwarsa Hadiah: Bonus yang diterima disimpan selama %d hari. Jika tidak diklaim secara aktif selama periode ini, akan secara otomatis dikreditkan ke akun. Misalnya: jika hadiah diperoleh pada 1 Januari dan disimpan selama %d hari, akan secara otomatis dikreditkan ke akun pada 1 Januari pukul 00:00:00.\r\n
  6.Penjelasan Pemeliharaan: Setelah mencapai level VIP tertentu, jika setoran dan taruhan valid anggota dari bulan sebelumnya tidak memenuhi persyaratan pemeliharaan, mereka akan secara otomatis turun satu level.\r\n
  7.Catatan Audit: Bonus VIP perlu dipertaruhkan 1 kali (yaitu, diaudit, dimainkan, atau melalui taruhan valid) untuk dapat ditarik, taruhan tidak terbatas pada platform permainan;\r\n
  8.Pernyataan Acara: Fitur ini hanya untuk taruhan permainan normal pemilik akun. Penyewaan akun, taruhan tanpa risiko (cocok, menyikat, peluang rendah), arbitrase jahat, penggunaan plugin, bot, memanfaatkan perjanjian, celah, antarmuka, kontrol kelompok, atau sarana teknis lainnya dilarang. Setelah diverifikasi, platform berhak mengakhiri login anggota, menghentikan penggunaan situs web anggota, dan menyita bonus dan keuntungan yang tidak pantas tanpa pemberitahuan sebelumnya.\r\n
  9.Penjelasan: Dengan mengklaim hadiah VIP, platform menganggap anggota setuju untuk mematuhi kondisi dan peraturan yang sesuai. Untuk menghindari kesalahpahaman dalam interpretasi teks, platform menyimpan hak interpretasi akhir acara ini.`,
    Misson: "Misi",
    Set_Up: "Atur",
    Link: "Tautkan",
    Please_link_your_mobile_phone: "Silakan tautkan nomor telepon seluler Anda",
    Please_link_your_mail: "Silakan tautkan email Anda",
    birth_tip: "Tanggal Lahir (tidak dapat diubah setelah ditetapkan)",
    pltwaf: "Silakan tautkan akun penarikan terlebih dahulu",
    ped: 'Silakan masukkan 6-40 digit',
    plw: 'Silakan masukkan WhatsApp',
    pefa: "Silakan masukkan akun Facebook",
    pet: 'Silakan masukkan Telegram',
    eut: 'Masukkan hingga 5-40 ',
    Year: "Tahun",
    Save: "Simpan",
    Male: "Laki-laki",
    Female: "Perempuan",
    Statement: "Pernyataan",
    Cumulative_bet_amount: "Jumlah Taruhan Kumulatif",
    Total_Valid_Bets: "Total Taruhan Valid",
    Total: "Total W/L",
    nob: "Jumlah Taruhan",
    ba: "Jumlah Taruhan",
    Ganhos: "W/L",
    Verify_Login_Password: "Verifikasi Kata Sandi Login",
    you_tip: "Anda dapat menautkan nomor telepon setelah memverifikasi kata sandi login",
    Other_Verification_Methods: "Metode Verifikasi Lainnya",
    The_above_method_is_not_available: "Metode di atas tidak tersedia?",
    Contact_Us: "Hubungi Kami",
    Enter_Password: "Masukkan Kata Sandi",
    Mobile_Phone: "Nomor Telepon Seluler",
    phone: "Silakan masukkan nomor telepon",
    Player_ID: "ID Pemain",
    Unlinked: "Tidak Ditautkan",
    Email: "Email",
    Login_Password: "Kata Sandi Login",
    Security_Question: "Pertanyaan Keamanan",
    Link_Third_party_Login: "Tautkan Login Pihak Ketiga",
    Unset: "Belum Diatur",
    set_title: "Ini adalah penarikan pertama Anda, Anda perlu menetapkan kata sandi penarikan terlebih dahulu",
    set_up: "Atur Kata Sandi Penarikan",
    New_Withdrawal: "Kata Sandi Penarikan Baru",
    Old_Withdrawal: "Kata Sandi Penarikan Lama",
    password_note: `Catatan: Kata sandi penarikan Anda melindungi keamanan dana Anda. Sangat penting dan
    hanya boleh diketahui oleh Anda sendiri untuk menghindari kerugian finansial.`,
    confirm: "konfirmasi",
    Confirm: "Konfirmasi",
    Current_Lucky_Value: "Nilai Beruntung Saat Ini",
    you_still_need: "Anda masih perlu bertaruh",
    to_get: "untuk mendapatkan",
    Lucky_Points: "Poin Beruntung",
    Instant: "Undian Instan",
    Luck_Value: "Nilai Beruntung",
    Silver: "Perak",
    Golden: "Emas",
    Diamond: "Berlian",
    Reward_announcement: "Pengumuman Hadiah",
    My_Records: 'Rekam Saya',
    won_at: "menang pada",
    returntable_rule_content: "Total taruhan valid (yaitu, taruhan pada platform tertentu atau audit)",
    Designated_platform: "Platform Tertentu",
    return_table_content_2: `1.Selama periode acara, setiap 1 taruhan valid secara otomatis mendapatkan 1 poin beruntung. 1000-10000 poin beruntung memungkinkan satu undian, dengan hadiah maksimum 9999.<br>
    2.Poin beruntung yang diperoleh hari ini dan tidak digunakan pada hari berikutnya akan kedaluwarsa dan reset menjadi nol;<br>
    3.Hadiah hanya dapat diklaim secara manual di iOS, Android, H5, PC<br>
    4.Bonus yang diberikan dalam acara ini (tidak termasuk modal) memerlukan 1 kali rollover (yaitu, verifikasi,
    taruhan, atau taruhan yang valid) untuk penarikan, taruhan tidak terbatas pada platform permainan;<br>
    5.Acara ini hanya untuk operasi manual normal pemilik akun, penyewaan, penggunaan perangkat lunak curang,
    bot, perjudian antar akun yang berbeda, manipulasi salingan, arbitrase, API, protokol, memanfaatkan
    celah, kontrol kelompok, atau sarana teknis lainnya dilarang, jika tidak hadiah dapat dibatalkan
    atau dikurangi, atau akun dapat dibekukan atau bahkan dimasukkan ke dalam daftar hitam;<br>
    6.Untuk menghindari perbedaan pemahaman teks, platform akan menyimpan interpretasi akhir
    dari acara ini.`,
    twde: "Total taruhan selama periode acara",
    Used_Lucky_Value: "Nilai Beruntung yang Digunakan",
    Expired_Lucky_Value: "Nilai Beruntung yang Kedaluwarsa",
    Available_Lucky_Value: "Nilai Beruntung yang Tersedia",
    Claim_Successfully: "Klaim Berhasil",
    Total_Account_Balance: "Saldo Akun Total",
    One_click_transfer: "Transfer Satu Klik",
    Live: "Live",
    Fishing: "Memancing",
    Sports: "Olahraga",
    Cards: "Kartu",
    Lottery: "Togel",
    Blockchain: "Permainan Blockchain",
    Slots: "Slot",
    All_Type: "Semua Jenis",
    ALL_Platform: "Semua Platform",
    Platform: "Platform",
    Total_Commission: "Komisi Total",
    Referral_information: "Informasi Referral",
    More: "Lebih",
    Commission_Collected: "Komisi yang Diterima",
    Last_Commission: "Komisi Terakhir",
    Total_Members: "Total Anggota",
    Total_angent: "Total",
    Direct_Subordinates: "Bawahan Langsung",
    Others: "Lainnya",
    Total_Perf: "Total Performa",
    Sub_Perf: "Performa Bawahan",
    Others_Perf: "Performa Lainnya",
    Subordinate_Wagers: "Taruhan Bawahan",
    Total_valid_bets: "Total taruhan valid",
    total_no: "Total jumlah taruhan",
    Total_angent_wl: "Total W/L",
    Withdrawal_Account: "Akun Penarikan",
    Hide: "Sembunyikan",
    Show: "Tampilkan",
    Add_New_Bank_Account: "Tambah Akun Bank Baru",
    Enter_PIN: "Masukkan PIN",
    for_you_account: "Untuk keamanan akun Anda, silakan masukkan kata sandi penarikan",
    pix_tip: "Akun PIX tidak boleh kosong",
    digits_11: "Silakan masukkan 11 digit",
    digits_6: "Silakan masukkan 6 digit",
    Set_as_Default: "Atur Sebagai Default",
    Add_withdrawal_account: "Tambah Akun Penarikan",
    add_account_tip: "Silakan pilih akun penarikan terlebih dahulu",
    with_tip_1: "Jumlah taruhan yang masih diperlukan untuk penarikan adalah",
    with_tip_2: " ",
    Normal_withdrawal: "Penarikan Biasa",
    Min: 'Min',
    Max: 'Max',
    Withdrawal_Amount_cannot_be_empty: 'Jumlah Penarikan tidak boleh kosong',
    withdraw_err: "Penarikan tidak tersedia sampai audit selesai",
    wp_can_be_empty: "Kata Sandi Penarikan tidak boleh kosong",
    Can_be_collected_today: "Dapat dikumpulkan hari ini",
    Fee: "Biaya",
    Add: "Tambah",
    All_status: "Semua Status",
    Not_settled: 'Belum Diselesaikan',
    Settled: 'Diselesaikan',
    Order_canceled: "Pesanan Dibatalkan",
    Confirm_New_Withdrawal_Password: "Konfirmasi Kata Sandi Penarikan Baru",
    Search: "Cari",
    Search_Games: "Cari Permainan",
    tab_Search: "Cari",
    Favorite: "Favorit",
    Reports_tab: "Laporan",
    em_claim: "Saat ini tidak ada hadiah yang tersedia untuk diklaim",
    Start_Date: "Tanggal Mulai",
    End_Date: "Tanggal Berakhir",
    Cancel: "Batal",
    My_ID: "ID Saya",
    Contribution_Commission: "Komisi Kontribusi",
    total_first: "Total pemain setoran pertama",
    Other_first_deposit: "Setoran pertama lainnya",
    Other_deposit: "Setoran lainnya",
    Direct_first_deposit: "Setoran pertama langsung",
    Subordinate_Deposits: "Setoran Bawahan",
    Subordinate: "Bawahan",
    Full_Screen: "Layar Penuh",
    Refresh: "Segarkan",
    Yes: "Ya",
    No: "Tidak",
    Settlement_date: "Tanggal Penyelesaian",
    Contributors: "Kontributor",
    Joining_time: "Waktu Bergabung",
    Member_ID: "ID Anggota",
    Statistical_date: "Tanggal Statistik",
    Number_of_bets: "Jumlah Taruhan",
    Total_W_L: "Total W/L",
    bounusTip: "Anggota bawah hanya akan memberikan cashback jika setoran mereka ≥0 dan telah melakukan taruhan valid ≥0 selama periode penyelesaian.",
    Type_Details: "Detail Jenis",
    Bet_ID: "ID Taruhan",
    Bet_amount: "Jumlah Taruhan",
    In_game: "Digunakan dalam permainan",
    page: 'halaman',
    Page: 'Halaman',
    entries: 'entri',
    page_total_1: "Total",
    page_total_2: "entri",
    more_1: "Saat ini menampilkan",
    more_2: "permainan populer dari",
    more_3: "",
    Load_More: "Muat Lebih Banyak",
    Reminder: "Pemberitahuan",
    Log_out_from_account: "Keluar dari Akun",
    Cashback_Exclusivo: "Cashback Exclusivo",
    privileges_Exclusivo: "Privilese VIP",
    login_password_tip: 'Ubah kata sandi login setelah memverifikasi kata sandi login',
    Confirm_the_new_password: "Konfirmasi kata sandi baru",
    Daily_loss_rescue_fund: "Dana Penyelamatan Kerugian Harian",
    Loss_amount: "Jumlah Kerugian",
    Extra_Rewards: "Hadiah Tambahan",
    Yesterday_s_losses: "Kerugian Kemarin",
    Today_s_Rescue_Bonus: "Bonus Penyelamatan Hari Ini",
    Lucky_wager: "Taruhan Beruntung",
    No_winning_wager_today: "Tidak ada taruhan menang hari ini",
    max_times_claimed: "Maks. {num} kali diklaim hari ini ({num} kali tersisa)",
    Bet_number_last_digits: "Angka terakhir nomor taruhan",
    Reward_multiple: "Pengganda Hadiah",
    Reward_limit: "Batas Hadiah",
    reward_amount: "Jumlah Hadiah = Jumlah Taruhan pada tiket tersebut x Pengganda Bonus, tanpa melebihi batas",
    Music: 'Musik',
    Cycle: 'Putar Ulang',
    Shuffle: 'Acak',
    Repeat: 'Ulangi',
    Downloaded: 'Terunduh',
    System_Music: "Musik Sistem",
    My_music: "Musik Saya",
    change_password_note: "Perhatian: Kata sandi penarikan melindungi dana Anda dan sangat penting. Simpan sendiri untuk mencegah kerugian finansial",
    Change_Withdrawal_Password: 'Ubah Kata Sandi Penarikan',
    Link_Email: 'Tautkan Email',
    Enter_Email: 'Masukkan Email',
    Deslize_para: 'Geser untuk menyelesaikan teka-teki',
    length_tip: '{lengthRange}digit',
    Numbers: 'Angka',
    Lowercase: 'Huruf Kecil',
    Uppercase: 'Huruf Besar',
    My_superiors: 'Atasan Saya',
    Agent_mode: 'Mode Agen',
    Unlimited_level: 'Perbedaan Tingkat Tidak Terbatas (Penyelesaian Harian)',
    OfferCenter: 'Pusat Penawaran',
    SupportCenter: 'Pusat Dukungan',
    OfficialChannel: 'Saluran Resmi',

    pleaseOpenYour: 'Silakan buka aplikasi pembayaran Anda dan scan atau salin dan tempel kode QR di bawah ini untuk menyelesaikan pembelian Anda;',
    This_QR_code: 'Kode QR ini hanya dapat dibayar sekali. Jika Anda perlu membayar lagi, silakan kembali dan isi ulang;',
    After_the_payment: 'Setelah pembayaran berhasil, Anda dapat kembali ke lobi permainan dan menunggu poin ditambahkan!',
    Effective_time: 'Waktu Efektif',
    Copy_QR_code: 'Salin Kode QR',
    QR_code_address: 'Alamat Kode QR',
    Order_Status: 'Status Pesanan',
    To_be_paid: 'Menunggu Pembayaran',
    Creation_Time: 'Waktu Pembuatan',
    Order_Number: 'Nomor Pesanan',
    Merchant_order_number: 'Nomor Pesanan Penjual',
    success: 'Berhasil',
    failure: 'Gagal',
    Direct_subordinates: "Subordinate Langsung",
};