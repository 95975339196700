<template>
  <el-scrollbar ref="scrollbar" style="height: 100%;">
    <slot />
  </el-scrollbar>
</template>
<script>
export default {
  mounted() {
    this.$refs.scrollbar.handleScroll = () => {
      let wrap = this.$refs.scrollbar.wrap
      this.$refs.scrollbar.moveY = (wrap.scrollTop * 100) / wrap.clientHeight;
      this.$refs.scrollbar.moveX = (wrap.scrollLeft * 100) / wrap.clientWidth;
      let poor = wrap.scrollHeight - wrap.clientHeight
      if (poor == parseInt(wrap.scrollTop) || poor === Math.ceil(wrap.scrollTop) || poor === Math.floor(wrap.scrollTop)) {
        this.$emit('load')
      }
    }
  },
  methods: {
    scrollToTop(){
      this.$refs.scrollbar.$el.querySelector('.el-scrollbar__wrap').scrollTop = 0
    }
  }
}
</script>