import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'
import home from './modules/home'
import music from './modules/music'
import api from './modules/api'


Vue.use(Vuex);

const store = new Vuex.Store({
  state:{code:0},
  modules:{user,home,api,music}
})


export default store
