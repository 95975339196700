<template>
  <div class='date-picker-wrap' v-clickoutside="close">
    <div class="date-picker" ref="dropParent" @click="visible = !visible" :class="[{ focus: visible }, { transparent }]">
      <div class="date-value">{{ leftDateString }}</div>
      <div class="p-l"></div>
      <div class="date-value">{{ rightDateString }}</div>
    </div>
    <pop placement="bottom-start">
      <div class="date-range">
        <div class="left-range">
          <div class="picker-title">{{ $t('Start_Date') }}</div>
          <van-datetime-picker v-model="leftDate" visible-item-count="3" :show-toolbar="false" type="date" title=""
            :min-date="minDate" :max-date="maxDate" />
          <div class="pg-btn-wrap">
            <pg-button size="mini" class="pg-btn" @click="close">{{ $t('Cancel') }}</pg-button>
          </div>
        </div>
        <div class="right-range">
          <div class="picker-title">{{ $t('End_Date') }}</div>
          <van-datetime-picker v-model="rightDate" visible-item-count="3" :show-toolbar="false" type="date" title=""
            :min-date="leftDate" :max-date="maxDate" />

          <div class="pg-btn-wrap">
            <pg-button size="mini" class="pg-btn" type="primary" @click="confirm">{{ $t('Confirm') }}</pg-button>
          </div>
        </div>
        <!-- <date-table selection-mode="range" :date="leftDate" :default-value="defaultValue" :min-date="minDate"
          :max-date="maxDate" :range-state="rangeState" :disabled-date="disabledDate" @changerange="handleChangeRange"
          :first-day-of-week="firstDayOfWeek" @pick="handleRangePick">
        </date-table>
        <date-table
              selection-mode="range"
              :date="rightDate"
              :default-value="defaultValue"
              :min-date="minDate"
              :max-date="maxDate"
              :range-state="rangeState"
              :disabled-date="disabledDate"
              @changerange="handleChangeRange"
              :first-day-of-week="firstDayOfWeek"
              @pick="handleRangePick">
            </date-table> -->
      </div>
    </pop>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter';
import pop from '../drop/pop.vue';
import dateTable from './date-table.vue';

import {
  formatDate,
  parseDate,
  isDate,
  modifyDate,
  modifyTime,
  modifyWithTimeString,
  prevYear,
  nextYear,
  prevMonth,
  nextMonth,
  nextDate,
  extractDateFormat,
  extractTimeFormat
} from 'element-ui/src/utils/date-util';
import { getLocalTime } from '@/util'
export default {
  name: 'PgDatePicker',
  components: { pop, dateTable },
  mixins: [Emitter],
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => { }
    },
  },
  data() {
    return {
      defaultValue: null,

      minDate: new Date(2020, 0, 1),
      maxDate: getLocalTime(-3),
      currentDate: getLocalTime(-3),
      visible: false,
      rangeState: {
        endDate: null,
        selecting: false,
        row: null,
        column: null
      },
      disabledDate: '',
      leftDate: getLocalTime(-3),
      firstDayOfWeek: 7,
      rightDate: nextMonth(getLocalTime(-3)),
    }
  },
  computed: {
    leftDateString() {
      return formatDate(this.leftDate)
    },
    rightDateString() {
      return formatDate(this.rightDate)
    }
  },
  watch: {
    visible(val) {
      this.broadcast('dropItem', 'visible', [
        val,
      ]);
    },
    value: {
      handler(val) {
        if (val[0]) {
          this.leftDate = val[0]
        }
        if (val[1]) {
          this.rightDate = val[1]
        }
      },
      immediate: true
    },
    leftDate() {
      this.$emit('input', [this.leftDate, this.rightDate])
    },
    rightDate() {
      this.$emit('input', [this.leftDate, this.rightDate])
    }
  },
  methods: {
    confirm() {
      this.close()
      this.$emit('confirm', [this.leftDateString, this.rightDateString])
    },
    close() {
      if (this.visible) {
        this.visible = false
      }
    },
    handleChangeRange(val) {
      this.minDate = val.minDate;
      this.maxDate = val.maxDate;
      this.rangeState = val.rangeState;
    },
    handleRangePick(val, close = true) {
      const defaultTime = this.defaultTime || [];
      const minDate = modifyWithTimeString(val.minDate, defaultTime[0]);
      const maxDate = modifyWithTimeString(val.maxDate, defaultTime[1]);

      if (this.maxDate === maxDate && this.minDate === minDate) {
        return;
      }
      this.onPick && this.onPick(val);
      this.maxDate = maxDate;
      this.minDate = minDate;

      // workaround for https://github.com/ElemeFE/element/issues/7539, should remove this block when we don't have to care about Chromium 55 - 57
      setTimeout(() => {
        this.maxDate = maxDate;
        this.minDate = minDate;
      }, 10);
      if (!close || this.showTime) return;
      this.handleConfirm();
    },
    handleConfirm(visible = false) {
      if (this.isValidValue([this.minDate, this.maxDate])) {
        this.$emit('pick', [this.minDate, this.maxDate], visible);
      }
    },
    isValidValue(value) {
      return Array.isArray(value) &&
        value && value[0] && value[1] &&
        isDate(value[0]) && isDate(value[1]) &&
        value[0].getTime() <= value[1].getTime() && (
          typeof this.disabledDate === 'function'
            ? !this.disabledDate(value[0]) && !this.disabledDate(value[1])
            : true
        );
    },
  },
}
</script>

<style lang='scss' scoped>
.date-range {
  display: flex;
  min-width: 5rem;
  background: var(--theme-main-bg-color);
  border: .01rem solid var(--theme-color-line);
  border-radius: 0.2rem;
  position: relative;

  &::after {
    width: 1px;
    content: '';
    height: 3.2rem;
    background: var(--theme-color-line);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .picker-title {
    color: var(--theme-text-color);
    font-size: .24rem;
    font-weight: 700;
    text-align: center;
    padding: 0.2rem 0;
  }

  .pg-btn-wrap {
    font-size: 0;
    text-align: center;
    padding: 0.2rem 0;
  }

  .left-range {
    flex: 1;
    position: relative;
  }

  .right-range {
    flex: 1;
    position: relative;
  }

  ::v-deep .van-picker {
    background-color: transparent;

    .van-picker-column {
      font-size: .24rem;
    }

    .van-picker__mask {
      background-image: none;
    }

    .van-picker-column__item {
      color: var(--theme-text-color-lighten);
    }

    .van-picker-column__item--selected {
      color: #fff;
    }
  }
}

.date-picker-wrap {
  .date-picker {
    user-select: none;
    display: inline-flex;
    align-items: center;
    border-radius: 0.52rem;
    border: 1px solid #d9d9d9;
    background: var(--theme-main-bg-color);
    min-width: 1.6rem;
    position: relative;
    padding: 0 0.2rem;
    color: var(--theme-text-color-lighten);
    border: 0.01rem solid var(--theme-color-line);

    &.focus {
      border-color: var(--theme-ant-primary-color-13);
    }

    &.transparent {
      background: transparent;
    }

    &::after {
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      height: .01rem;
      width: .11rem;
      background-color: var(--theme-text-color-lighten);
    }

    .date-value {
      font-size: .2rem;
      white-space: nowrap;
      padding: 0.1rem 0.2rem;
      text-align: center;
    }
  }


}

@media screen and (min-width:751px) {
  .date-picker-wrap {
    .date-picker {
      height: .4rem;
    }
  }
}
</style>