import { gameTypes } from "@/config";

export default {
  install: function (Vue) {
    Vue.filter("gameType", function (value) {
      const tempArr = gameTypes.filter((o) => o.value == value);
      return tempArr.length > 0 ? tempArr[0].label : "";
    });
  },
};
