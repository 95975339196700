export default {
    Home: "Inicio",
    offers: "Promoção",
    Profile: "Perfil",
    Profile_me_info: "Informações pessoais",
    Withdrawal: "Saque",
    Customer_service: "Serviço de clientes",
    login: "Entrar",
    register: "Registro",
    deposit: "Depósito",
    withdraw: "Saque",
    Withdraw_Records: "Registro de Retiradas",
    Audit_Report: "Registros de Auditoria",
    Manage_Account: "Gerenciar Conta",
    Balance: "Saldo da Conta",
    Normal_withdrawal: "Retirada Padrão",
    Withdrawal_Password: "Senha de Saque",
    Not_Started: "Não Começou",
    Completed: "Completado",
    Ongoing: "Em curso",
    interest: "Juros",
    dont_show: "Não mostrar novamente hoje",
    Casino: "Cassino",
    Reward: "Bônus",
    Agent: "Convidar",
    tab_agent: "Agente",
    net_info: "Rede do Agente",
    Offer_Center: "Promoção",
    Event: "Evento",
    APP_download: "Baixar App",
    Mission: "Missão",
    Games: "Jogos",
    Support: "Apoio",
    Online_Service: "Apoio online",
    Help_Center: "Centro de Ajuda",
    Message_Center: "Centro de mensagens",
    forgot_password: "Esqueceu a Senha?",
    Remmeber_Me: "Lembrar Senha",
    Register_An_Account: "Registra uma Conta",
    Username: "Nome de Usuário",
    user_tip: "4-16 caráter bit, suporte em inglês/números",
    password: "Senha",
    password_tip: "6-16 caracteres",

    password_agin_tip: "Senha incorreta, digite novamente!",
    Re_enter_password: "Senha incorreta, digite novamente!",
    over_tip: "Tenho 18 anos, li e concordo com ",
    User_Agreement: "Acordo do Usuário",
    I_have_read: "Lido e Compreendido",
    user_agreement_1: `1. Para evitar disputas de apostas, os membros devem ler as regras da empresa antes de entrar no
  aplicativo. Uma vez que o jogador“ Eu concordo” Ao entrar nesta empresa para apostar, será
  considerado que você está de acordo com o Acordo do Usuário da empresa. `,
    user_agreement_2: `2. É de responsabilidade do membro garantir a confidencialidade de sua conta e informações de login. Quaisquer apostas online feitas com o seu número de conta e senha de membro serão consideradas válidas. Por favor, altere sua senha de tempos em tempos. A empresa não se responsabiliza por qualquer compensação de apostas feitas com conta e senha roubadas. `,
    user_agreement_3: `3. A empresa reserva-se o direito de alterar este acordo ou as regras do jogo ou as regras de confidencialidade de tempos em tempos. Os termos modificados entrarão em vigor na data especificada após a ocorrência da alteração, e o direito de tomar decisões finais sobre todas as disputas é reservado à empresa. `,
    user_agreement_4: `4. Os usuários devem ser maiores de idade de acordo com as leis do país de residência para usar cassino ou aplicativo online. As apostas online que não tenham sido submetidas com sucesso serão consideradas nulas.`,
    user_agreement_5: `5. Quando um jogador é desconectado automaticamente ou forçosamente do jogo antes que o resultado do jogo seja divulgado, isso não afetará o resultado do jogo.`,
    Messages: "Mensagens",
    Account: "Conta",
    Main_Wallet: "Carteira central",
    To_achieve_next_level: "Para alcançar o próximo nível",
    Betting_still_lacks: "Apostar ainda falta",
    Upgrade_Needs_Wager: "Aposta necessária",
    Account_Details: "Detalles da Conta",
    Bet_Records: "Apostas",
    Report: "Relatórios",
    Reports: "Relatório Pessoal",
    Withdrawal_Management: "Configura de saque",
    Withdrawal_Method: "Método de Retirada",
    million_monthly: "milhões por mês",
    Security_Center: "Centro de Segurança",
    Language: "Idioma",
    Feedback_to_get_rewards: "Bônus de Sugestão",
    Quit: "Sair",
    Login_Now: "Login Agora",
    First_deposit_upon_registration: "Registar o primeiro depósito",
    Bonus: "Bônus",
    Collected: "Completado",
    Task_time: "Hora da tarefa",
    bonus_1: "Missões de longo prazo (cada nova conta só pode participar uma vez)",
    bonus_2: "II.Condições da tarefa:",
    bonus_2_content: "Complete as definições relevantes e a ligação de segurança da nossa conta",
    bonus_3: "III.Conteúdo da tarefa:",
    bonus_3_content: `1.Todas as Membros recém-registrados completar as tarefas acima mencionadas e receber um determinado montante de bônus após a conclusão da tarefa, quanto maior for a dificuldade, maior será a recompensa.<br>2.Visto que cada conta é completamente anónima, se a mesma for roubada, não será possível recuperar a perda de fundos resultante do roubo. Por conseguinte, impusemos um processo de verificação das contas em duas etapas, em particular a inclusão do e-mail de pagamento para validar as ações realizadas pelo proprietário da conta e garantir a segurança da sua conta.<br>3.Receber diretamente quando as condições são cumpridas. Pode receber diretamente em qualquer um dos pontos das iOS,Android,H5,PC, os fundos são considerados nulos quando expirados (considerados voluntariamente perdidos quando não recolhidos pelo utilizador).<br>4.Devido ao elevado bónus atribuído por esta missão, o bônus oferecido necessita de 2 X corrente (ou seja, auditoria, participações ou apostas válidas) para poder receber o dinheiro. As participações não estão limitadas a plataformas específicas.<br>5.Esta missões tem utilização limitada ao proprietário da conta para uma jogabilidade normal. O recurso a esquemas de aluguer, a utilização de simuladores (programas de burla), robots, apostas intencionais com contas diferentes, configurações intencionais, concertação, associação, acordo, utilização de lacunas, controlo de grupo ou outros meios técnicos de participação são estritamente proibidos, caso contrário, as recompensas serão confiscadas, congeladas ou deduzidas da recompensa, ou as contas poderão mesmo ser postas na lista negra.<br>6.Para evitar diferenças no entendimento do texto, a plataforma manterá a interpretação final deste evento.`,
    Accumulated_bets_made: "Apostas acumuladas feitas",
    Go: "Prosseguir",
    Deposit_Records: "Registro de recarga",
    Online_Deposit: "Depósito on-line",
    Deposit_Amount: "Valor do Depósito",
    Bonus_event_explanation: "Detalhes adicionais do evento de bónus",

    Deposit_Now: "Recarregue Agora",
    The_first_deposit_to_receive: "O primeiro depósito a receber",
    Total_Deposit: "Montante total de recarga",
    Deposit_Details: "Deposit Details",
    Today: "Hoje",
    Yesterdaty: "Ontem",
    Days: "Dias",
    Day: "Dia",
    DAY: "DIA",
    All: "Tudo",
    Total_Withdrawal: "Total de saques",
    Total_Withdraw: "Retiradas Acumuladas",
    Accumulated_Claimed_Benefits: "Benefícios reivindicados acumulados",
    Transaction_Type: "Tipo de transação",
    Action: "Operação",
    Deposit_Method: "Método de depósito",
    Deposit_channels: "Canais de depósito",
    Time_Created: "Hora de Criação",
    Order_No: "Nº do Pedido",
    Newbie_Bonus: "Bônus para novatos",
    Daily_Mission: "Missão Semanal",
    Claim_All: "Coletar Tudo",
    History: "Reg de Coletas",
    Claim: "Pendente",
    Claim_1: "Receber",
    Claimed: "Recolhido:",
    Next: "Seguintes",
    No_Records: "Sem Registros",
    Level: "Nível",
    Valid_Bets: "Apostas Válidas",
    First_Deposit: "Depósito inicial",
    Online_login_and_counts: 'Atual',
    Online_login_and_counts_pc: "Início de sessão e contagem online",
    Commission: "Comissões",
    Promotion_Sharing: "Link de Convite",
    My_Data: "Meus Dados",
    Agent_Network: "Tutorial de promoção",
    Referral_Link: "Meus Indicados",
    Performance: "Minha Comissão",
    Subordinate_s_Wagers: "Finanças diretas",
    All_data: "Todos os dados",
    Subordinate_stats: "Dados diretos",
    Agent_Bonus_Rate: "Taxa de Comissão",
    Collectable: "Coletável",
    Agent_Tier: "Nível do Agente",
    Promotion_conditions: "Condições da promoção (resultados acumulados)",
    Total_Pending_Audit: "Tipo de Transação",
    Audit_Status: "Status da auditoria",
    Funds_Change: "Valor da transação",
    Number_of_audits: "Vezes auditado",
    Total_Audit_Required: "Total para Auditoria",
    Pending_audit: "Auditoria pendente",
    Audited: "Auditado",
    Limited_to_the_platform: "Limitado à plataforma",
    Game_Limited: "Jogo restrito",
    Total_Bet_Lucky_Spin: "Bônus para convidar amigos",
    My_Link: "Link exclusivo",
    Quick_Share: "Partilha rápida",
    valid_s: "Pessoas de nível inferior eficazes ",
    people: " pessoas",
    Details: "Detalhes",
    box_tip: "Qual é o número de jogadores efetivamente promovidos? (cumprir todas as condições indicadas abaixo)",
    the_s: "O subordinado acumulou recargas",
    the_turnover: "O subordinado acumulou apostas",
    Or_the_above: "Ou o acima mencionado",
    Create: "Criar",
    Mine: "meu",
    Feedback_Content: "Conteúdo do comentário",
    Suggestions_for_revision: "Relate os problemas e farei melhorias",
    Attachment: "Anexo",
    Easier_to_be_adopted: "Mais fácil de adotar",
    Reupload: "Carregar Novamente",
    upload_tip: "É suportado o envio de imagens e vídeos (tamanho não superior a 50MB)",
    Reward_Rules: "Regras para recompensas",
    Reward_Rules_content: "Estabelecemos uma grande recompensa para recolher sugestões que permitam otimizar o sistema e o seu funcionamento, a fim de lhe proporcionar uma melhor experiência! Caso sejam adotadas, serão concedidas recompensas.",
    Submit_feedback: "Submeter comentário",
    No_Messages: "Sem Mensagem",
    online_tip: "Serviço ao cliente disponível para ajudar a solucionar problemas e responder a dúvidas apresentadas.",
    plat_sup: "atendimento ao Cliente",
    News: "Notícia",
    Notification: "Notificação",
    Marquee: "Painel Rolante",

    daily_i_1: "Sessão iniciada ",
    daily_i_2: " dias consecutivos",
    Required_deposit: "É necessário para carregar ",
    Required_bets: "Apostas: ",
    daily_rule_title_1: "I.Condições do Evento:",
    daily_rule_content_1: "Depósito + participação total (reinicialização após 7 dias seguidos)",
    daily_rule_title_2: "II.Instruções Do Evento:",
    daily_rule_content_2: `1.Apenas se iniciar sessão e cumprir as condições de recarga e de apostas diárias, receberá um bónus fixo ou bónus misterioso, até 18;<br>
  2.Este evento é uma atividade de check-in consecutivo (se interrompida, a contagem será reiniciada e começará a partir do primeiro dia);<br>
  3.As recompensas só podem ser retiradas manualmente no iOS、Android、H5、PC;<br>
  4.A atribuição de bônus desta atividade (excluindo o capital) requer 1 X rollover (isto é, auditoria, participação ou aposta válida) para levantar o dinheiro. Participações não limitadas por jogo ou plataforma:<br>
  5.Este evento é limitado a operações normais realizadas pelo titular da conta. É proibido alugar, usar plug-ins externos, robôs, apostar em contas diferentes, brushing mútuo, arbitragem, interface, protocolo, exploração de vulnerabilidades, controle de grupo ou outros meios técnicos para participar. Caso contrário, as recompensas serão canceladas ou deduzidas, a conta será congelada ou até mesmo adicionada à lista negra;<br>
  6.Para evitar diferenças no entendimento do texto, a plataforma manterá a interpretação final deste evento.<br>`,
    Back: "Retornar",

    Hot: "Populares",
    Recent: "Recente",

    Distributed: "Distribuído",
    Deposited: "Existir",
    Transfer_in: "Depósito",
    Transfer_out: "Retirar",
    Settlement_period: "Ciclo de assentamento",
    hours: "hora",
    Total_claimed: "Coleção cumulativa",
    Interest_ceiling: "Coletar",
    No_restrictions: "Ilimitado",

    Total_Earnings: "Renda Total",
    Record_details: "Detalhes do registro",
    Interest_rules: "Regras de juros",
    Interest_tips: "Taxa de juros anual 15%",
    Time: "Horas",
    Type: "Tipos",
    Amount: "Quantia",
    Rebate_Records: `Reg <br>de Coletas`,
    Rebate_rate: "Taxa de Cashback",
    Receive_amount: "Receba valor",
    Rebateable: "Coletável",
    to_level: "Para o próximo nível",
    bsl: "Aposta válida ainda é necessária  ",
    VIP_Level_List: "Lista de níveis VIP",
    Promotion_Bonus: "Bônus de aumento de nível",
    Weekly_Bonus: "Bônus Semanal",
    Monthly_Bonus: "Bônus Mensal",
    Tips: "Termos de uso",
    vip_rule_title: "Instruções sobre regras VIP",
    tips_content: `Ou seja, a promoção para o próximo nível precisa se basear na aposta efetiva acumulada original e, em seguida, na quantidade de apostas efetivas, a promoção de apostas efetivas VIP1 requer 1.000, e as apostas válidas VIP2 requerem 2000, então os membros precisam acumular -se acumulados Apostas efetivas 1000+2000 = 3000 para avançar para o avanço VIP2, assim por diante.\n`,
    vip_rule: `1.Padrão de promoção: atenda aos requisitos da promoção VIP (ou seja, a recarga ou apostas eficazes podem atender às condições), você pode avançar para o nível VIP correspondente e obter o bônus de promoção correspondente.O bônus pode ser recebido de tempos em tempos;\r\n
  2.Salário diário: Se a recarga diária e as apostas válidas atenderem aos requisitos salariais diários do nível atual, você poderá obter o bônus salarial diário correspondente. Se você avançar para vários níveis consecutivos, só poderá obter o bônus salarial diário do atual nível.O bônus pode ser recebido de tempos em tempos;\r\n
  3.Salário Semanal: Se a recarga semanal e as apostas válidas atenderem ao nível atual de requisitos salariais semanais, você poderá obter o bônus salarial semanal correspondente. Se você avançar para vários níveis consecutivos, poderá obter apenas o nível atual de bônus salarial semanal.O bônus pode ser recebido de tempos em tempos;\r\n
  4.Lulu mensal: recarga mensal e apostas efetivas para atender ao nível atual do Lulu mensal, e você pode obter o bônus de prêmio mensal correspondente.O bônus pode ser recebido de tempos em tempos;\r\n
  5.Tempo de vencimento da recompensa: reservas de longo prazo para bônus obtidos, você precisa receber manualmente;\r\n
  6.Instruções para auditoria: o bônus VIP oferecido pode ser levantado apenas após o cumprimento do requisito de rollover 1x (ou seja, auditoria, apostas ou apostas válidas), independentemente da plataforma em que joga;\r\n
  7.Declarações: Esta função está limitada às operações normais dos titulares de contas. É proibido alugar contas, efetuar apostas sem risco (apostas com contas diferentes, swiping mútuo e swiping de odds baixas), arbitragem viciosa, utilizar plug-ins, robôs, exploração de acordos, lacunas, interfaces, controlo de grupo ou outros meios técnicos de participação; caso contrário, uma vez provado, a plataforma tem o direito de proibir os membros de iniciar sessão, suspender a utilização do nosso website, e confiscar o bônus e os ganhos indevidos, sem qualquer aviso especial;\r\n
  8.Instruções: Ao reclamar o bônus VIP, considera-se que os membros aceitam e cumprem as regras correspondentes. A fim de evitar diferenças na compreensão do texto, a plataforma reserva o direito final de interpretar esta atividade.`,
    Misson: "Missão",
    Set_Up: "Configurar",
    Link: "Ir para Vinculação",
    Please_link_your_mobile_phone: "Associe o seu telemóvel",
    Please_link_your_mail: "Vincule o seu e-mail",
    birth_tip: "Selecione a data de nascimento (depois de definida, não pode ser modificada)",
    pltwaf: "Vincule primeiro a conta de levantamento",
    ped: "Introduza 6-40 números",
    plw: "Entre no WhatsApp",
    pefa: "Por favor insira suaconta do facebook",
    pet: "Por favor, informe seu nome de usúario no Telegram",
    eut: "Introduza 5-40 ",
    Year: "Ano",
    Save: "Salvar",
    Save_Btn: "Clique para Salvar",
    Male: "Masculino",
    Female: "Masculino",
    Statement: "Detalhes da     Conta",
    Cumulative_bet_amount: " Número total de apostas efetuadas",
    Total_Valid_Bets: "Total de Apostas Válidas",
    Total: "Vitórias/Perdas Total",
    nob: "Apostas/",
    ba: "Valor/",
    Ganhos: "Ganhos",
    Verify_Login_Password: "Verifica a senha",
    you_tip: "O número de telemóvel pode ser vinculado após verificação da palavra-passe de início de sessão",
    Other_Verification_Methods: "Outros Métodos",
    The_above_method_is_not_available: "O método acima não está disponível? ",
    Contact_Us: "Contacte-nos",
    Enter_Password: "Inserir Senha",
    Mobile_Phone: "Nº de Telefone",
    phone: "Digite o Número do Celular",
    Player_ID: "Nome de Usuário",
    Unlinked: "Desvincular",
    Email: "Endereço de E-mail",
    Login_Password: "Palavra-passe de início de sessão",
    Withdrawal_Password: "Senha de Saque",
    Security_Question: "Pergunta",
    Link_Third_party_Login: "Login de terceira partia de ligação",
    Unset: "não configurado",
    set_title: "Você é a primeira retirada, você precisa definir a senha de retirada primeiro",
    set_up: "Definir Senha de Saque",
    New_Withdrawal: "Nova Senha de Saque",
    Old_Withdrawal: "Contrasinha antiga de retirada",
    password_note: `Você é a primeira retirada, você precisa definir a senha de retirada primeiro`,
    confirm: "confirmar",
    Confirm: "Confirmar",
    Current_Lucky_Value: "Pontos de sorte atuais",
    you_still_need: "Ainda precisa de apostar ",
    to_get: "para obter",
    Lucky_Points: " Pontos de sorte",
    Instant: "SORT<br>EIO",
    Luck_Value: "Pontos de sorte",
    Silver: "Silver",
    Golden: "Ouro",
    Diamond: "Diamond",
    Reward_announcement: "Notificação de Prêmio",
    My_Records: 'Meus Registros',
    won_at: "obtê -lo em ",
    returntable_rule_content: "Apostas acumuladas (ou seja, apostas válidas ou em curso)",
    Designated_platform: "Plataforma especificada",
    return_table_content_2: `1.Durante o período de atividade, cada 1 de apostas válidas pode ganhar automaticamente 1 Número da Sorte, 1000-10000 Números da Sorte podem sortear um prêmio uma vez, com a maior recompensa 7777<br>
  2.Se o valor da sorte obtido hoje não for usado, ele expirará e limpará o zero;<br>
  3.As recompensas só podem ser retiradas manualmente no iOS、Android、H5、PC;<br>
  4.A atribuição de bônus desta atividade (excluindo o capital) requer 1 X rollover (isto é, auditoria, participação ou aposta válida) para levantar o dinheiro. Participações não limitadas por jogo ou plataforma:<br>
  5.Este evento é limitado a operações normais realizadas pelo titular da conta. É proibido alugar, usar plug-ins externos, robôs, apostar em contas diferentes, brushing mútuo, arbitragem, interface, protocolo, exploração de vulnerabilidades, controle de grupo ou outros meios técnicos para participar. Caso contrário, as recompensas serão canceladas ou deduzidas, a conta será congelada ou até mesmo adicionada à lista negra;<br>
  6.Para evitar diferenças no entendimento do texto, a plataforma manterá a interpretação final deste evento.`,
    twde: `Apostas totais durante o período do evento`,
    Used_Lucky_Value: "Valor Lucky Usado",
    Expired_Lucky_Value: "Valor de sorte expirado",
    Available_Lucky_Value: "Valor de sorte disponível",
    Claim_Successfully: "Afirmar com sucesso",
    Total_Account_Balance: " Saldo total da conta",
    One_click_transfer: "Recup. c/um clique",
    Live: "Jogo Ao Vivo",
    Fishing: "Pescaria",
    Sports: "Esporte",
    Cards: "Cartas",
    Lottery: "Loteria",
    Blockchain: "Jogos em Blockchain",
    Slots: "Arcade",
    All_Type: "Tipos",
    ALL_Platform: "Plataformas",
    Platform: "Plataforma",
    Total_Commission: "Comissão total",
    Referral_information: "Informações da promoção",
    More: "Mais",
    Commission_Collected: "Comissão recebida",
    Last_Commission: "Última Comissão",
    Total_Members: "Total Members",
    Total_angent: "Número total de equipes",
    Direct_Subordinates: "Subordinados diretos",
    Others: "Outros",
    Total_Perf: "Rendimento Total",
    Sub_Perf: "Rendimento dos Indicados",
    Others_Perf: "Outros rendimentos",
    Subordinate_Wagers: "Apostas diretas",
    Total_valid_bets: "Total de apostas válidas",
    total_no: "Total de apostas válidas",
    Total_angent_wl: "Total de perdas e ganhos",
    Withdrawal_Account: "Conta de Retirada",
    Hide: "Ocultar",
    Show: "Mostrar",
    Add_New_Bank_Account: "Adicionar nova conta bancária",
    Enter_PIN: "Inserir Senha",
    for_you_account: 'Para a segurança da sua conta, introduza a palavra-passe de levantamento',
    pix_tip: "Conta PIX campo não pode estar vazio",
    digits_11: "Introduza 11 números",
    digits_6: "Introduza 6 números",
    Set_as_Default: "Definir como padrão",
    Add_withdrawal_account: "Adicionar Conta",
    add_account_tip: "Selecione primeiro a conta para levantamento",
    with_tip_1: "Também preciso apostar ",
    with_tip_2: " para retirar ",
    Normal_withdrawal: "Retirada Padrão",
    Min: 'Mínimo',
    Max: 'Máximo',
    Withdrawal_Amount_cannot_be_empty: 'Withdrawal Amount cannot be empty',
    withdraw_err: 'A auditoria não foi concluída, ainda não é possível efetuar o levantamento',
    wp_can_be_empty: "Senha de Saque campo não pode estar vazio",
    Can_be_collected_today: "Pode ser coletado hoje",
    Fee: "Custos",
    Add: "Adicionar",
    All_status: "Estados",
    Not_settled: 'Não Liquidado',
    Settled: 'Liquidado',
    Order_canceled: "Foram retirados",
    Confirm_New_Withdrawal_Password: "Confirma Senha Nova",
    Search: "Pesquisar",
    Search_Games: "Search Games",
    tab_Search: "Pesquisar",
    Favorite: "Favoritos",
    Reports_tab: "Relatório Pessoal",
    em_claim: "Não existem prémios a receber neste momento",
    Start_Date: "Data de início",
    End_Date: "Data de fim",
    Cancel: "Cancelar",
    My_ID: "Minha identidade",
    Contribution_Commission: "Contribuição da Comissão",
    total_first: "Contagem total de recargas",
    Other_first_deposit: "Outras recargas iniciais",
    Other_deposit: "Outro depósito",
    Direct_first_deposit: "Primeira recarga direta",
    Subordinate_Deposits: "Total de recargas diretas",
    Subordinate: "Subordinate",
    Full_Screen: "Ecrã inteiro",
    Refresh: "Atualizar",
    Yes: "Sim",
    No: "Não",
    Settlement_date: "Data de liquidação",
    Contributors: "Número de Colaboradores",
    Joining_time: "Hora de adesão",
    Member_ID: "ID de Membro",
    Statistical_date: "Datas estatísticas",
    Number_of_bets: "N.º de boletins de apostas",
    Total_W_L: "Vitórias/Perdas Total",
    bounusTip: "Os membros de nível inferior contribuirão para o reembolso se os seus depósitos forem ≥0 e tenham feito apostas válidas ≥0 durante o período de liquidação.",
    Type_Details: "Detalhes do tipo",
    Bet_ID: "Bet ID",
    Bet_amount: "Bet amount",
    In_game: "A utilizar atualmente no jogo",
    page: 'página',
    Page: 'Página',
    entries: 'peças',
    page_total_1: "",
    page_total_2: "peças no total",
    more_1: "A exibir",
    more_2: "jogos entre",
    more_3: "Popular jogos",
    Load_More: "Carregar mais",
    Reminder: "Dicas",
    Log_out_from_account: "Deseja terminar sessão da conta",
    Cashback_Exclusivo: "Cashback Exclusivo",
    privileges_Exclusivo: "VIP privileges",
    login_password_tip: "A senha nova pode ser alterada apos verificar a senha presente",
    Confirm_the_new_password: "Confirm the new password",
    Daily_loss_rescue_fund: "Daily loss rescue fund",
    Loss_amount: "Loss amount",
    Extra_Rewards: "Extra Rewards",
    Yesterday_s_losses: "Perdas de ontem",
    Today_s_Rescue_Bonus: "Bônus de suporte de hoje",
    Lucky_wager: "Aposta da sorte",
    No_winning_wager_today: "Nenhuma aposta ganha hoje",
    max_times_claimed: "Limite máx. {num} hoje ({num} vezes restantes)",
    Bet_number_last_digits: "Número final da aposta",
    Reward_multiple: "Múltipla de bônus",
    Reward_limit: "Limite de bônus",
    reward_amount: "Bônus = montante da aposta x múltipla de bónus, sem exceder o limite máximo",
    Music: 'Música',
    Cycle: 'Cycle',
    Shuffle: 'Aleatório',
    Repeat: 'Repetir',
    Downloaded: 'Baixado',
    System_Music: "Sistema de Música",
    My_music: "Minhas músicas",
    change_password_note: 'Atenção: A senha de saque protege seus fundos e é extremamente importante. Mantenha-a em segredo para evitar qualquer perda financeira.',
    Change_Withdrawal_Password: 'Alterar Senha de Saque',
    Link_Email: 'Vincular o e-mail',
    Enter_Email: "Por favor insira seu e-mail",
    Deslize_para: 'Deslize para concluir o puzzle',
    Numbers: 'Números',
    Lowercase: 'Letras minúsculas',
    Uppercase: 'Letras maiúsculas',
    My_superiors: 'Conta Superior',
    Agent_mode: 'Modo de Agente',
    Unlimited_level: 'Diferença de nível infinito (Liquidação diária)',
    Me_Header_user: "Dados",
    Me_Header_Support: "Suporte",
    Yesterday: "Ontem",
    This_Week: "Está Semana",
    Last_Week: "Última Semana",
    This_Month: "Este Mês",
    Last_Month: "Mês Passado",
    Add_direct: "Adicionar membros diretos",
    deposit_players: "Primeiros Depósitos",
    Number_deposits: "Depósitos",
    Deposit_amount: "Depósito",
    Direct_subordinates: "Subordinados Diretos",
};