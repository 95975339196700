<template>
  <i class="refresh-wrap" @click="refresh">
    <Icon class="icon-refresh" :class="{ rotate: refreshLoading }" type="refresh"></Icon>
  </i>
</template>

<script>
export default {
  data() {
    return {
      refreshLoading: false,
    }
  },
  props: {
    toDo: {
      type: Function
    }
  },
  methods: {
    refresh() {
      if (this.refreshLoading) {
        return
      }
      this.refreshLoading = true;
      this.toDo && this.toDo()
      setTimeout(() => {
        this.refreshLoading = false;
      }, 2000)
    },
  },
}
</script>

<style lang='scss' scoped>
.refresh-wrap {
  display: flex;
  align-self: center;

  .icon-refresh {
    &.rotate {
      animation: rotateAni .3s linear infinite
    }
  }
}
</style>