<template>
  <div class="nav-haeder">
    <div class="nav-icon-back lighten-color" @click="back">
      <Icon type="arrow-line-left" />
      <span class="pc-el back-text" v-if="showBack">{{ $t('Back') }}</span>
    </div>
    <div class="nav-text">{{ title }}</div>
    <div class="nav-right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    toHome: {
      type: Boolean,
      default() {
        return false
      }
    },
    showBack: {
      type: Boolean,
      default() {
        return true
      }
    },
    customerBack: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  methods: {
    back() {
      if (this.customerBack) {
        this.$emit('back')
      } else {
        if (this.toHome) {
          this.$router.push('/index')
          return
        }
        this.$router.back()
      }

    }
  },
}
</script>

<style lang='scss' scoped>
.page-body {
  height: 4rem;
  flex: 1;
  overflow-y: auto;
  font-size: 0.24rem;
}

.pc-page {
  .nav-haeder {
    height: 0.6rem;
    box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    border-bottom: 0;
    margin: 0 0 0.2rem 0;
    border-radius: 0.1rem;

    .nav-icon-back {
      font-size: .2rem;

      .back-text {
        user-select: none;
        cursor: pointer;
        margin-left: 0.1rem;
      }
    }

    .nav-text {
      font-size: .23rem;

      &::before {
        content: '';
        display: block;
        width: 0.7433rem;
        height: 0.0867rem;
        background: url(@/assets/images/img_title_decor@x3.png);
        background-size: 100% 100%;
        margin-right: 0.12rem;
      }

      &::after {
        content: '';
        display: block;
        width: 0.7433rem;
        height: 0.0867rem;
        margin-left: 0.12rem;
        background: url(@/assets/images/img_title_decor@x3.png);
        background-size: 100% 100%;
        transform: rotate(-180deg);
      }
    }
  }
}</style>