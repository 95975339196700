<template>
  <div class="of-main-wrap">
    <div class="of-left">
      <div class="btn" v-for="(item, index) in categoryList" :key="index" @click="current = index">
        <MenuBtn :icon="item.icon" :vertical="Layout.isPc ? false : true" :active="current == index"
          :text="item.text" />
      </div>
    </div>
    <div class="of-right" v-if="!Layout.isPc">
      <div class="of-right-item" v-for="(item, index) in gameList" :key="index">
        <div class="rl-l">
          <img class="plateform-icon"
            src="https://cdntoos.k55win.com/cocos/icon/0/14_N_PG.png?t=1685636550&swRuntimeCacheName=web-lobby-runtime"
            alt="">
          <span class="plateform-text">PG</span>
        </div>
        <div class="rl-c">
          <div class="rl-c-row">
            <div class="row-item">
              <label>{{ $t('Valid_Bets') }}</label>
              <span> 0,00</span>
            </div>
            <div class="row-item">
              <label>{{ $t('Rebate_rate') }}</label>
              <span> 0%</span>
            </div>
          </div>
          <div class="rl-c-row">
            <div class="row-item">
              <label>{{ $t('Rebateable') }}</label>
              <span class="warn"> 0,00</span>
            </div>
            <div class="row-item">
              <label>Lower level rate</label>
              <span> 0%</span>
            </div>
          </div>
        </div>
        <div class="rl-r">
          <Icon type="arrow-right-line" />
        </div>
      </div>
    </div>
    <div class="of-right-pc card" v-else>
      <div class="row row-sb r-header">
        <div><span>{{ $t('Can_be_collected_today') }}</span><span class="warn-color">0,00</span></div>
        <div class="row column-c">
          <PgButton class="min-btn" type="primary" size="mini" :disabled="true">
            {{ $t('Claim_All') }}
          </PgButton>
          <PgButton class="min-btn" type="primary" size="mini">
            {{ $t('History') }}
          </PgButton>
        </div>
      </div>
      <div class="table-wrap">
        <pg-table :table-data="tableData" :config="tableConfig">
          <template #val="">
            <div class="row row-sb">
              <span>{{ $t('Valid_Bets') }}</span>
              <span>{{ $t('Rebate_rate') }}</span>
            </div>
          </template>
        </pg-table>
      </div>
      <div class="pagination">
        <pg-pagination :page-size="pager.limit" :current-page="pager.page" :page-sizes="[10, 20, 30, 40, 50]" background
          small layout="prev, pager, next,total, sizes, jumper" :total="total" @current-change="handlerPageChange"
          @size-change="handleSizeChange">
        </pg-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import MenuBtn from '@/components/MenuBtn';
export default {
  components: { MenuBtn },
  inject: ['Layout'],
  data() {
    return {
      current: 0,
      categoryList: [
        { icon: 'sevent', text: 'Slot' },
        { icon: 'block', text: 'Blockchain Games' },
        { icon: 'fish', text: 'Fishing' },
        { icon: 'live', text: 'Cards' },
        { icon: 'sport', text: 'Sports' },
      ],
      gameList: [1, 2, 3],
      tableData: [],
      tableConfig: [
        { label: this.$t('Platform'), prop: 'created_at', align: 'center', },
        { align: 'center', headeSlot: "val", width: '4rem' },
        { label: this.$t('Receive_amount'), prop: 'Reward', align: 'center', },
        { label: this.$t('Action'), prop: 'Source', align: 'center', },
      ],
      pager: {
        page: 1,
        limit: 10
      },
      total: 0,
    }
  },
  methods: {

    handlerPageChange(page) {
      this.pager.page = page;
    },
    handleSizeChange(val) {
      this.pager.limit = val
      this.pager.page = 1;
    },
  },
}
</script>

<style lang='scss' scoped>
.of-main-wrap {
  display: flex;

  .of-left {
    padding-top: 0.2rem;
    max-height: calc(100% - 3.94rem);
    overflow-y: auto;
    width: 1.9rem;
    font-size: 0;
    display: flex;
    flex-direction: column;

    ::v-deep .pg-button {
      width: 1.2rem;
      font-size: 10px;
      line-height: 0.5rem;
      padding: 0 .05rem;
      margin: 0.2rem auto 0;
    }

    .btn {
      margin-bottom: 0.2rem;
    }
  }

  .of-right {
    flex: 1;
    width: 1rem;
    padding-bottom: 1.24rem;
    overflow-y: scroll;


    .of-right-item {
      background-color: var(--theme-main-bg-color);
      border-radius: 0.1rem;
      padding: .2rem 0 .2rem .1rem;
      position: relative;
      margin: 0.1rem;
      display: flex;

      .rl-l {
        width: 0.7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .plateform-icon {
          width: 0.5rem;
          height: 0.5rem;
          object-fit: contain;
        }

        .plateform-text {
          margin-top: 0.1rem;
          font-size: 0.2rem;
          color: var(--theme-left-nav-text-color);
        }
      }

      .rl-c {
        width: 1rem;
        font-size: .2rem;
        flex: 1;

        .rl-c-row {
          display: flex;

          .row-item {
            color: var(--theme-text-color-lighten);
            width: 50%;
            margin-bottom: .14rem;

            span {
              color: #fff;

              &.warn {
                color: var(--theme-secondary-color-finance)
              }
            }
          }
        }

      }

      .rl-r {
        width: 0.44rem;
        font-size: .25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--theme-text-color-lighten);
      }
    }
  }

  .of-right-pc {
    border-radius: .1rem;
    flex: 1;
    width: 1rem;
    padding: 0.2rem;
    padding-bottom: 0;

    .pagination {
      transform: translateY(120%);
    }

    .r-header {
      font-size: .18rem;
      padding: 0.1rem 0;

      .warn-color {
        margin-left: 0.1rem;
      }

      .min-btn {
        height: .4rem;
        margin-left: 0.2rem;
        font-size: .14rem;
        width: 1rem;
        border-radius: .1rem;
      }
    }
  }
}

.pc-page {
  .of-main-wrap {
    .of-left {
      padding-top: 0;
    }

  }
}
</style>