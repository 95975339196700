<template>
  <pg-poup v-model="visible" :title="$t('Mission')">
    <div class="mission-container">

      <div class="m-content">
        <div class="btn-list">
          <div class="m-btn" :class="{ active: current === 0 }" @click="current = 0">
            <span>{{ $t('Newbie_Bonus') }}</span>
          </div>
          <div class="m-btn" :class="{ active: current === 1 }" @click="current = 1">
            <span>{{ $t('Daily_Mission') }}</span>
          </div>
          <PgButton class="min-btn" type="primary" size="mini" :disabled="true">{{ $t('Claim_All') }}</PgButton>
          <PgButton class="min-btn" type="primary" size="mini">{{ $t('History') }}</PgButton>
        </div>
        <div class="mis-content">
          <scrollbar>
            <div class="scroll-content">
              <keep-alive>
                <component :is="currentCompoent" ref="component" @close="close"></component>
              </keep-alive>

            </div>
          </scrollbar>
        </div>
      </div>
    </div>
  </pg-poup>
</template>

<script>
import Bonus from './MissionComponents/Bonus.vue'
import DailyMission from './MissionComponents/DailyMission.vue'
export default {
  name: 'Mission',
  data() {
    return {
      current: 0,
      visible: false,
    }
  },
  components: { Bonus, DailyMission },
  watch: {
    visible(val) {
      if (!val) {
        this.$emit('close')
      } else {
        this.$refs.component.getData()
      }
    }
  },
  computed: {
    currentCompoent() {
      let component = 'Bonus'
      if (this.current == 1) {
        component = 'DailyMission'
      }
      return component
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    close(){
      this.visible = false;
    }
  }
}
</script>

<style lang='scss' scoped>
.mission-container {
  width: 7.1rem;
  height: 10rem;
  display: flex;
  box-sizing: border-box;
  padding: 0 .02rem .13rem .19rem;
  flex-direction: column;

  .title {
    font-size: .3rem;
    color: var(--theme-text-color-darken);
    font-weight: 700;
    text-align: center;
  }

  .m-content {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;

    .btn-list {
      padding-right: 0.2rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      .m-btn {
        user-select: none;
        cursor: pointer;
        border-radius: 0.1rem;
        min-height: 0.7rem;
        color: var(--theme-left-nav-text-color);
        width: 1.5rem;
        display: inline-flex;
        align-items: center;
        background: var(--theme-main-bg-color);
        border: thin solid var(--theme-color-line);
        padding: 0 0.06rem;
        box-sizing: border-box;
        margin-bottom: 0.2rem;
        box-shadow: 0 0.03rem 0.09rem 0 var(--theme-aside-no-active-box-shadow);

        span {
          font-size: 0.24rem;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }

        &.active {
          color: var(--theme-primary-font-color);
          background: var(--theme-primary-color);
        }

      }

      .min-btn {
        margin-bottom: 0.2rem;
      }
    }

    .mis-content {
      flex: 1;
      overflow: hidden;

      .scroll-content {
        padding-right: 0.18rem;
      }



    }
  }
}


.pc-page {

  .mission-container {
    padding: .2rem .12rem .3rem .3rem;
    width: 10rem;
    height: 8.5rem;
    flex: 1;
    max-height: 70vh;
    box-sizing: border-box;

    .m-content {
      .mis-content .scroll-content {
        padding-right: 0.2rem;
      }
    }
  }
}
</style>