<template>

  <div id='offer-history'>
    <pg-nav :title="$t('History')"></pg-nav>
    <div class="filter">
      <div class="row column-c">
        <pg-select v-model="form.date" :options="dateOptions" />
        <pg-select v-model="form.type" class="ml02" :options="typeOptions" />
      </div>
      <div class="row column-c">
        <span class="lighten-color">{{ $t('Bonus') }}</span>
        <span class="warn-color ml01">0,00</span>
      </div>
    </div>
    <div class="his-list" v-if="!Layout.isPc">
      <empty />
    </div>

    <div class="his-list" v-else>
      <div class="table-wrap">
        <pg-table :table-data="tableData" :config="tableConfig"></pg-table>
      </div>
      <div class="pagination">
        <pg-pagination :page-size="pager.limit" :current-page="pager.page" :page-sizes="[10, 20, 30, 40, 50]" background
          small layout="prev, pager, next,total, sizes, jumper" :total="total" @current-change="handlerPageChange"
          @size-change="handleSizeChange">
        </pg-pagination>
      </div>
    </div>
    <div>


    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        date: 0,
        type: 0
      },
      tableData: [],
      tableConfig: [
        { label: this.$t('Time'), prop: 'created_at', align: 'center' },
        { label: this.$t('Name'), prop: 'name', align: 'center' },
        { label: this.$t('Reward'), prop: 'Reward', align: 'center' },
        { label: this.$t('Source'), prop: 'Source', align: 'center' },
        { label: this.$t('Status'), prop: 'Status', align: 'center' },
      ],
      dateOptions: [
        { label: this.$t('Today'), value: 0 },
        { label: this.$t('Yesterdaty'), value: -1 },
        { label: `7 ${this.$t('Days')}`, value: -7 },
        { label: `15 ${this.$t('Days')}`, value: -15 },
        { label: `30 ${this.$t('Days')}`, value: -30 }
      ],
      typeOptions: [
        { label: this.$t('All'), value: 0 },
        { label: this.$t('Collected'), value: 1 },
        { label: this.$t('Distributed'), value: 2 },
      ],
      pager: {
        page: 1,
        limit: 10
      },
      total: 0,
    }
  },
  inject: ['Layout'],
  methods: {
    handlerPageChange(page) {
      this.pager.page = page;
    },
    handleSizeChange(val) {
      this.pager.limit = val
      this.pager.page = 1;
    },
  },
}
</script>

<style lang='scss' scoped>
#offer-history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .nav-haeder {
    display: none;
  }

  .table-wrap {
    padding: 0 0.2rem;

    ::v-deep .table-thead {
      background: var(--theme-bg-color);
    }

  }

  .filter {
    font-size: .24rem;
    height: 0.9rem;
    padding: 0 0.2rem;
    display: flex;
    background: var(--theme-main-bg-color);
    justify-content: space-between;
  }

  .pagination {
    transform: translateY(120%);
  }

  .his-list {
    flex: 1;
    background: var(--theme-main-bg-color);
  }
}

@media screen and (min-width:751px) {
  #offer-history {
    .nav-haeder {
      display: flex;
    }

    .filter{
      border-top-right-radius: 0.1rem;
      border-top-left-radius: 0.1rem;
    }

  .his-list {
      border-bottom-right-radius: 0.1rem;
      border-bottom-left-radius: 0.1rem;
    }
  }
}
</style>