export default {
  bind(el, binding) {
    if (binding.value) {
      el.style.display = "block";
      el.style.width = "100%";
    }
  },
  inserted(el, bind) {
    const NUM = el.innerText.length;
    const W = el.offsetWidth;
    const H = el.offsetHeight;
    const minFontSize = 10;
    const maxFontSize = 84;
    let fontSizeMin =
      (Math.sqrt((W + H) * (W + H) + 4 * W * H * NUM - 4 * W * H) - W - H) /
      (2 * NUM - 2);

    let signState = true;
    let fontSize = fontSizeMin;
    while (signState) {
      if (Math.ceil(NUM / Math.floor(W / fontSize)) * fontSize > H) {
        signState = false;
        break;
      } else {
        signState = true;
      }
      fontSize = fontSize + 1;
    }
    fontSize = fontSize - 1;

    if (fontSize >= maxFontSize) {
      fontSize = maxFontSize;
    }
    if (fontSize <= minFontSize) {
      fontSize = minFontSize;
    }
    el.style.fontSize = `${fontSize}px`;
  },
};
