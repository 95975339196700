import { render, staticRenderFns } from "./PgInput.vue?vue&type=template&id=56a6822c&scoped=true"
import script from "./PgInput.vue?vue&type=script&lang=js"
export * from "./PgInput.vue?vue&type=script&lang=js"
import style0 from "./PgInput.vue?vue&type=style&index=0&id=56a6822c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a6822c",
  null
  
)

export default component.exports