<template>
  <div id='retirar'>
    <pg-poup v-model="visible">
      <div class="retirar-pop">
        <div class="title">{{ response.title }}
          <span class="close-line" @click="handlerClose">
            <Icon type="close-line" />
          </span>
        </div>
        <div class="content" v-html="response.content"></div>
        <pg-button class="btn" type="primary" v-for="(item, index) in response.button_list" :key="index" @click="btnClick(item)">{{ item.text}}</pg-button>
      </div>
    </pg-poup>
  </div>
</template>

<script>
export default {
  name: 'retirarPop',
  data() {
    return {
      visible: false,
      response: {},
      router:null
    }
  },
  methods: {
    handlerClose() {
      this.visible = false
    },
    btnClick(item){
      if(item.url){
        this.router.push(item.url)
      }
      this.handlerClose()
    }
  },
}
</script>

<style lang='scss' scoped>
.retirar-pop {
  padding: 0.3rem 0.2rem;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 6rem;
  margin: 8px auto;
  max-width: calc(100% - 16px);

  .title {
    font-size: .3rem;
    font-weight: bold;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: var(--theme-text-color-darken);

    .close-line {
      cursor: pointer;
      position: absolute;
      right: .1rem;
      top: 8%;
      right: 2%;
      font-size: .2rem;
      display: none;
      color: var(--theme-text-color);
      height: 0.56rem;
      line-height: .56rem;
      width: 0.56rem;
    }

  }

  .content {
    padding: 0.2rem 0;
    font-size: 0.24rem;
    color: var(--theme-text-color-darken);
  }

  .btn {
    margin-top: 0.2rem;
  }
}

.pc-page {
  .close-line {
    display: block !important;
  }
}
</style>