<template>
  <div class='' v-clickoutside="close">
    <div ref="dropParent" @click="visible = !visible">
      <slot></slot>
    </div>

    <pop :placement="placement">
      <slot name="content"></slot>
    </pop>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter';
import pop from './pop.vue';
export default {
  components: { pop },
  mixins: [Emitter],
  props: {
    placement: {
      type: String,
      default: 'bottom-start',
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  watch: {
    visible(val) {
      this.broadcast('dropItem', 'visible', [
        val,
      ]);
      this.$emit('isShow',val)
    }
  },
  methods: {
    close() {
      this.visible = false
    }
  },
}
</script>

<style lang='scss' scoped></style>