<template>
  <div class="home-footer">
    <div class="footer-main">
      <div class="footer-column">
        <ul class="footer-text-part row">
          <li class="text-item">
            <h3>{{ $t('Casino') }}</h3>
            <div class="link-item" @click="toPage('/offers?current=5')">{{ $t('Reward') }}</div>
            <div class="link-item" @click="toPage('/offers?current=2')">Rebate</div>
            <div class="link-item" @click="toPage('/agent')">VIP</div>
            <div class="link-item" @click="toPage('/agent')">{{ $t('Agent') }}</div>
            <div class="link-item" @click="toPage('/offers')">{{ $t('Event') }}</div>
            <div class="link-item" @click="Layout.$refs.mission.show()">{{ $t('Mission') }}</div>
          </li>
          <li class="text-item">
            <h3>{{ $t('Games') }}</h3>
            <div class="link-item" v-for="(item, index) in indexGameList" :key="index" @click="toIndex(index)">{{
              item.title }}</div>
          </li>
          <li class="text-item">
            <h3>{{ $t('Support') }}</h3>
            <div class="link-item" @click="toPage('/customer')">{{ $t('Online_Service') }}</div>
            <div class="link-item" @click="toPage('/customer')">{{ $t('Help_Center') }}</div>
          </li>
        </ul>

        <div class="plat-list">
          <img src="@/assets/images/img_facebook.png" alt="" @click="open('https://fb.me/')">
          <img src="@/assets/images/img_tg.png" alt="" @click="open('https://t.me/telegram')">
          <img src="@/assets/images/img_wa.png" alt="" @click="open('https://wa.me/')">
          <img src="@/assets/images/ins.png" alt="" @click="open('https://www.instagram.com/')">
          <img src="@/assets/images/youtube.png" alt="" @click="open('https://www.youtube.com/')">
          <img src="@/assets/images/tiktok.png" alt="" @click="open('https://www.tiktok.com/')">
          <img src="@/assets/images/kwai.png" alt="" @click="open('https://kwai-short-video-community.en.softonic.com/')">
          <img src="@/assets/images/18plus.png" alt="">
        </div>
      </div>
      <div class="pg-list" v-if="Layout.isPc">
        <img src="@/assets/images/gli.png" alt="">
        <img src="@/assets/images/mga.png" alt="">
        <img src="@/assets/images/pag.png" alt="">
        <img src="@/assets/images/gam.png" alt="">
        <img src="@/assets/images/pg.png" alt="">
        <img src="@/assets/images/pp.png" alt="">
        <img src="@/assets/images/img_footer_jdb.png" alt="">
        <img src="@/assets/images/jili.png" alt="">
        <img src="@/assets/images/tiger.png" alt="">
      </div>
      <div class="wap-btm" v-else>
          <div class="game-list">
            <img src="@/assets/images/pg.png" alt="">
            <img src="@/assets/images/pp.png" alt="">
            <img src="@/assets/images/img_footer_jdb.png" alt="">
            <img src="@/assets/images/jili.png" alt="">
            <img src="@/assets/images/tiger.png" alt="">
          </div>
          <div class="game-list">
            <img src="@/assets/images/gli.png" alt="">
            <img src="@/assets/images/mga.png" alt="">
            <img src="@/assets/images/pag.png" alt="">
            <img src="@/assets/images/gam.png" alt="">
          </div>
      </div>
      <div class="copyright">
        <ul>
          <li>
            <p>
              Copyright@2002-2024
            </p>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {

    }
  },
  inject: ['Layout'],
  computed: {
    ...mapState({
      gameList: state => state.home.gameList,
    }),
    indexGameList() {
      return this.gameList.filter(item => (item.tag_code !== "clube" && item.tag_code !== "amigos" && item.tag_code !== "jogos"))
    },
  },
  methods: {
    open(url) {
      window.open(url)
    },
    toPage(path) {
      this.$router.push(path);
    },
    toIndex(index) {
      if (this.$route.path !== '/index') {
        this.$router.push('/index')
      }

      this.$store.commit('SET_CURRENT_TAB_ISSCROLL', false)
      this.$store.commit('SET_CURRENT_TAB', index)
    }
  },
}
</script>

<style lang='scss' scoped>
.home-footer {
  margin-top: 0.2rem;
  padding: 0 0.2rem 1.8rem;
  background: var(--theme-side-footer-bg-color);

  .footer-main {
    margin: 0 auto;
    position: relative;
    width: 12rem;

    .wap-btm{
        border-top: 0.01rem solid var(--theme-color-line);
        display: ruby;
        align-items: center;
        justify-content: center;
        padding: 0.4rem 0px;
        .game-list{
          -ms-flex-pack: center;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 0.4rem;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-wrap: nowrap;
          display: flex;

          img{
            width: 0.96rem;
            margin-right: 0.2rem;
          }
        }
      }

    .footer-column {
      -ms-flex-align: start;
      -ms-flex-pack: center;
      align-items: flex-start;
      border-bottom: 0.01rem solid var(--theme-color-line);
      justify-content: center;
      padding: 0.67rem 0 0.4rem;
      position: relative;
      display: flex;

      .footer-text-part {
        border: none;
        margin: 0 0.4rem 0 0;
        padding-bottom: 0;
        width: 6.6rem;
        //display: flex;
        //padding: 0.67rem 0;

        .text-item {
          //margin-right: 0.2rem;
          width: 2.07rem;
          width: 33.33%;

          h3 {
            color: var(--theme-text-color-darken);
            font-size: .24rem;
            margin-top: 0;
            margin-bottom: 0.2rem;
          }

          .link-item {
            color: var(--theme-text-color-lighten);
            font-size: .24rem;
            line-height: .34rem;
            margin-bottom: 0.1rem;
            overflow: hidden;
            padding-right: 0.2rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            user-select: none;
            cursor: pointer;

            &:hover {
              color: var(--theme-ant-primary-color-0);
            }
          }
        }
      }

      

      .plat-list {
        width: 5rem;
        text-align: center;

        img {
          width: 0.5rem;
          margin-right: 0.2rem;
          height: 0.5rem;

          &:last-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }




  .pg-list {

    border-top: 0.01rem solid var(--theme-color-line);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0;

    img {
      width: 0.96rem;
      margin-right: 0.2rem;

      &:last-child {
        margin: 0;
      }
    }
  }


  .copyright {
    display: flex;
    justify-content: center;
    margin-top: 0.4rem;
    width: 100%;

    ul {
      display: flex;
      justify-content: center;
      margin: 0 !important;
      width: 100%;

      li {
        color: var(--theme-text-color-lighten);
        font-size: .16rem;
        line-height: 1.2;
        overflow: hidden;
        padding: 0 0.32rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        p {
          font-size: .175rem;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .home-footer {
    margin-top: 0.2rem;
    padding: 0 0.2rem 1.8rem;
    margin: 0 auto;
    position: relative;
    background: var(--theme-side-footer-bg-color);

    .footer-main {
      flex-direction: column;
      align-items: flex-start;

      justify-content: center;
      padding: 0.67rem 0 0.4rem;
      position: relative;
      display: flex;
      width: auto;

      .footer-column {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: center;
        margin-top: 0.4rem;
        width: 100%;

        .footer-text-part {
          border-bottom: 0.01rem solid var(--theme-color-line);
          margin: 0;
          padding-bottom: 0.48rem;
          width: 7.1rem;
          display: flex;
          justify-content: space-between;

          .text-item {
            width: 2.23rem;
            margin-right: 0.2rem;
          }
        }

        .plat-list {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          display: block;
          -ms-flex-direction: column;
          flex-direction: column;
          justify-content: center;
          margin-top: 0.4rem;
          width: 100%;
          text-align: center;
          img {
          width: 0.7rem;
          margin-right: 0.2rem;
          height: 0.7rem;

          &:last-child {
            margin: 0;
          }
        }
        }
      }
    }
  }
}
</style>