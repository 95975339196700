import { addClass } from "@/util";
import loading from "./loading";
import Vue from "vue";
const LoadingConstructor = Vue.extend(loading);
export default {
  bind(el, binding) {
    const instance = new LoadingConstructor({
      el: document.createElement("div"),
    });
    el.instance = instance;
    Vue.nextTick(() => {
      if (window.getComputedStyle(el).position == '' || window.getComputedStyle(el).position == 'static') {
        addClass(el, "pg-loading-parent");
      }
      el.appendChild(el.instance.$el);
    });
    if (binding.value) {
      el.instance.visible = true;
    }
  },
  update(el, binding) {
    if (binding.value != binding.oldValue) {
      el.instance.visible = binding.value;
    }
  },
};
