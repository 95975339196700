import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en'
import porLocale from './por'
import esLocale from './es'
import blLocale from './bl'
import tlLocale from './tl'
Vue.use(VueI18n)


const messages = {
  en: {
    ...enLocale,
  },
  por: {
    ...porLocale,
  },
  es: {
    ...esLocale,
  },
  bl: {
    ...blLocale,
  },
  tl: {
    ...tlLocale,
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem("language") ||'por',
  messages,
  silentFallbackWarn: true,
})

export default i18n