export default {
    Home: "Anasayfa",
    offers: "Teklifler",
    Profile: "Profil",
    Profile_me_info: "Profil",
    Withdrawal: "Çekim",
    Customer_service: "Müşteri Hizmetleri",
    login: "Giriş",
    register: "Kayıt",
    deposit: "Yatırım",
    withdraw: "Çekim",
    Withdraw_Records: "Çekim Kayıtları",
    Audit_Report: "Denetim Raporu",
    Manage_Account: "Hesap Yönetimi",
    Balance: "Bakiye",
    Withdrawal_Password: "Çekim Şifresi",
    Not_Started: "Başlamadı",
    Completed: "Tamamlandı",
    Ongoing: "Devam Ediyor",
    interest: "Faiz",
    dont_show: "Bugün tekrar gösterme",
    Casino: "Kasino",
    Reward: "Ödül",
    Agent: "Aracı",
    Event: "Etkinlik",
    APP_download: "Uygulama İndir",
    Mission: "Görev",
    Games: "Oyunlar",
    Support: "Destek",
    Online_Service: "Çevrimiçi Hizmet",
    Help_Center: "Yardım Merkezi",
    Message_Center: "Mesaj Merkezi",
    forgot_password: "Şifremi Unuttum",
    Remmeber_Me: "Beni Hatırla",
    Register_An_Account: "Bir Hesap Oluştur",
    Username: "Kullanıcı Adı",
    Name: "Ad",
    user_tip: "4-16 karakter, İngilizce/numaralar desteklenir",
    password: "Şifre",
    password_tip: "6-16 karakter",
    password_agin_tip: "Şifreler uyuşmuyor. Lütfen yeniden girin.",
    Re_enter_password: "Şifreyi Tekrar Girin",
    over_tip: "18 yaşından büyük olduğumu, okuduğumu ve kabul ettiğimi onaylıyorum",
    User_Agreement: "Kullanıcı Sözleşmesi",
    I_have_read: "Okudum",
    user_agreement_1: `1. Bahis anlaşmazlıklarını önlemek için, üyelerin Uygulamaya girmeden önce şirket kurallarını okuması gerekmektedir. Oyuncunun "Onaylıyorum" tuşuna basarak şirkete bahis yapmayı kabul ettiği an, şirketin Kullanıcı Sözleşmesine kabul etmiş sayılacaktır. `,
    user_agreement_2: `2. Üyenin hesabının ve giriş bilgilerinin gizliliğini sağlamak, üyenin sorumluluğundadır. Hesap numaranızla ve üye şifrenizle yapılan herhangi bir çevrimiçi bahis geçerli sayılacaktır. Lütfen şifrenizi zaman zaman değiştirin. Şirket, hesabınızda yapılan ve çalınan şifre ile yapılan bahisler için herhangi bir tazminat sorumluluğu taşımaz.`,
    user_agreement_3: `Şirket, bu sözleşmeyi veya oyun kurallarını veya gizlilik kurallarını zaman zaman değiştirebilir. Değişikliklerin gerçekleştiği tarihten itibaren yeni şartlar yürürlüğe girecektir ve tüm anlaşmazlıklarda şirketin son kararı alması hakkı saklıdır.`,
    user_agreement_4: `4. Kullanıcıların, ikamet ettikleri ülkenin yasalarına göre, kasinoda veya çevrimiçi uygulamada kullanmak için yeterli yaşa sahip olmaları gerekmektedir. Başarıyla gönderilmeyen çevrimiçi bahisler geçersiz sayılacaktır.`,
    user_agreement_5: `5. Oyuncu, oyunun sonucunun açıklanmadan önce otomatik olarak veya zorla oyun dışı bırakılırsa, bu oyunun sonucunu etkilemeyecektir.`,
    Messages: "Mesajlar",
    Account: "Hesap",
    Main_Wallet: "Ana Cüzdan",
    To_achieve_next_level: "Sonraki Düzeye Ulaşmak İçin",
    Betting_still_lacks: "Bahis Hala Eksik",
    Upgrade_Needs_Wager: "Yükseltme İçin Bahis Gereklidir",
    Account_Details: "Hesap Detayları",
    Bet_Records: "Bahis Kayıtları",
    Report: "Rapor",
    Reports: "Raporlar",
    Withdrawal_Management: "Çekim Yönetimi",
    Withdrawal_Method: "Çekim Yöntemi",
    million_monthly: "aylık milyon",
    Security_Center: "Güvenlik Merkezi",
    Language: "Dil",
    Feedback_to_get_rewards: "Ödüller Almak İçin Geri Bildirim Verin",
    Quit: "Çıkış",
    Login_Now: "Şimdi Giriş Yap",
    First_deposit_upon_registration: "Kayıt Sırasında İlk Yatırım",
    Bonus: "Bonus",
    Collected: "Toplandı",
    Task_time: "Görev Zamanı",
    bonus_1: "Uzun Süreli Görev (her yeni hesap sadece bir kez katılabilir)",
    bonus_2: "II.Görev Koşulları:",
    bonus_2_content: "Hesapla ilgili ayarları ve güvenlik bağlantılarını tamamlama",
    bonus_3: "III.Görev İçeriği:",
    bonus_3_content: `1.Her yeni kaydedilen hesap yukarıdaki görevleri tamamlayabilir ve tamamlandığında belirli bir miktarda bonus alabilir. Zorluk seviyesi ne kadar yüksekse ödül de o kadar büyüktür.<br>2.Her hesap tamamen anonimdir, çalındığında para kaybına neden olabilir ve geri kazanılamaz. Bu nedenle, çekme adresleri eklenirken iki adımlı doğrulamayı bağlama zorunluluğunda bulunuruz, böylece kişisel işlem olduğunu kanıtlanır ve güvenliğinizi sağlarsınız.<br>3.Koşullar sağlanan doğrudan talep edilebilir ve herhangi bir platformda doğrudan talep edilebilir; talep edilmezse (talep etmemek gönüllü feragat sayılır) süresi dolacaklardır.<br>4.Bu görevin nispeten yüksek bonusu nedeniyle, bonun çekmek için 2 kez denetimi gerektirir (yani denetim, bahis veya geçerli bahisler) ve oyun platformlarına kısıtlama yoktur.<br>5.Bu görev sadece hesap sahibinin normal manuel işlemlerine ayrılır; kiralama, hile yazılımları kullanımı, botlar, farklı hesaplar arasında bahis, karşılıklı fırça, arbitraj, API'ler, protokoller, güvenlik açıklarını sömürme, grup kontrolleri veya diğer teknik yöntemler yasaktır. İhlaller, ödüllerin iptali veya düşürülmesi, hesap dondurulması veya hatta kara listeye alınması neden olabilir.<br>6.Metin anlaşılmazlıklarını önlemek için, platform bu etkinliğin son yorumunu saklı tutacaktır.`,
    Accumulated_bets_made: "Yapılan Toplam Bahis",
    Go: "Git",
    Deposit_Records: "Yatırım Kayıtları",
    Online_Deposit: "Çevrimiçi Yatırım",
    Deposit_Amount: "Yatırım Miktarı",
    Bonus_event_explanation: "Bonus Etkinliği Açıklaması",
    Deposit_Now: "Şimdi Yatır",
    The_first_deposit_to_receive: "Almak İçin İlk Yatırım",
    Total_Deposit: "Toplam Yatırım",
    Deposit_Details: "Yatırım Detayları",
    Today: "Bugün",
    Yesterdaty: "Dün",
    Days: "Günler",
    Day: "Gün",
    DAY: "GÜN",
    All: "Tümü",
    Total_Withdrawal: "Toplam Çekim",
    Total_Withdraw: "Toplam Çekim",
    Accumulated_Claimed_Benefits: "Toplam Talep Edilen Avantajlar",
    Transaction_Type: "İşlem Türü",
    Action: "Eylem",
    Deposit_Method: "Yatırım Yöntemi",
    Deposit_channels: "Yatırım Kanalları",
    Time_Created: "Oluşturulma Zamanı",
    Order_No: "Sipariş No",
    Newbie_Bonus: "Yeni Kullanıcı Bonusu",
    Daily_Mission: "Günlük Görev",
    Claim_All: "Tümünü Talep Et",
    History: "Geçmiş",
    Rebate: "İndirim",
    Claim: "Talep Et",
    Claim_1: "Talep Et",
    Claimed: "Talep Edildi",
    Next: "Sonraki",
    No_Records: "Kayıt Yok",
    Level: "Düzey",
    Valid_Bets: "Geçerli Bahisler",
    First_Deposit: "İlk Yatırım",
    Online_login_and_counts: 'Çevrimdışı',
    Online_login_and_counts_pc: "Çevrimiçi Giriş ve Sayılar",
    Commission: "Komisyon",
    Agent_Network: "Aracı Ağı",
    Referral_Link: "Referans Bağlantısı",
    Performance: "Performans",
    Subordinate_s_Wagers: "Alt Üyelerin Bahisleri",
    All_data: "Tüm Veriler",
    Subordinate_stats: "Alt Üyelerin İstatistikleri",
    Agent_Bonus_Rate: "Aracı Bonus Oranı",
    Collectable: "Toplanabilir",
    Agent_Tier: "Aracı Seviyesi",
    Promotion_conditions: "Terfi Koşulları (Kümülatif Performans)",
    Total_Pending_Audit: "Toplam Bekleyen Denetim",
    Audit_Status: "Denetim Durumu",
    Funds_Change: "Para Değişikliği",
    Number_of_audits: "Denetim Sayısı",
    Total_Audit_Required: "Toplam Gereken Denetim",
    Pending_audit: "Bekleyen Denetim",
    Audited: "Denetlendi",
    Limited_to_the_platform: "Platforma Sınırlı",
    Game_Limited: "Oyuna Sınırlı",
    Total_Bet_Lucky_Spin: "Toplam Bahis Şans Çarkı",
    My_Link: "Benim Bağlantım",
    Quick_Share: "Hızlı Paylaş",
    valid_s: "Geçerli alt üyeler",
    people: " kişi",
    Details: "Detaylar",
    box_tip: "Geçerli referans sayısı nedir? (Aşağıdaki koşulları aynı anda karşılayan)",
    the_s: "Alt üyelerin toplam yatırımı",
    the_turnover: "Alt üyelerin toplam turloveri",
    Or_the_above: "Ya da yukarıdakiler",
    Create: "Oluştur",
    Mine: "Benim",
    Feedback_Content: "Geri Bildirim İçeriği",
    Suggestions_for_revision: "Düzenleme Önerileri",
    Attachment: "Ek",
    Easier_to_be_adopted: "Daha Kolay Kabul Edilebilir",
    Reupload: "Yeniden Yükle",
    upload_tip: "Resim ve video formatını destekler, boyut 50MB'dan fazla olmamalıdır",
    Reward_Rules: "Ödül Kuralları",
    Reward_Rules_content: "Daha iyi deneyim için sistem ve özelliklerimizi optimize etmek amacıyla geri bildirim toplamak için büyük bir bonus ayarladık! Kabul edildikten sonra, öneme göre (kabul edilmeyenler hariç) ödüller verilecektir.",
    Submit_feedback: "Geri Bildirim Gönder",
    No_Messages: "Mesaj Yok",
    online_tip: "Profesyonel tam zamanlı müşteri hizmetleri her zaman çevrimiçi olup her türlü sorunuzla yardımcı olmaktadır.",
    plat_sup: "Destek",
    News: "Haberler",
    Notification: "Bildirim",
    Marquee: "Açılış",
    daily_i_1: "Sürekli olarak oturum açıldı",
    daily_i_2: "gün",
    Required_deposit: " Gerekli yatırım ",
    Required_bets: "Gerekli bahisler ",
    daily_rule_title_1: "I.Etkinlik Koşulu:",
    daily_rule_content_1: "Yatırım + Bahis (7 gün arka arkaya sıfırlanır)",
    daily_rule_title_2: "II.Etkinlik İçeriği:",
    daily_rule_content_2: `1.Günlük yatırım ve bahis, etkinlik koşullarını karşılayan başarılı oturum açma için gerekir<br>
  karşılık sabit bonuslar veya gizli bonuslar talep edilebilir, en fazla 19.<br>
  2.Bu etkinlik, kesinti olması durumunda 1. günün devamı olan süreklilik etkinliğidir;<br>
  3.Ödüller yalnızca iOS, Android, H5, PC'de manuel olarak talep edilebilir<br>
  4.Bu etkinlikte verilen bonus (asıl haricinde) çekmek için 1 kez döngüye sokulmalıdır (yani doğrulama,
  bahis veya geçerli bahisler) ve bahis oyun platformuna sınırlı değildir;<br>
  5.Bu etkinlik sadece hesap sahibinin normal manuel işlemlerine ayrılır, kiralama, hile yazılımları kullanımı,
  botlar,<br>
  farklı hesaplar arasında bahis, karşılıklı manipülasyon, arbitraj, API'ler, protokoller, güvenlik açıklarını
  sömürme, grup kontrolü veya diğer teknik yöntemler yasaktır, aksi takdirde ödüller iptal edilebilir
  veya<br>
  düşürülebilir, hesap dondurulabilir veya hatta kara listeye alınabilir;<br>
  6.Metin anlaşılmazlıklarını önlemek için, platform bu etkinliğin son yorumunu saklı tutacaktır.`,
    Back: "Geri",
    Hot: "Popüler",
    Recent: "Son",
    Distributed: "Dağıtıldı",
    Deposited: "Yatırıldı",
    Transfer_in: "İçe Aktar",
    Transfer_out: "Dışa Aktar",
    Settlement_period: "Hesaplaşma Dönemi",
    hours: "saat",
    Total_claimed: "Toplam Talep Edilen",
    Interest_ceiling: "Faiz Tavanı",
    No_restrictions: "Herhangi Bir Kısıtlama Yok",
    Total_Earnings: "Toplam Kazanç",
    Record_details: "Kayıt Detayları",
    Interest_rules: "Faiz Kuralları",
    Interest_tips: "Yıllık Faiz Oranı 15%",
    Time: "Zaman",
    Type: "Tür",
    Amount: 'Miktar',
    Rebate_Records: 'İndirim<br>Kayıtları',
    Rebate_rate: "İndirim Oranı",
    Receive_amount: "Alınan Miktar",
    Rebateable: "İndirimli",
    to_level: "Sonraki Düzeye Ulaşmak İçin",
    bsl: "Bahis Hala Eksik",
    VIP_Level_List: "VIP Düzey Listesi",
    Promotion_Bonus: "Terfi Bonusu",
    Weekly_Bonus: 'Haftalık Bonus',
    Monthly_Bonus: 'Aylık Bonus',
    Tips: "İpuçları",
    tips_content: `Sonraki düzeye yükselmek için, mevcut kümülatif geçerli bahisler temel alınarak ek bahisler gerekir. 
  Örneğin, VIP 1'e yükselmek için 1000 kümülatif geçerli bahise, VIP 2'ye yükselmek için 2000 kümülatif geçerli bahise ihtiyaç vardır. 
  Dolayısıyla, VIP 2'ye ulaşmak için oyuncu toplam 3000 kümülatif geçerli bahise (1000 + 2000) ihtiyaç duyar ve böyle devam eder.`,
    vip_rule_title: "VIP Kuralları Açıklaması",
    vip_rule: `1.Terfi Standartı: VIP terfi gereksinimlerini karşılamak (yani yatırım veya geçerli bahisler kriterleri karşılanır) ve karşılık gelen VIP seviyesine yükselerek terfi bonusunu almak. Birden fazla seviye terfi edildiğinde, tüm seviye terfi bonuslarını alabilirsiniz. Anlık bonuslar talep edilebilir;\r\n
  2.Günlük Bonus: Mevcut seviyenin günlük yatırım ve geçerli bahis gereksinimlerini karşıladığınızda, karşılık gelen günlük bonusu alabilirsiniz. Birden fazla seviye terfi edildiğinde, sadece mevcut seviyenin günlük bonusunu alabilirsiniz. Anlık bonuslar talep edilebilir;\r\n
  3.Haftalık Bonus: Her hafta mevcut seviyenin yatırım ve geçerli bahis gereksinimlerini karşıladığınızda, karşılık gelen haftalık bonusu alabilirsiniz. Birden fazla seviye terfi edildiğinde, sadece mevcut seviyenin haftalık bonusunu alabilirsiniz. Anlık bonuslar talep edilebilir;\r\n
  4.Aylık Bonus: Her ay mevcut seviyenin yatırım ve geçerli bahis gereksinimlerini karşıladığınızda, karşılık gelen aylık bonusu alabilirsiniz. Birden fazla seviye terfi edildiğinde, sadece mevcut seviyenin aylık bonusunu alabilirsiniz. Anlık bonuslar talep edilebilir;\r\n
  5.Ödül Süresi: Alınan bonus, %d gün boyunca saklanır. Bu dönemde aktif olarak talep edilmezse, otomatik olarak hesabınıza kredilendirilir. Örneğin: 1 Ocak'ta bir ödül alınıp %d gün boyunca saklanırsa, 1 Ocak'ta 00:00:00'da otomatik olarak hesabınıza kredilendirilir.\r\n
  6.Bakım Açıklaması: Belirli bir VIP seviyesine ulaşıldıktan sonra, bir üyenin önceki ayın yatırımı ve geçerli bahisleri bakım gereksinimlerini karşılamazsa, otomatik olarak bir seviye düşürülür.\r\n
  7.Denetim Notu: VIP bonusları, 1 kez bahis (yani denetim, bahis veya geçerli bahisler) edilerek çekme için uygun hale getirilir. Bahis, herhangi bir oyun platformuna sınırlı değildir;\r\n
  8.Etkinlik Açıklaması: Bu özellik, sadece hesap sahibinin normal oyun bahisleri içindir. Hesap kiralaması, risk-free bahis (eşleştirme, fırça, düşük oranlar), kötü niyetli arbitraj, eklenti, bot, protokol, güvenlik açığı, arayüz, grup kontrolü veya diğer teknik yöntemler yasaktır. Doğrulandıktan sonra, platform üye girişlerini sona erdirmek, üye web sitesi kullanımını askıya almak ve ödülleri ve uygunsuz kârları kapatmak için önceden bilgi vermeden yetkili olur.\r\n
  9.Açıklama: VIP ödüllerini talep ederek, platform üye tarafından karşılık gelen koşullara ve ilgili düzenlemelere uyununun kabul edildiğini varsayar. Metin yorumlamasındaki yanlış anlaşılmaları önlemek için, platform bu etkinliğin son yorumunu saklı tutar.`,
    Misson: "Görev",
    Set_Up: "Ayarla",
    Link: "Bağla",
    Please_link_your_mobile_phone: "Lütfen mobil telefonunuzu bağlayın",
    Please_link_your_mail: "Lütfen e-postanızı bağlayın",
    birth_tip: "Doğum Günü (bir kez ayarlandıktan sonra değiştirilemez)",
    pltwaf: "Lütfen önce çekme hesabınızı bağlayın",
    ped: 'Lütfen 6-40 basamak girin',
    plw: 'Lütfen WhatsApp numarasını girin',
    pefa: "Lütfen Facebook hesabınızı girin",
    pet: 'Lütfen Telegram numarasını girin',
    eut: '5-40 basamak girin',
    Year: "Yıl",
    Save: "Kaydet",
    Male: "Erkek",
    Female: "Kadın",
    Statement: "Açıklama",
    Cumulative_bet_amount: "Kümülatif Bahis Miktarı",
    Total_Valid_Bets: "Toplam Geçerli Bahisler",
    Total: "Toplam Kâr/Zarar",
    nob: "Bahis Sayısı",
    ba: "Bahis Miktarı",
    Ganhos: "Kâr/Zarar",
    Verify_Login_Password: "Giriş Şifresini Doğrula",
    you_tip: "Giriş şifresini doğruladıktan sonra telefonu bağlayabilirsiniz",
    Other_Verification_Methods: "Diğer Doğrulama Yöntemleri",
    The_above_method_is_not_available: "Yukarıdaki yöntem kullanılamıyor?",
    Contact_Us: "Bizimle İletişime Geçin",
    Enter_Password: "Şifre Girin",
    Mobile_Phone: "Mobil Telefon",
    phone: "Lütfen telefon numarasını girin",
    Player_ID: "Oyuncu ID",
    Unlinked: "Bağlı Değil",
    Email: "E-posta",
    Login_Password: "Giriş Şifresi",
    Security_Question: "Güvenlik Sorusu",
    Link_Third_party_Login: "Üçüncü Taraf Girişini Bağla",
    Unset: "Ayarlanmamış",
    set_title: "İlk çekme işleminiz, önce çekme şifresini ayarlamanız gerekmektedir",
    set_up: "Çekme Şifresini Ayarla",
    New_Withdrawal: "Yeni Çekme Şifresi",
    Old_Withdrawal: "Eski Çekme Şifresi",
    password_note: `Not: Çekme şifreniz, fonlarınızın güvenliğini korur. Çok önemlidir ve herhangi bir finansal kayıp yaşanmasını önlemek için sadece sizin bilmeniz gerekir.`,
    confirm: "onayla",
    Confirm: "Onayla",
    Current_Lucky_Value: "Mevcut Şans Değeri",
    you_still_need: "Hala bahis yapmanız gerekmektedir",
    to_get: "almak için",
    Lucky_Points: "Şans Puanları",
    Instant: "Anlık<br>Çekiliş",
    Luck_Value: "Şans Değeri",
    Silver: "Gümüş",
    Golden: "Altın",
    Diamond: "Elmas",
    Reward_announcement: "Ödül Duyurusu",
    My_Records: 'Kayıtlarım',
    won_at: "kazandı",
    returntable_rule_content: "Kümülatif geçerli bahisler (yani belirli platformun bahisleri veya denetim)",
    Designated_platform: "Belirlenen platform",
    return_table_content_2: `1.Etkinlik döneminde, her 1 geçerli bahis otomatik olarak 1 şans puanı kazandırır. 1000-10000 şans puanı, 1 çekiliş hakkı verir, maksimum ödül 9999'dur.<br>
    2.Bugün kazandığınız ve ertesi gün kullanılmayan şans puanları süresi dolacak ve sıfırlanacaktır;<br>
    3.Ödüller yalnızca iOS, Android, H5, PC'de manuel olarak talep edilebilir<br>
    4.Bu etkinlikte verilen bonus (asıl haricinde) çekmek için 1 kez döngüye sokulmalıdır (yani doğrulama,
    bahis veya geçerli bahisler) ve bahis oyun platformuna sınırlı değildir;<br>
    5.Bu etkinlik sadece hesap sahibinin normal manuel işlemlerine ayrılır, kiralama, hile yazılımları kullanımı,
    botlar, farklı hesaplar arasında bahis, karşılıklı manipülasyon, arbitraj, API'ler, protokoller, güvenlik
    açığı sömürme, grup kontrolü veya diğer teknik yöntemler yasaktır, aksi takdirde ödüller iptal edilebilir
    veya düşürülebilir, hesap dondurulabilir veya hatta kara listeye alınabilir;<br>
    6.Metin anlaşılmazlıklarını önlemek için, platform bu etkinliğin son yorumunu saklı tutacaktır.`,
    twde: "Etkinlik döneminde toplam bahis",
    Used_Lucky_Value: "Kullanılan Şans Değeri",
    Expired_Lucky_Value: "Süresi Dolmuş Şans Değeri",
    Available_Lucky_Value: "Kullanılabilir Şans Değeri",
    Claim_Successfully: "Başarıyla Talep Edildi",
    Total_Account_Balance: "Toplam Hesap Bakiyesi",
    One_click_transfer: "Tek Tıklama İle Transfer",
    Live: "Canlı",
    Fishing: "Balıkçılık",
    Sports: "Spor",
    Cards: "Kart Oyunları",
    Lottery: "Loto",
    Blockchain: "Blockchain Oyunları",
    Slots: "Slot",
    All_Type: "Tüm Türler",
    ALL_Platform: "Tüm Platformlar",
    Platform: "Platform",
    Total_Commission: "Toplam Komisyon",
    Referral_information: "Referans Bilgisi",
    More: "Daha Fazla",
    Commission_Collected: "Toplanan Komisyon",
    Last_Commission: "Son Komisyon",
    Total_Members: "Toplam Üyeler",
    Total_angent: "Toplam",
    Direct_Subordinates: "Doğrudan Alt Üyeler",
    Others: "Diğerleri",
    Total_Perf: "Toplam Performans",
    Sub_Perf: "Alt Üyelerin Performansı",
    Others_Perf: "Diğerlerinin Performansı",
    Subordinate_Wagers: "Alt Üyelerin Bahisleri",
    Total_valid_bets: "Toplam Geçerli Bahisler",
    total_no: "Toplam Bahis Sayısı",
    Total_angent_wl: "Toplam Kâr/Zarar",
    Withdrawal_Account: "Çekme Hesabı",
    Hide: "Gizle",
    Show: "Göster",
    Add_New_Bank_Account: "Yeni Banka Hesabı Ekle",
    Enter_PIN: "PIN Girin",
    for_you_account: "Hesap güvenliğiniz için, lütfen çekme şifresini girin",
    pix_tip: "PIX Hesabı boş olamaz",
    digits_11: "Lütfen 11 basamak girin",
    digits_6: "Lütfen 6 basamak girin",
    Set_as_Default: "Varsayılan Olarak Ayarla",
    Add_withdrawal_account: "Çekme Hesabı Ekle",
    add_account_tip: "Lütfen önce çekme hesabını seçin",
    with_tip_1: "Çekme için gereken kalan bahis miktarı",
    with_tip_2: " ",
    Normal_withdrawal: "Normal Çekme",
    Min: 'Min',
    Max: 'Max',
    Withdrawal_Amount_cannot_be_empty: 'Çekme Miktarı boş olamaz',
    withdraw_err: "Denetim tamamlanana kadar çekme işlemi mevcut değil",
    wp_can_be_empty: "Çekme Şifresi boş olamaz",
    Can_be_collected_today: "Bugün toplanabilir",
    Fee: "Ücret",
    Add: "Ekle",
    All_status: "Tüm Durumlar",
    Not_settled: 'Ödenmedi',
    Settled: 'Ödendi',
    Order_canceled: "Sipariş İptal Edildi",
    Confirm_New_Withdrawal_Password: "Yeni Çekme Şifresini Onayla",
    Search: "Ara",
    Search_Games: "Oyunları Ara",
    tab_Search: "Arama",
    Favorite: "Favori",
    Reports_tab: "Rapor",
    em_claim: "Şu anda talep edilecek mevcut ödül yok",
    Start_Date: "Başlangıç Tarihi",
    End_Date: "Bitiş Tarihi",
    Cancel: "İptal",
    My_ID: "ID'm",
    Contribution_Commission: "Katkı Komisyonu",
    total_first: "Toplam ilk yatırım yapanlar",
    Other_first_deposit: "Diğer ilk yatırımlar",
    Other_deposit: "Diğer yatırımlar",
    Direct_first_deposit: "Doğrudan ilk yatırım",
    Subordinate_Deposits: "Alt Üyelerin Yatırımları",
    Subordinate: "Alt Üye",
    Full_Screen: "Tam Ekran",
    Refresh: "Yenile",
    Yes: "Evet",
    No: "Hayır",
    Settlement_date: "Hesaplaşma Tarihi",
    Contributors: "Katılımcılar",
    Joining_time: "Katılım Tarihi",
    Member_ID: "Üye ID",
    Statistical_date: "İstatistik Tarihi",
    Number_of_bets: "Bahis Sayısı",
    Total_W_L: "Toplam Kâr/Zarar",
    bounusTip: "Alt üyeleri, hesaplama döneminde yatırımları ≥0 ve geçerli bahisleri ≥0 ise, geri ödeme yapar.",
    Type_Details: "Tür Detayı",
    Bet_ID: "Bahis ID",
    Bet_amount: "Bahis Miktarı",
    In_game: "Oyunda Kullanım",
    page: 'sayfa',
    Page: 'Sayfa',
    entries: 'girişler',
    page_total_1: "Toplam",
    page_total_2: "giriş",
    more_1: "Şu anda gösterilen",
    more_2: "popüler oyunlar",
    more_3: "",
    Load_More: "Daha Fazla Yükle",
    Reminder: "Hatırlatma",
    Log_out_from_account: "Hesaptan Çıkış Yap",
    Cashback_Exclusivo: "Özel İade",
    privileges_Exclusivo: "VIP İndirimleri",
    login_password_tip: 'Giriş şifresini doğruladıktan sonra giriş şifresini değiştirin',
    Confirm_the_new_password: "Yeni şifreyi onaylayın",
    Daily_loss_rescue_fund: "Günlük Kayıp Kurtarma Fonu",
    Loss_amount: "Kayıp Miktarı",
    Extra_Rewards: "Ek Ödüller",
    Yesterday_s_losses: "Dünki Kayıplar",
    Today_s_Rescue_Bonus: "Bugünkü Kurtarma Bonusu",
    Lucky_wager: "Şanslı Bahis",
    No_winning_wager_today: "Bugün kazanan bahis yok",
    max_times_claimed: "Bugün en fazla {num} kez talep edilebilir ({num} kez kalmış)",
    Bet_number_last_digits: "Bahis numarasının son rakamları",
    Reward_multiple: "Ödül Katılımı",
    Reward_limit: "Ödül Sınırı",
    reward_amount: "Ödül Miktarı = O bahis kağıdının bahis miktarı x Bonus katlılığı, sınırı aşmamak üzere",
    Music: 'Müzik',
    Cycle: 'Sıralama',
    Shuffle: 'Karıştır',
    Repeat: 'Tekrar Et',
    Downloaded: 'İndirilen',
    System_Music: "Sistem Müziği",
    My_music: "Müziğim",
    change_password_note: "Dikkat: Çekme şifresi, fonlarınızı korur ve çok önemlidir. Herhangi bir finansal kayıp yaşanmasını önlemek için sadece sizin bilmeniz gerekir",
    Change_Withdrawal_Password: 'Çekme Şifresini Değiştir',
    Link_Email: 'E-posta Bağla',
    Enter_Email: 'E-posta Girin',
    Deslize_para: 'Bulmacayı tamamlamak için kaydırın',
    length_tip: '{lengthRange}basamak',
    Numbers: 'Rakamlar',
    Lowercase: 'Küçük Harfler',
    Uppercase: 'Büyük Harfler',
    My_superiors: 'Üst Üyelerim',
    Agent_mode: 'Aracı Modu',
    Unlimited_level: 'Sınırsız Seviye Farkı (Günlük Hesaplaşma)',
    OfferCenter: 'Teklif Merkezi',
    SupportCenter: 'Destek Merkezi',
    OfficialChannel: 'Resmi Kanal',

    pleaseOpenYour: 'Lütfen ödeme uygulamanızı açın ve aşağıdaki QR kodunu tarama veya kopyalayıp yapıştırarak satın almayı tamamlayın;',
    This_QR_code: 'Bu QR kodu yalnızca bir kez ödenebilir. Tekrar ödeme yapmanız gerekiyorsa, lütfen geri dönüp yeniden yükleyin;',
    After_the_payment: 'Ödeme başarılı olduktan sonra, oyun lobisine dönebilir ve puanların eklenmesini bekleyebilirsiniz!',
    Effective_time: 'Geçerlilik Zamanı',
    Copy_QR_code: 'QR Kodunu Kopyala',
    QR_code_address: 'QR Kod Adresi',
    Order_Status: 'Sipariş Durumu',
    To_be_paid: 'Ödenecek',
    Creation_Time: 'Oluşturma Zamanı',
    Order_Number: 'Sipariş Numarası',
    Merchant_order_number: 'Satıcı Sipariş Numarası',
    success: 'Başarılı',
    failure: 'Başarısız',
    Direct_subordinates: "Doğrudan Altımdakiler",
};