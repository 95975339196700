import messageComponent from "./message.vue";
import Vue from "vue";

const messageConstructor = Vue.extend(messageComponent);
const instances = [];
let id = -1;
function Message(options) {
  const messageInstance = new messageConstructor();
  messageInstance.message = options.message;
  messageInstance.type = options.type;

  let offsetTop = 20;
  instances.forEach((item) => {
    offsetTop += item.$el.offsetHeight + 20;
  });
  messageInstance.top = offsetTop;
  messageInstance.$mount(document.createElement("div"));
  document.body.appendChild(messageInstance.$el);
  id++;
  messageInstance.id = id;

  messageInstance.show();
  instances.push(messageInstance);
  setTimeout(() => {
    const offsetHeight = messageInstance.$el.offsetHeight;
    const index = instances.findIndex((o) => o.id === messageInstance.id);
    instances.forEach((item) => {
      item.$el.style.top =
        parseInt(item.$el.style.top) - offsetHeight -20 + "px";
    });
    messageInstance.visible = false;
    instances.splice(index, 1);
  }, 3000);
};

export default Message;