import message from "./message/";
import cryptoJs from 'crypto-js'

var app_key = '578fg8x9s8r5f6s5'
var app_iv = 'f5d3c5s4e5q2z5d4'

var u = navigator.userAgent;
export const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

export const parseQueryString = function (url) {
  var obj = {};
  var keyvalue = [];
  var key = "";
  var value = "";
  var paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
  for (var i = 0; i < paraString.length; i++) {
    keyvalue = paraString[i].split("=");
    key = keyvalue[0];
    value = keyvalue[1];
    obj[key] = value;
  }
  return obj;
};

export const copyText = function (text) {
  const oInput = document.createElement("input");
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select();
  document.execCommand("Copy");
  oInput.remove();
  message({
    type: "success",
    message: "Copied successfully",
  });
};

export const downloadBase64 = function (imgUrl) {
  if (window.navigator.msSaveOrOpenBlob) {
    const bstr = atob(imgUrl.split(",")[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr]);
    window.navigator.msSaveOrOpenBlob(blob, "code.jpg");
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    a.setAttribute("download", "code.jpg");
    a.click();
  }
};
export function hasClass(el, cls) {
  if (!el || !cls) return false;
  if (cls.indexOf(" ") !== -1)
    throw new Error("className should not contain space.");
  if (el.classList) {
    return el.classList.contains(cls);
  } else {
    return (" " + el.className + " ").indexOf(" " + cls + " ") > -1;
  }
}
export function recentTime(day, fmt, time) {
  let now = (time ? new Date(time) : getLocalTime(-3)).getTime();
  let recent = new Date(now + day * 24 * 60 * 60 * 1000);

  let fmtObj = {
    "M+": recent.getMonth() + 1,
    "d+": recent.getDate(),
    "h+": recent.getHours(),
    "m+": recent.getMinutes(),
    "s+": recent.getSeconds(),
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (recent.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let key in fmtObj) {
    if (new RegExp(`(${key})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? fmtObj[key]
          : ("00" + fmtObj[key]).substr(("" + fmtObj[key]).length)
      );
    }
  }
  return fmt;
}

export function addClass(el, cls) {
  if (!el) return;
  var curClass = el.className;
  var classes = (cls || "").split(" ");

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.add(clsName);
    } else if (!hasClass(el, clsName)) {
      curClass += " " + clsName;
    }
  }
  if (!el.classList) {
    el.className = curClass;
  }
}

// export function isPc() {
//   return window.innerWidth > 750;
// }

export function onScrollEvent(el, fn, delay) {
  var is = true;
  return () => {
    if (!is) {
      return false;
    }
    if (el.scrollHeight - el.scrollTop - el.clientHeight == 0) {
      is = false;
      fn();
      setTimeout(() => {
        is = true;
      }, delay);
    }
  };
}

export function encryptData (word) {
  var key = cryptoJs.enc.Utf8.parse(app_key)
  var iv = cryptoJs.enc.Utf8.parse(app_iv)
  var srcs = cryptoJs.enc.Utf8.parse(word)
  var encrypted = cryptoJs.AES.encrypt(srcs, key, {
    iv: iv,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.Pkcs7
  })
  return encrypted.toString()
}

export function decryptData (data) {
  var key = cryptoJs.enc.Utf8.parse(app_key)
  var iv = cryptoJs.enc.Utf8.parse(app_iv)
  var decrypt = cryptoJs.AES.decrypt(data, key, {
    iv: iv,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.Pkcs7
  })
  return decrypt.toString(cryptoJs.enc.Utf8)
}

export const MothOptions = [
  { label: "01", value: 1 },
  { label: "02", value: 2 },
  { label: "03", value: 3 },
  { label: "04", value: 4 },
  { label: "05", value: 5 },
  { label: "06", value: 6 },
  { label: "07", value: 7 },
  { label: "08", value: 8 },
  { label: "09", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
];
export function getLocalTime(i) { 
  if (typeof i !== 'number') return;
  let d = new Date(); 
  let len = d.getTime();
  let offset = d.getTimezoneOffset() * 60000;
  let utcTime = len + offset;
  return new Date(utcTime + 3600000 * i);
}
