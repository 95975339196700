import loading from './loading'
import scrollbottom from './scrollbottom'
import price from './price'
import autoSize from './autoSize'
import Clickoutside from 'element-ui/src/utils/clickoutside';

const directives = {
  install:Vue=>{
    Vue.directive('loading',loading)
    Vue.directive('Clickoutside',Clickoutside)
    Vue.directive('scrollbottom',scrollbottom)
    Vue.directive('price',price)
    Vue.directive('autoSize',autoSize)
  }
}

export default directives