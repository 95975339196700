import maintain from "./maintain";
import Vue from "vue";
const maintainConstructor = Vue.extend(maintain);

function Maintain(options) {
  const instance = new maintainConstructor();
  instance.time = options?.time || '';
  instance.url = options?.url || '';
  if (!document.getElementById('maintain')) {
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);
  }
}

export default Maintain;
