<template>
  <div class="bonus" v-loading="loading">
    <div class="card row column-c row-sb">
      <div class="row c-left column-c">
        <img src="@/assets/images/img_zcsc.png" alt="">
        <div>
          <div class="darken-color">{{ $t('First_deposit_upon_registration') }}</div>
          <div class="mt01">
            <span class="lighten-color">{{ $t('Bonus') }}</span>
            <span class="warn-color ml01">{{ info.first_recharge.bonus }}</span>
          </div>
        </div>
      </div>
      <pg-button type="primary" size="mini" v-if="info.first_recharge.status == 0" @click="toDeposit">Go</pg-button>
      <pg-button type="primary" size="mini" v-if="info.first_recharge.status == 1" :loading="first_rechargeBtnLoading" @click="claim('first_recharge')">{{
        $t('Claim') }}</pg-button>
      <pg-button type="primary" size="mini" v-if="info.first_recharge.status == 2" :disabled="true">{{ $t('Collected')
      }}</pg-button>
    </div>
    <div class="card row column-c row-sb">
      <div class="row c-left">
        <img src="@/assets/images/img_lm.png" alt="">
        <div>
          <div class="darken-color">{{ $t('Register_An_Account') }}</div>
          <div class="mt01">
            <span class="lighten-color">{{ $t('Bonus') }}</span>
            <span class="lighten-color ml01">{{ info.register.bonus }}</span>
          </div>
        </div>
      </div>
      <pg-button type="primary" size="mini" v-if="info.register.status == 1" :loading="btnLoading" @click="claim('register')">{{
        $t('Claim') }}</pg-button>
      <pg-button type="primary" size="mini" v-if="info.register.status == 2" :disabled="true">{{ $t('Collected')
      }}</pg-button>
    </div>
    <div class="card mis-rule">
      <p class="darken-color">I.{{ $t('Task_time') }}-3):</p>
      <p class="lighten-color">{{ $t('bonus_1') }}</p>
      <p class="darken-color">{{ $t('bonus_2') }}</p>
      <p class="lighten-color">{{ $t('bonus_2_content') }}</p>
      <p class="darken-color">{{ $t('bonus_3') }}</p>
      <p class="lighten-color" v-html="$t('bonus_3_content')"></p>
    </div>
  </div>
</template>

<script>
import { apiMissoionInfo, apiMissoionCollect } from '@/api'
export default {
  data() {
    return {
      loading: false,
      info: {
        first_recharge: {
        },
        register: {

        }
      },
      first_recharge_loading: false,
      register_loading: false,
      btnLoading:false
    }
  },
  inject: ['Layout'],
  methods: {
    getData() {
      this.loading = true;
      apiMissoionInfo().then(res => {
        this.info = res.data.new
        this.loading = false;
      }).catch(err => {
        this.loading = false
      })
    },
    claim(collect_type) {
      this[collect_type+'_oading'] = true;
      apiMissoionCollect({ collect_type}).then(res => {
        this.$message({
          type:'success',
          message:res.data.message
        })
        this.getData()
        this[collect_type+'_oading'] = false;
      }).catch(err => {
        this[collect_type+'_oading'] = false;
      })
    },
    toDeposit() {
      this.Layout.depositVisible = true;
      this.$emit('close')
    },
  },
}
</script>

<style lang='scss' scoped>
.bonus {
  .card.row {
    border-radius: 0.1rem;
    padding: .2rem .1rem .2rem .2rem;
    margin-bottom: 0.2rem;

    img {
      width: 30px;
      height: 30px;
      margin-right: 0.1rem;
      object-fit: contain;
    }

    .lighten-color,
    .warn-color {
      font-size: .18rem;
    }
  }

  .card.mis-rule {
    border-radius: 0.1rem;
    padding: 0.2rem;
    line-height: 1.45;

    p {
      margin-top: 0.05rem;
      font-size: .22rem;
    }
  }
}
</style>