<template>
  <div class="pg-progress">
    <div class="pg-progress-inner" :style="{ width: lineWidth }"></div>
    <div class="progress-text"> {{ current }}/{{ total }}</div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: [Number,String]
    },
    current: {
      type: [Number,String]
    }
  },
  computed: {
    lineWidth() {
      let width = 0;
      if (this.total != 0) {
        width = Number(this.current / this.total * 100).toFixed(2)
      }
      return width + '%'
    }
  }
}
</script>

<style lang='scss' scoped>
.pg-progress {
  background-color: var(--theme-text-color-placeholder);
  border-radius: 0.1rem;
  width: 100%;
  height: 0.2rem;
  color: var(--theme-text-color);
  overflow: hidden;
  position: relative;

  .pg-progress-inner {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: var(--theme-secondary-color-success);
    border-radius: 0.1rem;
  }

  .progress-text {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 0.2rem;
    text-align: center;
    z-index: 1;
  }
}
</style>