<template>
    <div id="sys-game" v-loading="pageLoading" @click="appClick" :class="isPc ? 'pc-page' : 'mobile-page'">
        <div class="pc-el">
            <pg-header></pg-header>
        </div>
        <div class="sys-bottom">
            <div class="sys-slider-wrap pc-el">
                <slider v-show="sliderShow" @showMission="$refs.mission.show()" />
            </div>
            <div class="sys-main-wrap">
                <div class="sys-main" ref="mainContent">
                    <keep-alive v-if="$route.meta.keepAlive">
                        <router-view></router-view>
                    </keep-alive>
                    <router-view v-else></router-view>
                    <Tabbar @tabbar-click="handleClick" v-if="gameList.length > 0" />
                    <pg-footer v-if="$route.path.indexOf('index') == -1 && isPc && gameList.length > 0"></pg-footer>
                </div>

            </div>
        </div>
        <LoginPop ref="loginPop" v-model="visible" @login="getUserInfo" @forgot="forgotPassword"
            @showUserAgreenment="showUserAgreenment" />
        <!-- <pg-poup v-model="forgotVisble">
            <div class="forgot-wrap" style="width: 6.9rem;">
                <div class="darken-color">{{ $t('Forgot_Password') }}</div>
                <div class="progress-container">
                    <div class="progress-item active">
                        <div class="steps-icon">1</div>
                    </div>
                    <div class="progress-item ">
                        <div class="steps-icon">2</div>
                    </div>
                    <div class="progress-item">
                        <div class="steps-icon">3</div>
                    </div>
                </div>
            </div>
            <div class="user-form">
                <pg-form v-model="form" :config="config"></pg-form>
                <pg-button class="btn" type="primary">{{ $t('Next') }}</pg-button>
            </div>
        </pg-poup> -->
        <pg-poup v-model="agreementVisible">

            <div class="agreeement-pop">
                <span class="close-line pc-el" @click="agreementVisible = false">
                    <Icon type="close-line" />
                </span>
                <div class="scroll-wrap">
                    <div style="font-size: 0.19rem;">
                        <p style="text-align: center;"><strong>{{ $t('User_Agreement') }}</strong></p>
                        <p style="text-align: center;">&nbsp;</p>
                        <p>{{ $t('user_agreement_1') }}</p>
                        <p>{{ $t('user_agreement_2') }}</p>
                        <p>{{ $t('user_agreement_3') }}</p>
                        <p>{{ $t('user_agreement_4') }}</p>
                        <p>{{ $t('user_agreement_5') }}</p>
                    </div>
                </div>
                <div class="btn-wrap">
                    <pg-button class="btn" type="primary" @click="agreementVisible = false">{{
        $t('I_have_read') }}</pg-button>
                </div>
            </div>
        </pg-poup>

        <!-- login out -->
        <pg-poup v-model="loginOut" :showClose="false">
            <div class="confirm-pop">
                <div class="o-title">{{ $t('Reminder') }}</div>
                <div class="o-content">{{ $t('Log_out_from_account') }}?</div>
                <div class="row">
                    <pg-button :loading="loginOutLoading" class="confirm-btn flex-1" size="mid"
                        @click="handleLoginOut">{{
        $t('Confirm') }}</pg-button>
                    <pg-button type="primary" class="flex-1" size="mid" @click="loginOut = false">{{ $t('Cancel')
                        }}</pg-button>
                </div>
            </div>
        </pg-poup>
        <!-- refresh -->
        <pg-poup v-model="refreshShow" :showClose="false">
            <div class="confirm-pop">
                <div class="o-title">{{ $t('Reminder') }}</div>
                <div class="o-content">{{ $t('Log_out_from_account') }}?</div>
                <div class="row">
                    <pg-button :loading="loginOutLoading" class="confirm-btn flex-1" size="mid"
                        @click="cancelRefresh">{{ $t('Cancel') }}</pg-button>
                    <pg-button type="primary" class="flex-1" size="mid" @click="handleRefresh">{{ $t('Confirm')
                        }}</pg-button>
                </div>
            </div>
        </pg-poup>
        <Deposit :visible.sync="depositVisible" />
        <DepositRecords :visible.sync="depositRecordVisible" />
        <Mission ref="mission" />
        <MusicDialog ref="musicDialog" />
    </div>
</template>

<script>
import Mission from '@/layout/components/Mission.vue';
import Tabbar from './components/Tabbar.vue'
import LoginPop from '@/components/LoginPop.vue'
import Deposit from './components/Deposit.vue'
import PgHeader from './components/PgHeader.vue'
import { mapState } from 'vuex'
import PgFooter from '@/layout/components/PgFooter.vue';
import slider from './components/slider.vue'
import DepositRecords from './components/DepositRecords.vue'
import MusicDialog from './components/MusicDialog'
export default {
    components: { PgFooter, Tabbar, LoginPop, Deposit, slider, PgHeader, DepositRecords, Mission, MusicDialog },
    data() {
        return {
            form: {
                user: ''
            },
            config: [
                {
                    type: 'input',
                    attr: {
                        prefix: 'user',
                    },
                    prop: 'user',
                    validate(val) {
                        if (val.length < 4 || val.length > 16) {
                            return '4-16 bit character, support English/numbers'
                        }
                    }
                }
            ],
            visible: false,
            toRoute: '',
            depositVisible: false,
            pageLoading: false,
            forgotVisble: false,
            timer: null,
            agreementVisible: false,
            depositRecordVisible: false,
            isPc: false,
            loginOut: false,
            loginOutLoading: false,
            actionTimer: null,
            refreshShow: false,
        }
    },
    provide() {
        return {
            Layout: this,
        }
    },
    computed: {
        ...mapState({
            sliderShow: state => state.home.sliderShow,
            siteInfo: state => state.home.siteInfo,
            gameList: state => state.home.gameList,
            userInfo: state => state.user.userInfo
        }),
    },
    watch: {
        "$route.fullPath": {
            handler(val) {
                this.$refs.mainContent.scrollTop = 0
            }
        }
    },
    created() {
        this.$store.dispatch('GetHomeList')
        this.$store.dispatch('GetSite')
        this.$store.dispatch('GetBannerList')
        this.isPc = false;


        if (this.isPc) {
            this.$store.commit('SET_SLIDER_SHOW', true)
        }
        if (localStorage.getItem('token')) {
            this.$store.dispatch('user/GetUserInfo')
        }
        // this.startActionTimer()
        this.getUserMoney()
        // window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))   //3
        // let isOpen = localStorage.getItem('isOpen')//1
        // let reload = localStorage.getItem('reload')//2
        // this.timer2 = setTimeout(() => {
        //     localStorage.removeItem('reload')//4
        // }, 2000);
        // console.log(isOpen, reload, isOpen == 'open', reload != 'reload', 'reload');
        // if (isOpen == 'open' && reload != 'reload') {
        //     localStorage.removeItem('isOpen')
        //     localStorage.removeItem('reload')
        //     this.timer3 = setTimeout(() => {
        //         let open1 = localStorage.getItem('isOpen')//7
        //         let load1 = localStorage.getItem('reload')
        //         if (open1 == 'open' && load1 !== 'reload') {
        //             window.alert('123')
        //             this.timer4 = setTimeout(() => {
        //                 window.opener = null;
        //                 location.href = "about:blank";
        //                 window.close();
        //                 localStorage.removeItem('reload')
        //                 localStorage.removeItem('isOpen')//6
        //             }, 2000)
        //         } else {
        //             history.go(0)
        //         }

        //     }, 1000)
        // } else {
        //     setInterval(() => {
        //         localStorage.setItem('isOpen', 'open')//5
        //     }, 1000);
        // }
    },
    mounted() {
        this.handleWidth();
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        window.onresize = () => {
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
            // this.isPc = window.innerWidth > 750;
            this.isPc = false;
            if (this.isPc && this.$route.path === '/me') {
                window.location.href = '/index'
            }
            this.$store.commit('SET_SLIDER_SHOW', this.isPc)
          this.handleWidth();
        }
    },
    methods: {
      handleWidth(){
        this.$nextTick(()=>{
          try {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const mobileKeywords = ['Mobi', 'Android', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone'];
            const isMobile = mobileKeywords.some(keyword => userAgent.indexOf(keyword) > -1);

            document.getElementsByTagName('html')[0].style.setProperty('--theme-max-width',isMobile?window.innerWidth+'px':window.innerHeight*0.56 +'px')
            if(!isMobile){
              document.getElementsByTagName('html')[0].style.setProperty('transform','translate(0,0)')
              document.getElementById('app').style.setProperty('transform','translate(0,0)')
            }
          }catch (e){
            console.log('3eeee',e)
          }
        })
      },
        beforeunloadHandler(e) {
            localStorage.setItem('reload', 'reload')
        },
        handleRefresh() {
            window.location.reload()
        },
        appClick() {
            // if (this.actionTimer) {
            //     clearTimeout(this.actionTimer)
            // }
            // this.startActionTimer();

        },
        startActionTimer() {
            this.actionTimer = setTimeout(() => {
                this.refreshShow = true;
            }, 10000)
        },
        cancelRefresh() {
            this.refreshShow = false;
            this.startActionTimer();
        },
        handleLoginOut() {
            this.clearTimer()
            this.loginOutLoading = true;
            setTimeout(() => {
                localStorage.removeItem('token')
                this.$store.commit('user/SET_MONEY', 0)
                this.$store.commit('user/SET_USERINFO', {})
                this.$router.replace('/index?login=true')
                this.loginOutLoading = false;
                this.loginOut = false
            }, 1000)
        },
        showUserAgreenment() {
            this.agreementVisible = true;
        },
        forgotPassword() {
            this.visible = false;
            // this.forgotVisble = true;
            window.open(this.siteInfo.service_link)
        },
        getUserMoney() {
            if (localStorage.getItem('token')) {
                this.$store.dispatch('user/GetUserMoney')
                this.startGetUserMoney()
            }
        },
        getUserInfo() {
            this.getUserMoney()
            this.$store.dispatch('user/GetUserInfo')
            if (this.toRoute) {
                this.$router.replace(this.toRoute)
                this.toRoute = ''
            }
        },
        startGetUserMoney() {
            if (this.timer) {
                return
            }
            this.timer = setInterval(() => {
                if (localStorage.getItem('token') && this.$route.path !== '/game') {
                    console.log(this.$route)
                    this.$store.dispatch('user/GetUserMoney').catch(err => {
                        this.clearTimer()
                    })
                }
            }, 10000)
        },
        clearTimer() {
            clearInterval(this.timer)
            this.timer = null
        },
        handleClick(item) {
            if (item.class == 'deposit') {
                this.depositVisible = true
            } else if (item.class == 'register') {
                this.$refs.loginPop.type = 'register'
                this.visible = true
            } else if (item.class == 'set-password') {
                if (this.userInfo.has_qk_pwd) {
                    this.$router.push('/withdrawal')
                } else {
                    this.$router.push('/set-password')
                }
            }
        }
    },
    destroyed() {
        clearInterval(this.timer)
        // localStorage.removeItem('isOpen')
        // localStorage.removeItem('reload')
        // clearTimeout(this.timer2)
        // clearTimeout(this.timer3)
        // clearTimeout(this.timer4)
        // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    }
}
</script>

<style lang="scss">
#sys-game {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;

    .confirm-pop {
        width: auto;
        background-color: var(--theme-main-bg-color);
        border: thin solid;
        border-color: var(--theme-color-line);
        border-radius: 0.2rem;
        padding: .2rem .3rem .3rem;

        .o-title {
            text-align: center;
            font-size: .22rem;
            color: var(--theme-text-color-darken);
        }

        .o-content {
            width: 5.5rem;
            text-align: center;
            color: var(--theme-text-color);
            margin: 0.3rem 0 0.65rem;
            font-size: .24rem;
        }

        .confirm-btn {
            margin-right: 0.4rem;
        }
    }

    .sys-bottom {
        width: 100%;
        flex: auto;
        display: flex;

        .sys-slider-wrap {
            padding-top: 0.88rem;
            height: calc(var(--vh, 1vh) * 100);
            box-sizing: border-box;
        }

        .sys-main-wrap {
            height: calc(var(--vh, 1vh) * 100);
            width: 1rem;
            flex: 1;
            box-sizing: border-box;
        }

        .sys-main {
            width: 100%;
            height: 100%;
            background-image: url('@/assets/images/bg_pattern_tile.png');
        }
    }

    .forgot-wrap {
        .darken-color {
            text-align: center;
            font-size: .3rem;
            padding: 0.2rem;
        }

        .progress-container {
            border: 1px solid var(--theme-color-line);
            border-radius: 0.1rem;
            display: flex;
            padding: 0.4rem 0;
            width: 6.3rem;
            margin: 0 auto;
            align-items: center;
            justify-content: center;

            .progress-item {
                width: 0.4rem;
                display: flex;
                color: var(--theme-text-color-lighten);
                flex-direction: column;
                align-items: center;
                position: relative;

                &:not(:last-child)::after {
                    content: '';
                    display: block;
                    width: 1.53rem;
                    position: absolute;
                    left: .494rem;
                    top: 50%;
                    height: 0.1rem;
                    background: var(--theme-color-line);
                    transform: translate(0%, -50%);
                }

                &:not(:last-child) {
                    margin-right: 1.7rem;
                }

                .steps-icon {
                    text-align: center;
                    font-size: .24rem;
                    height: 0.6rem;
                    line-height: .6rem;
                    width: 0.6rem;
                    border-radius: 50%;
                    background-color: var(--theme-main-bg-color);
                    border: thin solid var(--theme-color-line);
                }

                &.active {
                    color: var(--theme-primary-font-color);

                    .steps-icon {
                        background-color: var(--theme-primary-color);
                        border: thin solid var(--theme-primary-color);
                    }
                }

            }
        }
    }

    .user-form {
        padding: 0 0.3rem;
        margin-top: 0.2rem;
        padding-bottom: 0.5rem;

        .input-prefix {
            font-size: .22rem;
        }

        .btn {
            display: block;
            width: 100%;
        }
    }



    .agreeement-pop {
        padding: 0.3rem 0;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        width: 6rem;
        margin: 8px auto;
        max-width: calc(100% - 16px);

        .close-line {
            cursor: pointer;
            position: absolute;
            right: .1rem;
            top: 5%;
            transform: translateY(-50%);
            font-size: .2rem;
            display: inline-block;
            color: var(--theme-text-color);
            height: 0.56rem;
            line-height: .56rem;
            width: 0.56rem;
        }

        .scroll-wrap {
            flex: 1;
            line-height: 1.5;
            color: var(--theme-text-color);
            padding: 0 0.2rem;
            overflow-y: scroll;
        }

        .btn-wrap {
            padding: 0.2rem;

            .btn {
                width: 100%;
            }
        }
    }
}

.pc-page {

    #sys-game {
        .confirm-pop {
            width: auto;

            .o-content {
                font-size: .18rem;
                width: 5.5rem
            }

            .confirm-btn {}
        }

        .agreeement-pop {
            max-height: calc(var(--vh, 1vh)* 100 - 0.1rem);
            overflow: auto;
            position: static;
        }

        .sys-bottom {
            .sys-slider-wrap {
                padding-top: 0.8rem;
                box-shadow: 0 0 0.09rem 0 rgba(0, 0, 0, .06)
            }

            .sys-main-wrap {
                padding-top: 0.8rem;
            }
        }
    }
}
</style>