import retirar from "./retirar";
import Vue from "vue";
const retirarConstructor = Vue.extend(retirar);

function Retirar(options,router) {

  const instance = new retirarConstructor();
  instance.response = options;
  instance.router = router
  instance.$mount(document.createElement("div"));
  document.body.appendChild(instance.$el);
  instance.visible = true;

}

export default Retirar;
