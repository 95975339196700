import { apiGamesList, apiGetSite, apiHomeBanner } from "@/api";
export default {
  state: {
    gameListLoading: false,
    gameList: [],
    currentTab: 0,
    isScroll: true,
    sliderShow: false,
    siteInfo: {},
    bannerList: {}
  },
  mutations: {
    SET_GAME_LOADING(state, data) {
      state.gameListLoading = data;
    },
    SET_GAME_LIST(state, data) {
      state.gameList = data;
    },
    SET_CURRENT_TAB(state, data) {
      state.currentTab = data;
    },
    SET_CURRENT_TAB_ISSCROLL(state, data) {
      state.isScroll = data;
    },
    SET_SLIDER_SHOW(state, data) {
      state.sliderShow = data;
    },
    SET_SITE_INFO(state, data) {
      state.siteInfo = data;
    },
    SET_BANNER_LIST(state, data) {
      state.bannerList = data;
    },
  },
  actions: {
    GetHomeList: ({ commit }) => {
      commit("SET_GAME_LOADING", true);
      apiGamesList()
        .then((res) => {
          commit("SET_GAME_LIST", res.data || []);
          console.log(res);
          commit("SET_GAME_LOADING", false);
        })
        .catch((err) => {
          commit("SET_GAME_LOADING", false);
        });
    },
    GetSite: ({ commit }) => {
      commit("SET_GAME_LOADING", true);
      apiGetSite().then((res) => {
        commit("SET_SITE_INFO", res.data);
      });
    },
    GetBannerList: ({ commit }) => {
      apiHomeBanner().then((res) => {
        commit("SET_BANNER_LIST", res.data);
      });
    },
  },
};
