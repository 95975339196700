<template>
  <transition name="el-zoom-in-top">
    <div v-show="showPopper">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
import Popper from 'element-ui/src/utils/vue-popper';

export default {
  mixins: [Popper],
  name: 'dropItem',
  componentName: 'dropItem',
  mounted() {
    this.$parent.popperElm = this.popperElm = this.$el;
    this.referenceElm = this.$parent.$refs.dropParent;
  },
  created() {
    this.$on('visible', (val) => {
      this.showPopper = val;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
  transform-origin: center top
}

.el-zoom-in-top-enter,
.el-zoom-in-top-leave-active {
  opacity: 0;
  transform: scaleY(0)
}
</style>
