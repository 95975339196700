<template>
  <div class='pg-loading-wrap' v-show="visible">
    <img  class="loading-img" src="@/assets/images/loading.gif" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
.pg-loading-wrap {
  position: absolute;    
  border-bottom-left-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  background: rgba($color: #fff, $alpha: .2);
  justify-content: center;

  .loading-img {
    width: 1.5rem;
  }
}
</style>