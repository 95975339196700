<template>
  <transition name="pg-drawer">
    <div class='pg-drawer' v-show="visible" @click.self="handlerClose">
      <div :class="['drawer-body', visible ? 'drawer-open' : 'drawer-close']">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      }
    },
    model: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  methods: {
    handlerClose() {
      this.model && this.$emit('update:visible', false)
      this.$emit('close')
    }
  },
}
</script>

<style lang='scss' scoped>
.pg-drawer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: .6);
  z-index: 21;

  .drawer-body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: inline-block;
    position: relative;
  }
}
</style>