<template>
  <div style="font-size: 0;">
    <div class='bubble-wrap' :class="['bub-' + type]">
      <div class="bub-content">{{ content }}</div>
      <div class="bub-arrow "></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: String,
    type: {
      type: String,
      default() {
        return 'default'
      }
    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
.bubble-wrap {
  display: inline-block;

  .bub-content {
    font-size: .18rem;
    height: 0.26rem;
    line-height: .26rem;
    padding: 0 0.07rem;
    border-radius: 0.125rem 0.125rem 0.125rem 0;
    color: #fff;
    position: relative;
  }

  .bub-arrow {
    border-bottom: 0.07rem solid transparent;
    border-right: 0.07rem solid transparent;
    height: 0;
    width: 0;
  }

  &.bub-default {
    .bub-content {
      background-color: var(--theme-secondary-color-finance);
      border-radius: 0.125rem 0.125rem 0.125rem 0;
      color: #fff;
      font-size: .14rem;
      height: 0.22rem;
      line-height: .22rem;
      padding: 0 0.08rem;
      width: 100%;
    }

    .bub-arrow {
      border-left: 0.07rem solid var(--theme-secondary-color-finance);
    }
  }

  &.bub-error {
    .bub-content {
      background-color: var(--theme-secondary-color-error);
    }

    .bub-arrow {
      border-left: 0.07rem solid var(--theme-secondary-color-error);
    }
  }
}
</style>