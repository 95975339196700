<template>
  <div class="menu-btn" :class="[{ active }, { vertical }]" >
    <span class="icon-menu " v-if="icon">
      <Icon :type="icon" />
    </span>
    <img class="icon-img" v-if="img" :src="img" alt="">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    img: {
      type: String
    },
    text: {
      type: String
    },
    active: {
      type: Boolean
    },
    vertical: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
.menu-btn {
  user-select: none;
  cursor: pointer;
  margin: 0 auto;
  width: 1.5rem;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  background: var(--theme-main-bg-color);
  box-shadow: 0 0.03rem 0.09rem 0 var(--theme-aside-no-active-box-shadow);
  min-height: 0.7rem;
  border-radius: 0.1rem;
  padding: 0 0.04rem 0 0.06rem;

  .icon-img {
    width: 0.44rem;
    display: inline-block;
    margin: 0 auto .02rem;
    width: 0.5rem;
    object-fit: contain;
  }
  .text{
    flex: 1;
    text-align: center;
  }
  .icon-menu {
    font-size: .36rem;
    color: var(--theme-left-nav-text-color);
  }

  .text {
    font-size: 0.22rem;
    color: var(--theme-left-nav-text-color);
  }

  &.vertical {
    height: 1.05rem;
    justify-content: center;
    flex-direction: column;
    padding: 0 0.1rem;

    .icon-img,
    .icon-menu {
      width: 0.5rem;
      display: block;
    }
    .text{
      flex: none;
    }
  }


  &.active {
    background: var(--theme-primary-color);

    .icon-menu {
      color: var(--theme-primary-font-color);
    }

    .text {
      color: var(--theme-primary-font-color);
    }
  }

}
</style>