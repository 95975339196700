<template>
  <div id='interest'>
    <pg-nav v-if="Layout.isPc" :title="$t('interest')"></pg-nav>
    <div class="interest-body">
      <div class="card">
        <div class="row row-sb row-t">
          <div>
            <span class="c-label">{{ $t('Deposited') }}</span>
            <span class="c-value">0
              <Bubble class="bub" :content="$t('Interest_tips')" />
            </span>
          </div>
          <div class="row column-c ">
            <pg-button type="warn" size="mini" class="">{{ $t('Transfer_in') }}</pg-button>
            <pg-button type="primary" size="mini" class="blueBtn ml02">{{ $t('Transfer_out') }}</pg-button>
          </div>
        </div>
        <div class="row row-sb row-b">
          <div>
            <div class="info-box">
              <div class="c-label m-r-2">{{ $t('Settlement_period') }}</div>
              <div class="c-label mb01 darken-color">1 {{ $t('hours') }}</div>
            </div>
            <div class="info-box">
              <div class="c-label m-r-2">{{ $t('Total_claimed') }}</div>
              <div class="c-label">0.00</div>
            </div>
          </div>
          <div class="row">
            <div class="column row-e">
              <div class="info-box">
                <div class="c-label m-r-2">{{ $t('Interest_ceiling') }}</div>
                <div class="c-label darken-color" :class="[Layout.isPc ? '' : 'mb01']">{{ $t('No_restrictions') }}</div>
              </div>
              <div class="info-box">
                <div class="c-label m-r-2">{{ $t('Claim') }}</div>
                <div class="c-label warn-color"> 0,000000</div>
              </div>
            </div>

            <pg-button type="primary" size="mini" class="blueBtn mt02 ml02">{{ $t('Claim_1') }}</pg-button>
          </div>
        </div>

      </div>
      <Tabs :list="tabList" v-model="sub" />
      <div v-show="sub == 0">
        <div class="filter row row-sb column-c">
          <div class="row">
            <pg-select v-model="form.date" :options="dateOptions" />
            <pg-select v-model="form.type" class="ml02" :options="typeOptions" />
          </div>
          <div>
            <span class="lighten-color ">{{ $t('Total_Earnings') }}</span>
            <span class="warn-color">0.00</span>
          </div>
        </div>
        <div class="table-wrap">
          <pg-table :config="tableConfig"></pg-table>
        </div>
        
      </div>
      
      <div class="card-wrap" v-show="sub == 1">
        <div class="card">
          <div>1.<strong>Earnings Introduction:</strong> The amount deposited into the interest treasure must meet one
            complete cycle to generate interest. If withdrawn prematurely, the cycle will not count for earnings. For
            example, if the current settlement period is 1 hours, the amount transferred in on 2023-01-01 00:00:01 will
            generate the first cycle of interest on 2023-01-01 01:00:01;</div>
          <div>2.<strong>Settlement cycle: </strong>The current interest settlement period is 1 hours;</div>
          <div>3.<strong>Annual interest rate:</strong> The current annual interest rate is 15.00%;</div>
          <div>4.<strong>Calculation formula:</strong> Interest earnings = deposit amount * annual interest rate /
            settlement period;</div>
          <div>5.<strong>Example:</strong>A deposits 10,000 on 2023-01-01 00:00:01, the annual interest rate is 15%, and
            the
            settlement period is 1 hours, then the first interest income is obtained in 2023-01-01 01:00:01. The
            calculation
            is as follows:<strong>First interest =10000*15%/365 days/24 hours*1 hours = 0.171232</strong></div>
          <div>6.<strong> Entry threshold: </strong> Each transfer amount must be greater than or equal to 20 (i.e., ≥
            20),
            with no upper limit on the transfer amount. The more you transfer, the greater the earnings;</div>
          <div>7.<strong>Interest interest cap:</strong> There is no cap on the current interest rate. Remember to
            collect
            your income regularly or frequently to avoid missing out on more income! ;</div>
          <div>8.<strong>Claim time: </strong> Can claim on the next day, meaning that the interest generated on the
            same
            day can be claimed after 00:00 the next day;</div>
          <div>9.<strong> Auditing multiple: </strong> The current audition multiple is 1 times (betting turnover
            requirement). This means that the interest received must be bet before withdrawal. BetNo specific valid
            platformsOnly the received interest requires auditing, while the principal amount transferred in or out does
            not
            require auditing;</div>
          <div>10.<strong>Event Statement:</strong> This function is limited to the account owner for normal game
            betting.
            Account leasing, risk-free betting (betting, betting, low-loss betting), malicious arbitrage, use of
            plug-ins,
            robots, exploiting protocols, vulnerabilities, interfaces,Group control or other technical means are
            prohibited.
            Once verified, the platform reserves the right to terminate member logins, suspend member website usage, and
            confiscate bonuses and improper profits without prior notice;</div>
          <div>11.<strong>Explanation:</strong> When members claim interest rewards, this platform will default to
            members
            agreeing and complying with the corresponding conditions and other related regulations. To avoid ambiguity
            in
            understanding, this platform reserves the final interpretation of this activity;</div>
        </div>
      </div>
    </div>
    
  <div class="pagination" v-if="Layout.isPc && sub ==0">
    <pg-pagination :page-size="pager.limit" :current-page="pager.page" :page-sizes="[10, 20, 30, 40, 50]"
      background small layout="prev, pager, next,total, sizes, jumper" :total="total"
      @current-change="handlerPageChange" @size-change="handleSizeChange">
    </pg-pagination>
  </div>
  </div>
  
</template>

<script>
export default {
  data() {
    return {
      form: {
        date: 0,
        type: 0
      },
      typeOptions: [
        { label: 'All', value: 0 },
        { label: 'Transfer in', value: 1 },
        { label: 'Transfer out', value: 2 },
        { label: 'Claim Earnings', value: 3 },
      ],
      dateOptions: [
        { label: this.$t('Today'), value: 0 },
        { label: this.$t('Yesterdaty'), value: -1 },
        { label: `7 ${this.$t('Days')}`, value: -7 },
        { label: `15 ${this.$t('Days')}`, value: -15 },
        { label: `30 ${this.$t('Days')}`, value: -30 },
      ],
      tabList: [
        { label: this.$t('Record_details'), value: 'details' },
        { label: this.$t('Interest_rules'), value: 'rules' }
      ],
      sub: Number(this.$route.query.sub) || 0,
      tableConfig: [
        { label: this.$t('Time'), prop: 'Time', align: 'center' },
        { label: this.$t('Type'), prop: 'Type', align: 'center' },
        { label: this.$t('Amount'), prop: 'Amount', align: 'center' }
      ],

      pager: {
        page: 1,
        limit: 10
      },
      total: 0,
    }
  },
  watch: {
    sub(val) {
      let url = `${this.$route.path}?current=4&sub=${val}`;
      history.replaceState(null, null, url);
    }
  },
  inject: ['Layout'],
  methods: {
    handlerPageChange(page) {
      this.pager.page = page;
    },
    handleSizeChange(val) {
      this.pager.limit = val
      this.pager.page = 1;
    },
  },
}
</script>

<style lang='scss' scoped>
#interest {
  flex: 1;
  overflow-y: scroll;
  height: 100%;
  .card {
    padding: .4rem .2rem .2rem;

    .row .row .blueBtn {
      background-color: var(--theme-text-color) !important;
      border-color: var(--theme-text-color) !important;
      color: var(--theme-disabled-font-color) !important;
    }

    .c-label {
      font-size: .2rem;
      line-height: 1.45;

    }

    .m-r-2 {
      margin-right: .2rem;
    }

    .info-box {
      display: flex;
      flex-direction: column;
    }

    .c-value {
      color: var(--theme-text-color-darken);
      font-size: .3rem;
      font-weight: 700;
      height: 0.43rem;
      margin-left: 0.1rem;
      position: relative;

      .bub {
        width: 2rem;
        position: absolute;
        top: 0;
        left: 0.1rem;
        transform: translateY(-90%);
      }
    }


  }

  .filter {
    font-size: 0.2rem;
    padding: 0.2rem;
  }

  .table-wrap {
    padding: 0 0.2rem;

    ::v-deep .table-thead {
      background: var(--theme-bg-color);
    }

    .pagination {
      padding: 0.2rem 0;
    }
  }

  .card-wrap {
    padding: 0.2rem;

    .card {
      padding: 0.2rem;
      border-radius: 0.1rem;

      &>div:not(:last-child) {
        font-size: .22rem;
        margin-bottom: 0.3rem;
      }
    }
  }
}

@media screen and (min-width:751px) {
  #interest {
    background-color: transparent;

    .pagination{
      padding-top: .1rem;
    }

    .interest-body {
      background: var(--theme-main-bg-color);
      border-radius: .1rem;
    }

    ::v-deep .nav-haeder {
      margin: 0 0 0.2rem 0;
    }

    .card {
      background-color: var(--theme-main-bg-color);
      border-radius: 0.1rem;
      -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, .06);
      box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, .06);
      margin-top: 0.2rem;
      padding: 0.3rem 0.2rem;

      .info-box {
        display: flex;
        flex-direction: row;

        &:first-child {
          margin-top: 0.2rem;
        }
      }

      .row-t {
        border: 0.01rem solid var(--theme-color-line);
        border-bottom: none;
        padding: 0.3rem 0.2rem 0;
        border-radius: 0.1rem 0.1rem 0 0;
      }

      .row-b {
        padding: 0 0.2rem 0.2rem;
        border-radius: 0 0 0.1rem 0.1rem;
        border: 0.01rem solid var(--theme-color-line);
        border-top: none;
      }
    }
  }
}
</style>