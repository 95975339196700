export default {
    state: {
        isPause: true,
        musicIndex: 0,
        loopList: [
            { label: "Cycle", icon: "listLoop" },
            { label: "Shuffle", icon: "randomLoop" },
            { label: "Repeat", icon: "repeatLoop" },
        ],
        currentPlayMusicInfo: {
            id: 1,
            name: "You+Spin+Me+Round.mp3",
            url: "https://game.pgssoft-game.com/mp3/You+Spin+Me+Round.mp3",
            isDownload: true,
        },
        downloadIdArr: localStorage.getItem("downloadArr") ?
            JSON.parse(localStorage.getItem("downloadArr")) : [0] || [0],
        loopIndex: 0,
        musicList: [{
                id: 1,
                name: "You+Spin+Me+Round.mp3",
                url: "https://game.pgssoft-game.com/mp3/You+Spin+Me+Round.mp3",
                isDownload: true,
            },
            {
                id: 2,
                name: "Yesterday.mp3",
                url: "https://game.pgssoft-game.com/mp3/Yesterday.mp3",
                isDownload: false,
            },
            {
                id: 3,
                name: "Wiz+Khalifa+-107.+See+You+Again+ft.+Charlie+Puth+[Official+Video]+Furious+7+Soundtrack.mp3",
                url: "https://game.pgssoft-game.com/mp3/Wiz+Khalifa+-107.+See+You+Again+ft.+Charlie+Puth+[Official+Video]+Furious+7+Soundtrack.mp3",
                isDownload: false,
            },
            {
                id: 4,
                name: "Without+You.mp3",
                url: "https://game.pgssoft-game.com/mp3/Without+You.mp3",
                isDownload: false,
            },
            {
                id: 5,
                name: "Will+Smith-9.Live+It+Up.mp3",
                url: "https://game.pgssoft-game.com/mp3/Will+Smith-9.Live+It+Up.mp3",
                isDownload: false,
            },
            {
                id: 6,
                name: "Waiting+for+Love.mp3",
                url: "https://game.pgssoft-game.com/mp3/Waiting+for+Love.mp3",
                isDownload: false,
            },
            {
                id: 7,
                name: "Wait+Wait+Wait.mp3",
                url: "https://game.pgssoft-game.com/mp3/Wait+Wait+Wait.mp3",
                isDownload: false,
            },
            {
                id: 8,
                name: "Victory.mp3",
                url: "https://game.pgssoft-game.com/mp3/Victory.mp3",
                isDownload: false,
            },
            {
                id: 9,
                name: "Titanium.mp3",
                url: "https://game.pgssoft-game.com/mp3/Titanium.mp3",
                isDownload: false,
            },
            {
                id: 10,
                name: "The+Phoenix.mp3",
                url: "https://game.pgssoft-game.com/mp3/The+Phoenix.mp3",
                isDownload: false,
            },
            {
                id: 11,
                name: "The+Nights(Remix).mp3",
                url: "https://game.pgssoft-game.com/mp3/The+Nights(Remix).mp3",
                isDownload: false,
            },
            {
                id: 12,
                name: "The+Fox.mp3",
                url: "https://game.pgssoft-game.com/mp3/The+Fox.mp3",
                isDownload: false,
            },
            {
                id: 13,
                name: "The+Days.mp3",
                url: "https://game.pgssoft-game.com/mp3/The+Days.mp3",
                isDownload: false,
            },
            {
                id: 14,
                name: "The+Chainsmokers、Coldplay+-+15.Something+Just+Like+This.mp3",
                url: "https://game.pgssoft-game.com/mp3/The+Chainsmokers、Coldplay+-+15.Something+Just+Like+This.mp3",
                isDownload: false,
            },
            {
                id: 15,
                name: "The+Chainsmokers-69.Paris.mp3",
                url: "https://game.pgssoft-game.com/mp3/The+Chainsmokers-69.Paris.mp3",
                isDownload: false,
            },
            {
                id: 16,
                name: "The+Chainsmokers-68.Don_t+Let+Me+Down.mp3",
                url: "https://game.pgssoft-game.com/mp3/The+Chainsmokers-68.Don_t+Let+Me+Down.mp3",
                isDownload: false,
            },
            {
                id: 17,
                name: "The+Chainsmokers-67.Closer.mp3",
                url: "https://game.pgssoft-game.com/mp3/The+Chainsmokers-67.Closer.mp3",
                isDownload: false,
            },
            {
                id: 18,
                name: "That+Girl.mp3",
                url: "https://game.pgssoft-game.com/mp3/That+Girl.mp3",
                isDownload: false,
            },
            {
                id: 19,
                name: "Taylor+Swift+-+106.Bad+Blood+ft.+Kendrick+Lamar.mp3",
                url: "https://game.pgssoft-game.com/mp3/Taylor+Swift+-+106.Bad+Blood+ft.+Kendrick+Lamar.mp3",
                isDownload: false,
            },
            {
                id: 20,
                name: "Taylor+Swift+-+105.Shake+It+Off.mp3",
                url: "https://game.pgssoft-game.com/mp3/Taylor+Swift+-+105.Shake+It+Off.mp3",
                isDownload: false,
            },
            {
                id: 21,
                name: "Taylor+Swift+-+104.You+Belong+With+Me.mp3",
                url: "https://game.pgssoft-game.com/mp3/Taylor+Swift+-+104.You+Belong+With+Me.mp3",
                isDownload: false,
            },
            {
                id: 22,
                name: "Taylor+Swift+-+103.Blank+Space.mp3",
                url: "https://game.pgssoft-game.com/mp3/Taylor+Swift+-+103.Blank+Space.mp3",
                isDownload: false,
            },
            {
                id: 23,
                name: "Soviet+March.mp3",
                url: "https://game.pgssoft-game.com/mp3/Soviet+March.mp3",
                isDownload: false,
            },
            {
                id: 24,
                name: "Something+Just+Like+This.mp3",
                url: "https://game.pgssoft-game.com/mp3/Something+Just+Like+This.mp3",
                isDownload: false,
            },
            {
                id: 25,
                name: "Someone+Like+You.mp3",
                url: "https://game.pgssoft-game.com/mp3/Someone+Like+You.mp3",
                isDownload: false,
            },
            {
                id: 26,
                name: "Skin.mp3",
                url: "https://game.pgssoft-game.com/mp3/Skin.mp3",
                isDownload: false,
            },
            {
                id: 27,
                name: "Silver+Scrapes.mp3",
                url: "https://game.pgssoft-game.com/mp3/Silver+Scrapes.mp3",
                isDownload: false,
            },
            {
                id: 28,
                name: "Sia-55.Move+Your+Body.mp3",
                url: "https://game.pgssoft-game.com/mp3/Sia-55.Move+Your+Body.mp3",
                isDownload: false,
            },
            {
                id: 29,
                name: "Sia-55.Dusk+Till+Dawn.mp3",
                url: "https://game.pgssoft-game.com/mp3/Sia-55.Dusk+Till+Dawn.mp3",
                isDownload: false,
            },
            {
                id: 30,
                name: "Sia-54.Cheap+Thrills.mp3",
                url: "https://game.pgssoft-game.com/mp3/Sia-54.Cheap+Thrills.mp3",
                isDownload: false,
            },
            {
                id: 31,
                name: "Sia-53.Chandelier.mp3",
                url: "https://game.pgssoft-game.com/mp3/Sia-53.Chandelier.mp3",
                isDownload: false,
            },
            {
                id: 32,
                name: "Shape+of+You.mp3",
                url: "https://game.pgssoft-game.com/mp3/Shape+of+You.mp3",
                isDownload: false,
            },
            {
                id: 33,
                name: "Shakira+-+8.Whenever,+Wherever+(Official+Music+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Shakira+-+8.Whenever,+Wherever+(Official+Music+Video).mp3",
                isDownload: false,
            },
            {
                id: 34,
                name: "Shakira+-+7.Try+Everything+(From+Zootopia)+[Official+Music+Video].mp3",
                url: "https://game.pgssoft-game.com/mp3/Shakira+-+7.Try+Everything+(From+Zootopia)+[Official+Music+Video].mp3",
                isDownload: false,
            },
            {
                id: 35,
                name: "Shakira+-+6.Waka+Waka.mp3",
                url: "https://game.pgssoft-game.com/mp3/Shakira+-+6.Waka+Waka.mp3",
                isDownload: false,
            },
            {
                id: 36,
                name: "Sexy+Love.mp3",
                url: "https://game.pgssoft-game.com/mp3/Sexy+Love.mp3",
                isDownload: false,
            },
            {
                id: 37,
                name: "Seve.mp3",
                url: "https://game.pgssoft-game.com/mp3/Seve.mp3",
                isDownload: false,
            },
            {
                id: 38,
                name: "Set+Fire+to+the+Rain.mp3",
                url: "https://game.pgssoft-game.com/mp3/Set+Fire+to+the+Rain.mp3",
                isDownload: false,
            },
            {
                id: 39,
                name: "Selena+Gomez+The+Scene+-+82.Who+Says.mp3",
                url: "https://game.pgssoft-game.com/mp3/Selena+Gomez+The+Scene+-+82.Who+Says.mp3",
                isDownload: false,
            },
            {
                id: 40,
                name: "Selena+Gomez+The+Scene+-+81.Slow+Down.mp3",
                url: "https://game.pgssoft-game.com/mp3/Selena+Gomez+The+Scene+-+81.Slow+Down.mp3",
                isDownload: false,
            },
            {
                id: 41,
                name: "Selena+Gomez+The+Scene+-+80.Love+You+Like+A+Love+Song（cover）.mp3",
                url: "https://game.pgssoft-game.com/mp3/Selena+Gomez+The+Scene+-+80.Love+You+Like+A+Love+Song（cover）.mp3",
                isDownload: false,
            },
            {
                id: 42,
                name: "Selena+Gomez+The+Scene+-+79.Same+Old+Love.mp3",
                url: "https://game.pgssoft-game.com/mp3/Selena+Gomez+The+Scene+-+79.Same+Old+Love.mp3",
                isDownload: false,
            },
            {
                id: 43,
                name: "Sea+Shanty+Medley.mp3",
                url: "https://game.pgssoft-game.com/mp3/Sea+Shanty+Medley.mp3",
                isDownload: false,
            },
            {
                id: 44,
                name: "Sam+Smith-32.La+La+La+(Deez2+Remix).mp3",
                url: "https://game.pgssoft-game.com/mp3/Sam+Smith-32.La+La+La+(Deez2+Remix).mp3",
                isDownload: false,
            },
            {
                id: 45,
                name: "Sam+Smith-30.Too+Good+At+Goodbyes+(Acoustic).mp3",
                url: "https://game.pgssoft-game.com/mp3/Sam+Smith-30.Too+Good+At+Goodbyes+(Acoustic).mp3",
                isDownload: false,
            },
            {
                id: 46,
                name: "Rise+And+Fall.mp3",
                url: "https://game.pgssoft-game.com/mp3/Rise+And+Fall.mp3",
                isDownload: false,
            },
            {
                id: 47,
                name: "Rihanna-36.Drunk+On+Love.mp3",
                url: "https://game.pgssoft-game.com/mp3/Rihanna-36.Drunk+On+Love.mp3",
                isDownload: false,
            },
            {
                id: 48,
                name: "Rihanna-35.Only+Girl+(In+The+World)+(DJ).mp3",
                url: "https://game.pgssoft-game.com/mp3/Rihanna-35.Only+Girl+(In+The+World)+(DJ).mp3",
                isDownload: false,
            },
            {
                id: 49,
                name: "Rihanna-33.Diamonds.mp3",
                url: "https://game.pgssoft-game.com/mp3/Rihanna-33.Diamonds.mp3",
                isDownload: false,
            },
            {
                id: 50,
                name: "Redfoo+-+95.New+Thang+(Official+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Redfoo+-+95.New+Thang+(Official+Video).mp3",
                isDownload: false,
            },
            {
                id: 51,
                name: "Pitbull+ft.+Chris+Brown+-91.+International+Love+(Official+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Pitbull+ft.+Chris+Brown+-91.+International+Love+(Official+Video).mp3",
                isDownload: false,
            },
            {
                id: 52,
                name: "Pitbull+Ft.+Anthony+Watts+DJWS+-+88.I+Feel+Good+(Official+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Pitbull+Ft.+Anthony+Watts+DJWS+-+88.I+Feel+Good+(Official+Video).mp3",
                isDownload: false,
            },
            {
                id: 53,
                name: "Pitbull+-+90.Give+Me+Everything.mp3",
                url: "https://game.pgssoft-game.com/mp3/Pitbull+-+90.Give+Me+Everything.mp3",
                isDownload: false,
            },
            {
                id: 54,
                name: "Pitbull+-+89.3+to+Tango+(Official+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Pitbull+-+89.3+to+Tango+(Official+Video).mp3",
                isDownload: false,
            },
            {
                id: 55,
                name: "Pitbull+-+87.Timber+ft.+Keha+(Official+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Pitbull+-+87.Timber+ft.+Keha+(Official+Video).mp3",
                isDownload: false,
            },
            {
                id: 56,
                name: "No+Limit.mp3",
                url: "https://game.pgssoft-game.com/mp3/No+Limit.mp3",
                isDownload: false,
            },
            {
                id: 57,
                name: "Move+Your+Boby.mp3",
                url: "https://game.pgssoft-game.com/mp3/Move+Your+Boby.mp3",
                isDownload: false,
            },
            {
                id: 58,
                name: "Moskau.mp3",
                url: "https://game.pgssoft-game.com/mp3/Moskau.mp3",
                isDownload: false,
            },
            {
                id: 59,
                name: "Morro+Den+De.mp3",
                url: "https://game.pgssoft-game.com/mp3/Morro+Den+De.mp3",
                isDownload: false,
            },
            {
                id: 60,
                name: "Milk+shake.mp3",
                url: "https://game.pgssoft-game.com/mp3/Milk+shake.mp3",
                isDownload: false,
            },
            {
                id: 61,
                name: "Maroon+5-25.Girls+Like+You+(WondaGurl+Remix).mp3",
                url: "https://game.pgssoft-game.com/mp3/Maroon+5-25.Girls+Like+You+(WondaGurl+Remix).mp3",
                isDownload: false,
            },
            {
                id: 62,
                name: "Maroon+5-21.Payphone.mp3",
                url: "https://game.pgssoft-game.com/mp3/Maroon+5-21.Payphone.mp3",
                isDownload: false,
            },
            {
                id: 63,
                name: "Maroon+5+-+22.Sugar.mp3",
                url: "https://game.pgssoft-game.com/mp3/Maroon+5+-+22.Sugar.mp3",
                isDownload: false,
            },
            {
                id: 64,
                name: "Major+Lazer-45.Lean+On.mp3",
                url: "https://game.pgssoft-game.com/mp3/Major+Lazer-45.Lean+On.mp3",
                isDownload: false,
            },
            {
                id: 65,
                name: "Major+Lazer-44.Cold+Water.mp3",
                url: "https://game.pgssoft-game.com/mp3/Major+Lazer-44.Cold+Water.mp3",
                isDownload: false,
            },
            {
                id: 66,
                name: "Love+Yourself.mp3",
                url: "https://game.pgssoft-game.com/mp3/Love+Yourself.mp3",
                isDownload: false,
            },
            {
                id: 67,
                name: "Lonely+Together.mp3",
                url: "https://game.pgssoft-game.com/mp3/Lonely+Together.mp3",
                isDownload: false,
            },
            {
                id: 68,
                name: "Live+The+Night.mp3",
                url: "https://game.pgssoft-game.com/mp3/Live+The+Night.mp3",
                isDownload: false,
            },
            {
                id: 69,
                name: "Keha+-+100.TiK+ToK+(Official+Music+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Keha+-+100.TiK+ToK+(Official+Music+Video).mp3",
                isDownload: false,
            },
            {
                id: 70,
                name: "Justin+Timberlake+-42.Mirrors.mp3",
                url: "https://game.pgssoft-game.com/mp3/Justin+Timberlake+-42.Mirrors.mp3",
                isDownload: false,
            },
            {
                id: 71,
                name: "Justin+Timberlake+-41.+CANT+STOP+THE+FEELING!.mp3",
                url: "https://game.pgssoft-game.com/mp3/Justin+Timberlake+-41.+CANT+STOP+THE+FEELING!.mp3",
                isDownload: false,
            },
            {
                id: 72,
                name: "Justin+Bieber-77.What+Do+You+Mean.mp3",
                url: "https://game.pgssoft-game.com/mp3/Justin+Bieber-77.What+Do+You+Mean.mp3",
                isDownload: false,
            },
            {
                id: 73,
                name: "Just+The+Way+You+Are.mp3",
                url: "https://game.pgssoft-game.com/mp3/Just+The+Way+You+Are.mp3",
                isDownload: false,
            },
            {
                id: 74,
                name: "Im+Yours.mp3",
                url: "https://game.pgssoft-game.com/mp3/Im+Yours.mp3",
                isDownload: false,
            },
            {
                id: 75,
                name: "I+Love+Poland.mp3",
                url: "https://game.pgssoft-game.com/mp3/I+Love+Poland.mp3",
                isDownload: false,
            },
            {
                id: 76,
                name: "Hate+me.mp3",
                url: "https://game.pgssoft-game.com/mp3/Hate+me.mp3",
                isDownload: false,
            },
            {
                id: 77,
                name: "Go+Time.mp3",
                url: "https://game.pgssoft-game.com/mp3/Go+Time.mp3",
                isDownload: false,
            },
            {
                id: 78,
                name: "Gentleman.mp3",
                url: "https://game.pgssoft-game.com/mp3/Gentleman.mp3",
                isDownload: false,
            },
            {
                id: 79,
                name: "Friendships.mp3",
                url: "https://game.pgssoft-game.com/mp3/Friendships.mp3",
                isDownload: false,
            },
            {
                id: 80,
                name: "Flo+Rida+-+93.Cant+Believe+It+ft.+Pitbull+[Official+Music+Video].mp3",
                url: "https://game.pgssoft-game.com/mp3/Flo+Rida+-+93.Cant+Believe+It+ft.+Pitbull+[Official+Music+Video].mp3",
                isDownload: false,
            },
            {
                id: 81,
                name: "Fifth+Harmony-28.Write+On+Me.mp3",
                url: "https://game.pgssoft-game.com/mp3/Fifth+Harmony-28.Write+On+Me.mp3",
                isDownload: false,
            },
            {
                id: 82,
                name: "Fall+Out+Boy-73.The+Last+of+the+Real+Ones+(Remix).mp3",
                url: "https://game.pgssoft-game.com/mp3/Fall+Out+Boy-73.The+Last+of+the+Real+Ones+(Remix).mp3",
                isDownload: false,
            },
            {
                id: 83,
                name: "Fall+Out+Boy-72.Immortals+(Remix).mp3",
                url: "https://game.pgssoft-game.com/mp3/Fall+Out+Boy-72.Immortals+(Remix).mp3",
                isDownload: false,
            },
            {
                id: 84,
                name: "Fall+Out+Boy-71.Centuries.mp3",
                url: "https://game.pgssoft-game.com/mp3/Fall+Out+Boy-71.Centuries.mp3",
                isDownload: false,
            },
            {
                id: 85,
                name: "Ellie+Goulding-59.Love+Me+Like+You+Do.mp3",
                url: "https://game.pgssoft-game.com/mp3/Ellie+Goulding-59.Love+Me+Like+You+Do.mp3",
                isDownload: false,
            },
            {
                id: 86,
                name: "Ed+Sheeran-39.Photograph+(Felix+Jaehn+Remix).mp3",
                url: "https://game.pgssoft-game.com/mp3/Ed+Sheeran-39.Photograph+(Felix+Jaehn+Remix).mp3",
                isDownload: false,
            },
            {
                id: 87,
                name: "Ed+Sheeran+-+38.Thinking+Out+Loud+(Lyrics).mp3",
                url: "https://game.pgssoft-game.com/mp3/Ed+Sheeran+-+38.Thinking+Out+Loud+(Lyrics).mp3",
                isDownload: false,
            },
            {
                id: 88,
                name: "Dung+Nhu+Thoi+Quen.mp3",
                url: "https://game.pgssoft-game.com/mp3/Dung+Nhu+Thoi+Quen.mp3",
                isDownload: false,
            },
            {
                id: 89,
                name: "DJ+Snake+ft.+Justin+Bieber+-+98.Let+Me+Love+You+(Lyrics).mp3",
                url: "https://game.pgssoft-game.com/mp3/DJ+Snake+ft.+Justin+Bieber+-+98.Let+Me+Love+You+(Lyrics).mp3",
                isDownload: false,
            },
            {
                id: 90,
                name: "David+Guetta-65.She+Wolf（remix）.mp3",
                url: "https://game.pgssoft-game.com/mp3/David+Guetta-65.She+Wolf（remix）.mp3",
                isDownload: false,
            },
            {
                id: 91,
                name: "David+Guetta-64.2U+(Symphonic).mp3",
                url: "https://game.pgssoft-game.com/mp3/David+Guetta-64.2U+(Symphonic).mp3",
                isDownload: false,
            },
            {
                id: 92,
                name: "Coldplay-14.Hymn+For+The+Weekend.mp3",
                url: "https://game.pgssoft-game.com/mp3/Coldplay-14.Hymn+For+The+Weekend.mp3",
                isDownload: false,
            },
            {
                id: 93,
                name: "Carly+Rae+Jepsen+-+109.Call+Me+Maybe.mp3",
                url: "https://game.pgssoft-game.com/mp3/Carly+Rae+Jepsen+-+109.Call+Me+Maybe.mp3",
                isDownload: false,
            },
            {
                id: 94,
                name: "Bruno+Mars-1.That_s+What+I+Like.mp3",
                url: "https://game.pgssoft-game.com/mp3/Bruno+Mars-1.That_s+What+I+Like.mp3",
                isDownload: false,
            },
            {
                id: 95,
                name: "Bruno+Mars+-+4.The+Lazy+Song+(Official+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Bruno+Mars+-+4.The+Lazy+Song+(Official+Video).mp3",
                isDownload: false,
            },
            {
                id: 96,
                name: "Britney+Spears+-+108.Oops!...I+Did+It+Again+(Official+Video).mp3",
                url: "https://game.pgssoft-game.com/mp3/Britney+Spears+-+108.Oops!...I+Did+It+Again+(Official+Video).mp3",
                isDownload: false,
            },
            {
                id: 97,
                name: "Bressanone.mp3",
                url: "https://game.pgssoft-game.com/mp3/Bressanone.mp3",
                isDownload: false,
            },
            {
                id: 98,
                name: "Bond-Viva.mp3",
                url: "https://game.pgssoft-game.com/mp3/Bond-Viva.mp3",
                isDownload: false,
            },
            {
                id: 99,
                name: "Bleeding+Love.mp3",
                url: "https://game.pgssoft-game.com/mp3/Bleeding+Love.mp3",
                isDownload: false,
            },
            {
                id: 100,
                name: "Billie+Eilish-12.Everything+i+wanted.mp3",
                url: "https://game.pgssoft-game.com/mp3/Billie+Eilish-12.Everything+i+wanted.mp3",
                isDownload: false,
            },
        ],
    },
    mutations: {
        SET_MUSIC_INDEX(state, data) {
            state.musicIndex = data;
        },
        SET_LOOP_INDEX(state, data) {
            state.loopIndex = data;
        },
        SET_IS_PAUSE(state, data) {
            state.isPause = data;
        },
        SET_CURRENT_PLAY(state, data) {
            state.currentPlayMusicInfo = data;
        },
        SET_DOWNLOAD_ARR(state, data) {
            state.downloadIndexArr = data;
            localStorage.setItem("downloadArr", JSON.stringify(data));
        },
    },
};