<template>
  <transition name="slider-top" @after-leave="$emit('closed')">
    <div class='pg-pop-wrap' v-show="value">
      <div class="pop-content" :class="{ dark, transparent,full }">
        <div class="pop-head" v-if="title">
          <span>{{ title }}</span>
          <span class="close-line pc-el" @click="handleClose">
            <Icon type="close-line" />
          </span>
        </div>
        <slot></slot>
        <span class="mobile-el" v-if="showClose">
          <i class="icon-close " @click="handleClose"></i>
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    dark: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
    },
    title: {
      type: String
    },
    width: {
      type: String
    },
    full: {
      type: Boolean,
      default() {
        return false
      }
    },
    showClose: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('input', false)
    }
  },
}
</script>

<style lang='scss' scoped>
.pg-pop-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, .45);


  .pop-content {
    background: var(--theme-bg-color);
    border-radius: 0.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: calc(100vh - 1rem);
    overflow-y: visible;
    display: inline-flex;
    flex-direction: column;
    border: 1px solid var(--theme-color-line);
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);

    &.dark {

      background-color: var(--theme-main-bg-color);
    }

    &.transparent {
      background-color: transparent;
      border: 1px solid transparent;
    }

    &.full {
      width: 100%;
      max-height: calc(var(--vh, 1vh) * 100);
      height: 100%;
    }

    .pop-head {
      font-size: .3rem;
      text-align: center;
      padding: 0.2rem 0.3rem;
      color: #fff;
      position: relative;

      .close-line {
        cursor: pointer;
        position: absolute;
        right: .1rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: .2rem;
        display: inline-block;
        color: var(--theme-text-color);
        height: 0.56rem;
        line-height: .56rem;
        width: 0.56rem;
      }
    }

    .icon-close {
      position: absolute;
      display: inline-block;
      width: 0.5984rem;
      height: 0.5984rem;
      left: 50%;
      bottom: -1rem;
      transform: translateX(-50%);
      background-image: var(--bgUrl);
      background-position: -5.1136rem -1.3872rem;
      background-size: 7.072rem 6.5416rem;
    }

  }
}

.pc-page {
  .pg-pop-wrap .pop-content {
    top: calc(50% + 0.3rem);
  }
}
</style>