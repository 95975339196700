<template>
  <div class="daily-mission">
    <div class="card row row-sb column-c">
      <div class="list-left">
        <div class="darken-color">{{$t('Accumulated_bets_made')}}: 3000</div>
        <div class="row column-c mt01">
          <span class="lighten-color">{{$t('Bonus')}}</span>
          <span class="ml01 warn-color">3.00</span>
        </div>
      </div>
      <pg-button type="primary" size="mid">{{$t('Go')}}</pg-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
.daily-mission {
  .card {
    padding: 0.2rem;
    border-radius: 0.1rem;
    margin-bottom: 0.2rem;

    .list-left {
      width: 3.2rem;
    }

    .lighten-color,
    .warn-color {
      font-size: .18rem;
    }
  }
}
</style>