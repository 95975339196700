
export default {
  state: {
    apiData:{},
  },
  mutations: {
    SET_API_DATA(state, data) {
      const { res,key,params } = data;
      if(state.apiData[key]){
        state.apiData[key][JSON.stringify(params)] = res
      }else{
        state.apiData[key] = {
          [JSON.stringify(params)]:res
        }
      }
    },
  },
};
