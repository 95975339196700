<template>
  <transition name="pg-drawer">
    <div class='deposit-record' v-show="visible">
      <div class="deposit-body">
        <div class="des-header">
          <div @click="handlerClose">
            <Icon class="arrow-left mobile-el" type="arrow-line-left" />
          </div>
          <div>{{ $t('Deposit_Records') }}</div>
          <div class="record">
            <div class="pc-el">
              <span class="close-line" @click="handlerClose">
                <Icon type="close-line" />
              </span>
            </div>
          </div>
        </div>
        <div class="de-main" v-loading="loading">
          <div class="row row-sb mt02 column-c filter">
            <pg-select v-model="form.date" :options="dateOptions" />
            <div class="f026">
              <span class="lighten-color">{{ $t('Total_Deposit') }}</span>
              <span class="darken-color ml01">R${{ sumMoney }}</span>
            </div>
          </div>
          <div class="flex-1" v-if="list.length > 0">
            <scrollbar @load="loadMore">
              <div class="dr-list">
                <div class="list-item" v-for="(item, index) in list" :key="index" @click="show(item)">
                  <div>
                    <div class="row column-c">
                      <img class="icon" src="@/assets/images/finance-1703844448959-605596.png" alt="">
                      <span class="bank-name">{{ item.payment_type_text }}</span>
                    </div>

                    <div class="lighten-color mt01">
                      <span>{{ item.created_at }}</span>
                      <span>{{ item.bill_no }}
                        <i class="primary-color" @click.stop="copy(item.bill_no)">
                          <Icon type='copy' />
                        </i></span>
                    </div>
                  </div>
                  <div>
                    <div class="price">R$ {{ item.money }}</div>
                    <div class="status"
                      :class="[{ 'success-color': item.status == 2 }, { 'warn-color': item.status == 1 }]">{{
                        item.status_text }}</div>
                  </div>
                </div>
              </div>
            </scrollbar>
          </div>
          <div class="flex-1" v-else>
            <empty />
          </div>
        </div>
      </div>
      <pg-poup v-model="detailShow" :showClose="false">
        <div class="detail-pop">
          <div class="pop-header">
            <span>{{$t('Deposit_Details')}}</span>
            <i class="close" @click="detailShow = false">
              <Icon type="close-line" />
            </i>
          </div>
          <div class="info-body">
            <div class="de-bg" :class="[{ wait: currentRow.status == 1 }, { success: currentRow.status == 2 }]"></div>
            <div class="status-text"
              :class="[{ 'warn-color': currentRow.status == 1 }, { 'success-color': currentRow.status == 2 }]">{{
                currentRow.status_text }}</div>
            <div class="price">
              <span>R$ {{ currentRow.money }}</span>
              <i class="primary-color copy" @click.stop="copy(currentRow.money)">
                <Icon type='copy' />
              </i>
            </div>

          </div>
          <div class="de-body">
            <ul>
              <li>
                <span class="lighten-color">{{$t('Transaction_Type')}}</span>
                <span class="darken-color">{{$t('deposit')}}</span>
              </li>
              <li>
                <span class="lighten-color">{{$t('Deposit_Method')}}</span>
                <span class="darken-color">{{ currentRow.payment_type_text }}</span>
              </li>
              <li>
                <span class="lighten-color">{{ $t('Deposit_channels') }}</span>
                <span class="darken-color">{{ currentRow.payment_type_text }}</span>
              </li>
              <li>
                <span class="lighten-color">{{$t('Time_Created')}}</span>
                <span class="darken-color">{{ currentRow.created_at }}</span>
              </li>
              <li>
                <span class="lighten-color">{{$t('Order_No')}}</span>
                <div class="darken-color row column-c">
                  <span>{{ currentRow.bill_no }}</span>
                  <i class="primary-color copy" @click.stop="copy(currentRow.bill_no)">
                    <Icon type='copy' />
                  </i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </pg-poup>
    </div>
  </transition>
</template>

<script>
import { apiPostRechargeList } from '@/api'
import { copyText, recentTime } from '@/util'
export default {
  data() {
    return {
      loading: false,
      form: {
        date: 5
      },
      list: [],
      sumMoney: 0,
      pager: {
        page: 1,
        limit: 20
      },
      hasMore: true,
      detailShow: false,
      currentRow: {},
      dateOptions: [
        { label: this.$t('Today'), value: 0 },
        { label: this.$t('Yesterdaty'), value: -1 },
        { label: `7 ${this.$t('Days')}`, value: -7 },
        { label: `15 ${this.$t('Days')}`, value: -15 },
        { label: `30 ${this.$t('Days')}`, value: -30 },
        { label: this.$t('All'), value: 5 }
      ],
    }
  },
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getRecordList()
      }
    },
    'form.date': {
      handler() {
        this.pager.page=1;
        this.getRecordList()
      }
    }
  },
  methods: {
    show(item) {
      this.currentRow = item;
      this.detailShow = true;
    },
    getRecordList() {
      this.loading = true;
      let data = { ...this.pager }
      if (this.form.date != 5) {
        data.created_at = [recentTime(this.form.date, 'yyyy-MM-dd'), recentTime(0, 'yyyy-MM-dd')]
      }
      apiPostRechargeList(data).then(res => {
        this.loading = false;
        this.list = data.page === 1 ? res.data.data : [...this.list, ...res.data.data]
        this.hasMore = this.list.length < res.data.total
        this.sumMoney = res.statistic.sum_money
      })
    },
    loadMore() {
      if (this.hasMore) {
        this.pager.page++
        this.getRecordList()
      }
    },

    copy(text) {
      copyText(text)
    },
    handlerClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang='scss' scoped>
.deposit-record {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: .6);
  z-index: 100;

  .deposit-body {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 94vh;
    display: flex;
    flex-direction: column;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.2rem 0.2rem 0 0;
    animation: slide-form-bottm .3s;

    .des-header {
      position: relative;
      display: flex;
      align-items: center;
      color: var(--theme-text-color-darken);
      font-size: .3rem;
      padding: .2rem .3rem;
      justify-content: center;

      .arrow-left {
        color: var(--theme-text-color-darken);
        font-size: .24rem;
        left: 0.24rem;
        position: absolute;
        top: 0.24rem;
      }

      .record {
        position: absolute;
        font-size: .24rem;
        right: 0.3rem;
        color: var(--theme-primary-color);
        cursor: pointer;
        display: flex;
        align-items: center;

        .close-line {
          display: inline-block;
          color: #fff;
          font-size: .16rem;
          height: 0.56rem;
          line-height: .56rem;
          padding: 0 0.1rem;
          font-size: .16rem;
          margin-left: .16rem;
        }
      }
    }

    .de-main {
      flex: 1;
      box-sizing: border-box;
      display: flex;
      overflow: hidden;
      flex-direction: column;

      .filter {
        padding: 0 0.3rem;
      }

      .dr-list {
        padding: 0 0.2rem;
        flex: 1;
        margin-top: 0.2rem;

        .list-item {
          font-size: .2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 0.1rem;
          padding: 0.15rem 0.2rem;
          background: var(--theme-bg-color);

          &:nth-of-type(2n) {
            background: transparent;
          }

          .price {
            font-size: .26rem;
            font-weight: 700;
            color: var(--theme-text-color-darken);
            margin-bottom: 0.1rem;
          }

          .bank-name {
            font-size: .24rem;
            max-width: 4rem;
            margin-left: 0.1rem;
            color: var(--theme-text-color-darken);
          }

          .icon {
            width: 0.48rem;
            height: 0.48rem;
          }

        }
      }
    }

  }

  .detail-pop {
    width: 7.04rem;
    font-size: .22rem;

    .pop-header {
      padding: 0.2rem 0.3rem;
      text-align: center;
      position: relative;
      font-size: .3rem;
      color: var(--theme-text-color-darken);

      .close {
        font-size: .2rem;
        height: 0.6rem;
        line-height: .6rem;
        width: 0.6rem;
        position: absolute;
        right: 0;
        top: 0;
        color: var(--theme-text-color);
      }
    }

    .info-body {
      border-bottom: thin dashed var(--theme-color-line);
      padding-bottom: .3rem;
      margin: 0 .2rem;

      .de-bg {
        height: 1.1rem;
        width: 1.1rem;
        margin: .16rem auto 0;
        border-radius: 50%;

        &.success {
          background: var(--bgUrl) var(--theme-secondary-color-success);
          background-position: -4.83rem -14.1rem;
          background-size: 18.3229rem 18.0871rem;
        }

        &.wait {
          background: var(--bgUrl) var(--theme-secondary-color-finance);
          background-position: -7.44rem -14rem;
          background-size: 18.3229rem 18.0871rem;
        }
      }

      .status-text {
        text-align: center;
        font-size: .24rem;
        height: 0.6rem;
        line-height: .6rem;
        margin: .2rem 0 .05rem;
      }

      .price {
        font-size: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--theme-text-color-darken);

        .copy {
          font-size: .3rem;
          margin-left: 0.1rem;
        }
      }
    }

    .de-body {
      padding: .3rem 0;
      margin: 0 .2rem;

      li {
        align-items: center;
        display: flex;
        font-size: .18rem;
        font-size: .22rem;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        min-height: 0.26rem;

        .copy {
          font-size: .3rem;
          margin-left: 0.1rem;
        }
      }
    }

  }
}

.pc-page {
  .deposit-record {
    .deposit-body {
      position: absolute;
      left: 50%;
      top: 50%;
      bottom: auto;
      transform: translate(-50%, -50%);
      animation: none;
      width: 8rem;
      border-radius: 0.2rem;
    }
  }
}</style>