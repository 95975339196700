<template>
  <div class='img-wrap' :key="oldSrc">
    <div class='loading-animation' v-if="loaded" :style="loadingHeight ? 'height:' + loadingHeight + 'px' : ''">
      <i class="game-bg-icon"><Icon type="game-load-bg" /></i>
    </div>
    <img class="img" v-else :src="oldSrc" alt="">
  </div>

</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      oldSrc: null,
      loadingHeight: 0,
    }
  },
  props: {
    src: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    src: {
      handler(val) {
        if (val != this.oldSrc) {
          this.loaded = true;
          this.preLoadImage(val)
        }
      },
      immediate: true
    },
  },
  methods: {
    preLoadImage(val) {
      this.$nextTick(() => {
        var image = new Image();
        image.src = val;
        image.onload = () => {
          this.oldSrc = val;
          this.loaded = false;
        }
      })

    }
  },
}
</script>

<style lang='scss' scoped>
.img-wrap {
  width: 100%;
  overflow: hidden;
  background: var(--theme-main-bg-color);

  .img {
    width: 100%;
    display: block;
    border-radius: .2rem;
  }

  .loading-animation {
    width: 100%;
    border-radius: 0.2rem;
    border: 1px solid var(--theme-main-bg-color);
    height: 2.1rem;
    .game-bg-icon{
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        font-size: 1.03rem;
        --fallback-background-image: initial;
    }
  }
}
</style>